import { defineStore } from 'pinia';

const useToastStore = defineStore('toastStore', {
  state: () => ({
    toastMessage: null,
  }),
  getters: {
    getToastMessage: (state) => state.toastMessage,
  },
  actions: {
    setToastMessage(toast) {
      this.toastMessage = toast;
      document.querySelector('aab-toastmessage').show();
    },
  },
});

export default useToastStore;
