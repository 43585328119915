<template>
  <div>
    <match-responses v-if="sidRequestStore.isMatchResponsesPageActive" />
    <match-nominees v-else />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MatchResponses from '@/components/shareholderId/matching/MatchResponses.vue';
import MatchNominees from '@/components/shareholderId/matching/MatchNominees.vue';
import useSidRequestStore from '@/stores/SidRequestStore';

export default defineComponent({
  name: 'RequestMatching',
  components: { MatchNominees, MatchResponses },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
});
</script>
