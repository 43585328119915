import { createRouter, createWebHistory } from 'vue-router';
import useUserStore from '@/stores/UserStore';
import i18n from '@/i18n';
import navItems from '@/utils/reference/navItems';
import routes from './routes';

const router = createRouter({
  // hash is default, history doesn't work on azure
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// All roles lead to the same authorized page at this moment
function redirectToAuthorizedPage(_userRoles, navGuardNext) {
  navGuardNext(navItems.sidHome.route);
}

router.beforeEach(async (to, from, next) => {
  // i18n check
  i18n.global.locale = i18n.global.locale
    || i18n.global.fallbackLocale
    || document.documentElement.lang
    || 'en-GB';

  // Update metadata (title) of route to update in language observer of widget base
  i18n.meta = to.meta; // pass it around to base-widget language observer, so i18n.keys work

  const userStore = useUserStore();
  // ensure user cannot access certain pages without authentication
  if (to.meta.requiresAuth && !userStore.isAuthenticated) {
    userStore.setReturnUrl(to.fullPath);
    next('/login');
    return;
  }
  // ensure authenticated user is redirected to page
  // they are authenticated for when visiting login page
  if (to.meta.requiresNonAuth && userStore.isAuthenticated) {
    redirectToAuthorizedPage(userStore.getRoles, next);
    return;
  }
  // ensure user cannot access certain pages without proper role
  if (to.meta.requiresRoles) {
    if (
      !userStore.getRoles
      || !to.meta.requiresRoles.some((value) => userStore.getRoles.includes(value))
    ) {
      redirectToAuthorizedPage(userStore.getRoles, next);
      return;
    }
  }

  // continue with routing
  next();
});

export default router;
