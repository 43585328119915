import i18n from '@/i18n';
import { formatNumber } from '@/utils/formatLib';

const undisclosed = i18n.global.t('utils.undisclosed');

/**
 * Create the matching category using the format of aab-status attributes
 * @name createMatchingCategory
 * @param matchingQuality         integer or null, the matching score
 * @returns {Object}              Matching category object
 */
export function createMatchingCategory(matchingQuality) {
  const status = {
    statusMessage: '',
    styleType: '',
  };
  const formattedQuality = formatNumber(matchingQuality / 100, 'percent');
  // resolve the null value first because (null <= 33) resolves as true
  if (matchingQuality === 0) {
    status.statusMessage = i18n.global.t('utils.reliability', { value: formattedQuality });
    status.styleType = 'negative';
  } else if (!matchingQuality) {
    status.statusMessage = i18n.global.t('utils.pending');
    status.styleType = 'inactive';
  } else if (matchingQuality <= 33) {
    status.statusMessage = i18n.global.t('utils.reliability', { value: formattedQuality });
    status.styleType = 'negative';
  } else if (matchingQuality > 33 && matchingQuality <= 66) {
    status.statusMessage = i18n.global.t('utils.reliability', { value: formattedQuality });
    status.styleType = 'warning';
  } else if (matchingQuality > 66) {
    status.statusMessage = i18n.global.t('utils.reliability', { value: formattedQuality });
    status.styleType = 'positive';
  }
  return status;
}

/**
 * Render the properties for the nomination total
 * @name createTotalNomination
 * @param totalNomination         The raw total nomination properties
 * @returns {Object}              Formatted nomination total properties
 */
export function createTotalNomination(totalNomination) {
  return {
    matchQuality: createMatchingCategory(totalNomination.totalBalanceMatchQuality),
    totalBalanceByAccountServicer: totalNomination.totalBalanceByAccountServicer,
    totalBalanceByNominee: totalNomination.totalBalanceByNominee,
  };
}

/**
 * Render the properties for the identifier information.
 * Data is delivered by either the account servicer or the nominee and this function
 * creates the formatted data for both. No nominee response date time means that
 * the nominee hasn't responded yet. No nominee name means that the data comes from
 * the nominee. In that instance, we provide back exactly what we received: empty values.
 * If there is a nominee name, then the data was delivered by the account servicer, and we want to
 * edit for undisclosed values.
 * @name createIdentifierDetails
 * @param details                  The raw details, either delivered by account servicer or nominee
 * @param nomineeResponseDateTime  The response datetime of the nominee response.
 * @returns {Object}               Formatted nomination total properties
 */
export function createIdentifierDetails(details, nomineeResponseDateTime) {
  if (!nomineeResponseDateTime && !details.nomineeName) {
    return details;
  }
  return {
    accountServicerIdentifier: details.accountServicerIdentifier || undisclosed,
    accountServicerName: details.accountServicerName || undisclosed,
    nomineeIdentifier: details.nomineeIdentifier || undisclosed,
    nomineeName: details.nomineeName || undisclosed,
  };
}

/**
 * Render the properties for the account information. Of which there can be many in a group.
 * @name createAccountDetails
 * @param account         The raw account details
 * @returns {Object}      Formatted account details
 */
function createAccountDetails(account) {
  return {
    accountNumber: account.accountNumber || undisclosed,
    accountBalance: account.accountBalance || 0,
  };
}

/**
 * Render the properties for the account group. The account group is an object
 * containing two account group arrays, one from the account servicer and one from
 * the nominee (responder)
 * @name createAccountGroupInfo
 * @param accountGroup    The account group
 * @returns {Object}      Formatted account group
 */
export function createAccountGroupInfo(accountGroup) {
  return {
    accountServicerAccounts: Object.keys(accountGroup.accountServicerAccounts)
      .map((accountKey) => createAccountDetails(accountGroup.accountServicerAccounts[accountKey])),
    responderAccounts: Object.keys(accountGroup.responderAccounts)
      .map((accountKey) => createAccountDetails(accountGroup.responderAccounts[accountKey])),
  };
}

/**
 * Render the email addresses of the nominee.
 * If there is a response and there are nominee emails, return nominee emails
 * If there is a response but there a no nominee emails, return undisclosed
 * If there is no response, return pending
 * Default, return nominee emails
 * @name createEmail
 * @param reference    The raw reference details
 * @returns {Object}   Email array or string indicating status
 */
export function createEmail(reference) {
  if (!reference.nomineeResponseDateTime && !reference.nomineeEmail) {
    return i18n.global.t('utils.pending');
  }
  if (
    reference.nomineeEmail
    && reference.nomineeResponseDateTime
    && reference.nomineeEmail.length > 0
  ) {
    return reference.nomineeEmail;
  }
  if (reference.nomineeResponseDateTime
    && (!reference.nomineeEmail || reference.nomineeEmail.length === 0)
  ) {
    return undisclosed;
  }
  return reference.nomineeEmail;
}
