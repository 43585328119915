<!--
  This layout is the simple layout with only a slot for the titleBar and a default
  slot for content. This style isn't used yet and is created for future use, i.e.
  Not Found page.
-->

<template>
  <div class="d-flex flex-column min-h-screen">
    <div
      id="main"
      role="main"
      class="flex-grow-1 pb-6 pb-lg-7"
    >
      <section
        v-if="showTitleBar"
        :class="titleBarGradient"
      >
        <title-bar
          :title-long="titleLong"
          :title-short="titleShort"
          :large-title-bar="largeTitleBar"
        >
          <template #titleBar>
            <slot name="titleBar"></slot>
          </template>
        </title-bar>
      </section>
      <div
        class="container-fluid max-w-layout px-md-3"
        :class="titleBarPadding"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/header/TitleBar.vue';

export default {
  name: 'LayoutPlain',
  components: { TitleBar },
  props: {
    titleLong: {
      type: String,
      required: true,
    },
    titleShort: {
      type: String,
      required: true,
    },
    backButtonPath: {
      type: String,
      default: null,
    },
    backButtonTitle: {
      type: String,
      default: null,
    },
    showTitleBar: {
      type: Boolean,
      default: true,
    },
    largeTitleBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleBarGradient() {
      return this.showTitleBar ? 'gradient-g400-g200' : '';
    },
    titleBarPadding() {
      return this.showTitleBar ? 'pt-md-3' : '';
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.container_fluid;
@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.pb-6;
@include lib.pb-lg-7;
@include lib.px_md_3;
</style>
