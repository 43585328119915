import '@webcomponents/scoped-custom-element-registry';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import thousandSeparator from '@/directives/thousandSeparator';
import i18n from './i18n';
import router from './router';
import App from './App.vue';

import '@aab/sc-aab-accordion';
import '@aab/sc-aab-action';
import '@aab/sc-aab-button';
import '@aab/sc-aab-checkbox';
import '@aab/sc-aab-datepicker';
import '@aab/sc-aab-empty-state';
import '@aab/sc-aab-icon';
import '@aab/sc-aab-info-popover';
import '@aab/sc-aab-tooltip';
import '@aab/sc-aab-input';
import '@aab/sc-aab-inputblock-password';
import '@aab/sc-aab-modal';
import '@aab/sc-aab-notification';
import '@aab/sc-aab-pagination';
import '@aab/sc-aab-progress-bar';
import '@aab/sc-aab-radio-button';
import '@aab/sc-aab-sidepanel';
import '@aab/sc-aab-select';
import '@aab/sc-aab-slider';
import '@aab/sc-aab-status';
import '@aab/sc-aab-spinner';
import '@aab/sc-aab-table';
import '@aab/sc-aab-tabs';
import '@aab/sc-aab-toastmessage';

import '@/utils/axios';

createApp(App)
  .use(i18n)
  .use(router)
  .use(createPinia())
  .directive('thousand-separator', thousandSeparator)
  .mount('#app');
