import {
  createMatchingCategory,
  createAccountGroupInfo,
  createIdentifierDetails, createTotalNomination, createEmail,
} from '@/utils/serviceHelpers/sidReconDataHelpers';
import i18n from '@/i18n';
import { formatDateTime } from '@/utils/formatLib';

const unknownName = i18n.global.t('utils.unknownValue', { value: 'name' });
const unknownIdentifier = i18n.global.t('utils.unknownValue', { value: 'identifier' });
const unknown = i18n.global.t('utils.unknown');
const undisclosed = i18n.global.t('utils.undisclosed');
const pending = i18n.global.t('utils.pending');

/**
 * Create the formatted aggregated nominees based of raw data
 * @name createAggregatedNomineeData
 * @param data        the raw aggregated nominee data
 * @returns {Object}  Aggregated nominee data, formatted
 */
export function createAggregatedNomineeData(data) {
  const aggNominees = Object.keys(data.list)
    .map((key) => ({
      uuid: data.list[key].nomineeUuid,
      nomineeName: data.list[key].officialNomineeName || unknownName,
      nomineeCountry: data.list[key].nomineeCountry,
      nomineeIdentifier: data.list[key].nomineeIdentifier || unknownIdentifier,
      email: data.list[key].email || undisclosed,
      aggregatedAmount: data.list[key].aggregatedAmount,
      matchedAmount: data.list[key].matchedAmount || 0,
      matchingQuality: data.list[key].matchingQuality || pending,
      responseRate: data.list[key].responseRate || 0,
      matchingCategory: createMatchingCategory(data.list[key].matchingQuality),
    }));
  return {
    aggNominees,
    totalItems: data.totalElements,
  };
}

/**
 * Create the formatted nominee account data based of raw data
 * @name createNomineeAccountPerAccountServicerData
 * @param data        the raw nominee account data
 * @returns {Object}  Nominee account data, formatted
 */
export function createNomineeAccountPerAccountServicerData(data) {
  const nomineeAccounts = Object.keys(data.list)
    .map((key) => ({
      uuid: data.list[key].nomineeAccountUuid,
      nomineeName: data.list[key].nomineeName || unknownName,
      nomineeIdentifier: data.list[key].nomineeIdentifier || unknownIdentifier,
      nomineeEmail: data.list[key].nomineeEmail,
      nomineeCountry: data.list[key].nomineeAccountCountry,
      matchedResponseIdentifier: data.list[key].matchedResponseIdentifier,
      matchedResponseReceivedDateTime:
        data.list[key].matchedResponseReceivedDatetime
          ? i18n.global.d(new Date(data.list[key].matchedResponseReceivedDatetime), 'short') : null,
      responseId: data.list[key].sidResponseIdentifier,
      accountServicerName: data.list[key].accountServicerName || unknown,
      accountServicerId: data.list[key].accountServicerIdentifier || unknown,
      accountServicerUuid: data.list[key].accountServicerUuid || unknown,
      safekeepingAccountNumber: data.list[key].safekeepingAccountNumber || unknown,
      shareholdingBalance: data.list[key].shareholdingBalance,
      matchedShareholdingBalance: data.list[key].matchedShareholdingBalance || 0,
      responseRate: data.list[key].responseRate || 0,
      matchingCategory: createMatchingCategory(data.list[key].matchingQuality),
      lastEmailSentBy: data.list[key].lastEmailSentBy,
      lastEmailSentOn:
        data.list[key].lastEmailSentOn
          ? i18n.global.d(new Date(data.list[key].lastEmailSentOn), 'short') : null,
    }));
  return {
    nomineeAccounts,
    totalItems: data.totalElements,
  };
}

/**
 * Create the formatted matching details data
 * Replacing null values with 'Undisclosed', 'Pending' or 0
 * Creating status objects for matching quality
 * @name createMatchingDetailsData
 * @param data        the raw responses data
 * @returns {Object}  responses data, formatted
 */
export function createMatchingDetailsData(data) {
  const { nominations } = data;
  const nominationsFormatted = Object.keys(nominations)
    .map((key) => {
      const { totalNomination } = nominations[key];
      const { reference } = nominations[key];
      const { identifiers } = nominations[key].matching;
      const { accounts } = nominations[key].matching;
      return {
        totalNomination: createTotalNomination(totalNomination),
        reference: {
          nomineeEmail: createEmail(reference),
          nomineeResponseDateTime:
            reference.nomineeResponseDateTime
              ? i18n.global.d(new Date(reference.nomineeResponseDateTime), 'long') : pending,
          nomineeResponseIdentifier: reference.nomineeResponseIdentifier || pending,
        },
        matching: {
          identifiers: {
            accountServicerIdentifierMatchQuality: createMatchingCategory(
              identifiers.accountServicerIdentifierMatchQuality,
            ),
            nomineeIdentifierMatchQuality: createMatchingCategory(
              identifiers.nomineeIdentifierMatchQuality,
            ),
            detailsByAccountServicer: createIdentifierDetails(
              identifiers.detailsByAccountServicer,
              reference.nomineeResponseDateTime,
            ),
            detailsByNominee: createIdentifierDetails(
              identifiers.detailsByNominee,
              reference.nomineeResponseDateTime,
            ),
          },
          accounts: Object.keys(accounts)
            .map((accountGroupKey) => createAccountGroupInfo(accounts[accountGroupKey])),
        },
      };
    });
  const { totalBalance } = data;
  const totalBalanceFormatted = {
    totalBalanceByAccountServicer: totalBalance.totalBalanceByAccountServicer,
    totalBalanceByNominee: totalBalance.totalBalanceByNominee || 0,
    matchQuality: createMatchingCategory(totalBalance.matchQuality),
    lastEmailSentBy: totalBalance.lastEmailSentBy,
    lastEmailSentOn:
      totalBalance.lastEmailSentOn
        ? i18n.global.d(new Date(totalBalance.lastEmailSentOn), 'short') : null,
  };
  return {
    nominations: nominationsFormatted,
    totalBalance: totalBalanceFormatted,
  };
}

/**
 * Create the formatted response accounts
 * @name createResponseAccountData
 * @param data        the raw responses data
 * @returns {Object}  responses data, formatted
 */
export function createResponseAccountData(data) {
  const responseAccounts = Object.keys(data.list)
    .map((key) => ({
      responseAccountUuid: data.list[key].responseAccountUuid,
      responseId: data.list[key].responseId,
      responseDate: formatDateTime(data.list[key].responseDate),
      responderName: data.list[key].responderName,
      responderIdentifier: data.list[key].responderIdentifier,
      responderCountry: data.list[key].responderCountry,
      accountServicerName: data.list[key].accountServicerName,
      accountServicerIdentifier: data.list[key].accountServicerIdentifier,
      accountServicerCountry: data.list[key].accountServicerCountry,
      accountNumber: data.list[key].accountNumber ? data.list[key].accountNumber : '-',
      amount: data.list[key].amount,
      matchedAmountCategory: data.list[key].matchedAmountCategory,
    }));
  return {
    responseAccounts,
    totalItems: data.totalElements,
  };
}

/**
 * Create the formatted nominee accounts
 * @name createNomineeAccountData
 * @param data        the raw nominees data
 * @returns {Object}   nominees data, formatted
 */
export function createNomineeAccountData(data) {
  const nomineeAccounts = Object.keys(data.list)
    .map((key) => ({
      nomineeAccountUuid: data.list[key].nomineeAccountUuid,
      responseDate: formatDateTime(data.list[key].responseDate),
      nomineeName: data.list[key].nomineeName,
      nomineeIdentifier: data.list[key].nomineeIdentifier,
      nomineeCountry: data.list[key].nomineeCountry,
      responderName: data.list[key].responderName,
      responderIdentifier: data.list[key].responderIdentifier,
      responderCountry: data.list[key].responderCountry,
      accountNumber: data.list[key].accountNumber ? data.list[key].accountNumber : '-',
      amount: data.list[key].amount ? data.list[key].amount : 0,
      matchedAmountCategory: data.list[key].matchedAmountCategory,
    }));
  return {
    nomineeAccounts,
    totalItems: data.totalElements,
  };
}
