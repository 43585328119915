<template>
  <li
    class="d-flex"
    @click="changeVisibilitySubLinks()"
    @keyup.enter="changeVisibilitySubLinks()"
  >
    <router-link
      class="flex-grow-1 p-1 m-0"
      :to="link.route"
    >
      {{ $t(link.label) }}
      <base-icon
        v-if="subLinks"
        class="chevron float-right"
        :icon-name="icon"
        size="3"
        :label="$t('utils.chevronIndicating', { value: 'subMenuItem' })"
      />
    </router-link>
  </li>
  <ul
    v-if="showSubLinks"
    class="subLink"
  >
    <li
      v-for="subLink in subLinks"
      :key="subLink.routeName"
      class="d-flex subLink"
    >
      <router-link
        class="flex-grow-1 m-0"
        :to="subLink.route"
      >
        {{ $t(subLink.label) }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon.vue';

export default {
  name: 'BaseMenuItem',
  components: { BaseIcon },
  props: {
    background: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: true,
      required: false,
    },
    link: {
      type: Object,
      required: true,
    },
    subLinks: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      subLinksVisible: false,
    };
  },
  computed: {
    icon() {
      if (this.subLinks && this.showSubLinks) {
        return 'sy_arrow_chevron_up';
      }
      if (this.subLinks && !this.showSubLinks) {
        return 'sy_arrow_chevron_down';
      }
      return null;
    },
    showSubLinks() {
      return this.subLinksVisible;
    },
  },
  methods: {
    changeVisibilitySubLinks() {
      this.subLinksVisible = !this.subLinksVisible;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.border_box;
@include lib.p_1;
@include lib.pl_3;
@include lib.py_1;
@include lib.p_0;
@include lib.m_0;
@include lib.float_right;
@include lib.d_inline_block;
@include lib.d_flex;
@include lib.flex_grow_1;
@include lib.h4;

li {
  cursor: pointer;
  font-size: 1rem;
  list-style: none;
  line-height: 24px;
  margin: 0;
}

ul.subLink {
  padding: 0;
}

li.subLink a {
  padding: 8px 8px 8px 40px;
}

a:hover,
a.router-link-active {
  background-color: lib.$gm50;
  font-weight: bold;
}

.bordered-items a:hover,
.bordered-items a.router-link-active {
  border-left: 2px solid lib.$gm65;
}

.chevron {
  vertical-align: sub;
}

a {
  border-left: 2px solid transparent;
  text-decoration: none;
  color: lib.$g300;
  display: flex;
  justify-content: space-between;
}
</style>
