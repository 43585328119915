<template>
  <aab-modal
    id="manual-matching-modal"
    modal-size="extra-large"
    :is-open="openMatchingModal"
    @modal-close="$emit('close-manual-matching')"
  >
    <div slot="modal-title">
      <base-typology
        tag="h3"
        variant="margin-0"
      >
        {{ $t('shareholderId.findAMatch') }}
      </base-typology>
    </div>
    <div slot="modal-content">
      <div v-if="!preSelectedItemsRows || preSelectedItemsRows.length === 0">
        <aab-empty-state
          :title="$t('utils.nothingSelected')"
          :text="$t(
            'utils.nothingSelectedInstruction',
            { value: $t('shareholderId.manualMatching').toLowerCase() }
          )"
          :icon="emptyIcon"
        />
      </div>
      <div v-else>
        <section
          id="pre-selected-items"
          :aria-label="preSelectedItemsLabel"
        >
          <!--error-->
          <div
            v-if="manualMatchingError"
            id="manual-matching-error"
            class="m-3 mb-2"
          >
            <aab-notification
              type="negative"
              close-icon
              type-aria-label="Error notification"
              @aab-notification-close-icon-clicked="manualMatchingError = null"
            >
              <base-typology>{{ manualMatchingError }}</base-typology>
            </aab-notification>
          </div>

          <div class="title-pre-selected-items">
            <base-typology variant="intro">
              {{ preSelectedItemsLabel }}
            </base-typology><br>
            <base-typology variant="small">
              {{ `${$t('utils.selectedValue', { value: $t('gen.amount').toLowerCase()})}:` }}
              <b>{{ formatNumber(preSelectedTotalAmount, 'decimal') }} </b>
            </base-typology><br>
          </div>
          <base-data-table
            :columns="preSelectedItemsColumns"
            :rows="preSelectedItemsRows"
            :caption="preSelectedItemsLabel"
            :sort-on="preSelectedItemsSortOn"
            :sort-direction="preSelectedItemsSortDirection"
            variant="bar"
            @request-update="sortData($event, 'pre-selected')"
          />
        </section>
        <section
          id="selected-items"
          class="pt-1"
        >
          <div class="title-selected-items">
            <base-typology variant="intro">
              {{ selectedItemsLabel }}
            </base-typology><br>
            <base-typology variant="small">
              {{ `${$t('utils.selectedValue', { value: $t('gen.amount').toLowerCase()})}:` }}
              <b>{{ formatNumber(selectedTotalAmount, 'decimal') }} </b>
            </base-typology><br>
          </div>
          <div v-if="!selectedItems || selectedItems.length === 0">
            <aab-notification type="info">
              <base-typology>{{ $t('utils.nothingSelectedNotification') }}</base-typology>
            </aab-notification>
          </div>
          <div v-if="selectedItems.length > 0">
            <base-data-table
              :columns="selectedItemsColumns"
              :rows="selectedItems"
              :caption="selectedItemsLabel"
              :sort-on="selectedItemsSortOn"
              :sort-direction="selectedItemsSortDirection"
              selectable-rows
              variant="bar"
              @request-update="sortData($event, 'selected')"
              @row-selected="selectedItemClicked"
            />
          </div>
        </section>
        <section
          id="available-items"
          class="pt-1"
        >
          <base-data
            ref="availableItemsTable"
            :title="availableItemsLabel"
            :empty-message="availableItemsEmptyMessage"
            :loading="availableItemsLoading"
            :columns="availableItemsColumns"
            :rows="availableItems"
            :error="availableItemsError"
            :total-items="totalAvailableItemsMinusSelected"
            :sort-direction="sortDirection"
            :sort-on="sortOn"
            :page-size="pageSize"
            :page-number="pageNumber"
            :action-bar="actionBarAvailableItems"
            container="div"
            selectable-rows
            @request-update="updateAvailableItems($event)"
            @action-bar-event="processActionBarEvent($event)"
            @row-selected="availableItemClicked"
          />
        </section>
      </div>
    </div>
    <div slot="modal-footer">
      <div
        v-if="!preSelectedItemsRows || preSelectedItemsRows.length === 0"
        class="modal-footer"
      >
        <base-button
          id="close-button"
          :text="$t('utils.close')"
          style-type="primary"
          @click="$emit('close-manual-matching')"
          @keyup.enter="$emit('close-manual-matching')"
        />
      </div>
      <div
        v-else
        class="modal-footer"
      >
        <base-button
          id="manual-matching-close-button"
          :text="$t('utils.cancel')"
          style-type="secondary"
          @click="$emit('close-manual-matching'); resetAvailableItemsTable"
          @keyup.enter="$emit('close-manual-matching'); resetAvailableItemsTable"
        />
        <base-button
          id="manual-matching-apply-button"
          class="ml-1"
          :text="$t('utils.apply')"
          :disabled="selectedItems.length === 0"
          style-type="primary"
          @click="applyMatches"
          @keyup.enter="applyMatches"
        />
      </div>
    </div>
  </aab-modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { em_folder as emptyIcon } from '@aab/sc-aab-icon-set';
import BaseButton from '@/components/base/BaseButton.vue';
import { formatNumber, lowerCaseFirstChar } from '@/utils/formatLib';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import { sortObjects } from '@/utils/arrayTransformation';
import {
  createManualMatches,
  getMatchedContextResponses,
  getMatchedContextNominees,
  getNomineeAccounts,
  getResponseAccounts,
} from '@/services/sidService';
import {
  accountServicerNameConverter,
  amountConverter,
  matchedAmountConverter,
  nomineeNameConverter,
  responderNameConverter,
} from '@/utils/modelConverters/baseDataTableConverters';
import BaseData from '@/components/base/BaseData.vue';

/**
 * ManualMatching is the modal in which the user can match responses and nominees together.
 * The modal can be opened in ResponseAccounts and NomineeAccounts.
 * The data present in the three tables depends on where the was opened (this.origin).
 * The three tables are 'preSelectedItems', 'selectedItems' and 'availableItems'.
 *   ---
 * PreSelectedItems: all items that were selected in ResponseAccounts or NomineeAccounts,
 *   being response accounts or nominee accounts respectively. This table has a header which
 *   includes a count for total amount present in the table. This list is extended with items
 *   retrieved in the context during the watch handler of openMatchingModal
 * SelectedItems: all items part of a potentially existing match, retrieved in the watch handler
 *   of openMatchingModal and the items the user has purposefully selected in availableItems.
 *   This table has a header which includes a count for total amount present in the table.
 * AvailableItems: the items available for adding to the match. These are nominee accounts when
 *   opening the modal in ResponseAccounts, and response accounts when opening the modal
 *   in NomineeAccounts. This list is refreshed when user opens modal. Items in selectedItems are
 *   removed from this list and when a PARTIAL_MATCH or MATCHED item is selected, other accounts of
 *   those categories are disabled to prevent accounts being added to multiple matches.
 *   ---
 * A watch handler creates a copy on prop preSelectedItems ensures that ManualMatching can update
 *   those rows depending on user action.
 * The watch handler on openManualMatching triggers the search for previous matches
 *   and a refresh of the modal.
 */
export default defineComponent({
  name: 'ManualMatching',
  components: {
    BaseData, BaseDataTable, BaseButton, BaseTypology,
  },
  props: {
    preSelectedItems: {
      type: Array,
      default: () => [],
    },
    openMatchingModal: {
      type: Boolean,
      default: null,
    },
    origin: {
      type: String,
      validator(val) {
        return ['responses', 'nominees'].includes(val);
      },
      required: true,
    },
  },
  emits: ['close-manual-matching', 'manual-matches-created'],
  setup() {
    const availableItemsTable = ref(null);
    return { availableItemsTable };
  },
  data() {
    return {
      preSelectedItemsSortOn: 'amount',
      preSelectedItemsSortDirection: 'DESC',
      preSelectedItemsRows: [],
      availableItemsLoading: false,
      availableItemsError: null,
      availableItems: [],
      selectedItems: [],
      selectedItemsSortOn: 'amount',
      selectedItemsSortDirection: 'DESC',
      totalAvailableItems: 0,
      pageNumber: 1,
      pageSize: 25,
      sortDirection: 'DESC',
      sortOn: 'amount',
      filters: {
        searchBy: null,
      },
      emptyIcon,
      manualMatchingError: null,
    };
  },
  computed: {
    preSelectedItemsLabel() {
      if (this.origin === 'responses') {
        return this.$t('utils.selectedValue', { value: this.$tc('shareholderId.response', 2).toLowerCase() });
      }
      return this.$t('utils.selectedValue', { value: this.$tc('shareholderId.nominee', 2).toLowerCase() });
    },
    preSelectedTotalAmount() {
      if (!this.preSelectedItemsRows || this.preSelectedItemsRows.length === 0) {
        return null;
      }
      return this.preSelectedItemsRows.reduce((acc, item) => acc + (item.amount?.value || 0), 0);
    },
    idsOfPreselectedItems() {
      return new Set(this.preSelectedItemsRows.map((row) => row.id));
    },
    preSelectedItemsColumns() {
      return [
        {
          key: this.origin === 'responses' ? 'responderName' : 'nomineeName',
          template: 'title',
          label: this.origin === 'responses' ? this.$t('shareholderId.responder') : this.$t('shareholderId.nominee'),
          sortable: true,
          width: '30',
        },
        {
          key: 'accountServicerName',
          template: 'title',
          label: this.$t('shareholderId.accountServicer'),
          sortKey: this.origin === 'responses' ? 'responderName' : 'accountServicerName',
          sortable: true,
          width: '25',
        },
        {
          key: 'accountNumber',
          label: this.$tc('gen.account', 1),
          sortable: true,
          width: '15',
        },
        {
          key: 'amount',
          template: 'amount',
          label: this.$t('gen.amount'),
          sortable: true,
          width: '10',
        },
        {
          key: 'matchedAmountCategory',
          template: 'status',
          label: this.$t('utils.status'),
          sortable: true,
          width: '15',
        },
      ];
    },
    selectedItemsLabel() {
      if (this.origin === 'responses') {
        return this.$t('utils.selectedValue', { value: this.$tc('shareholderId.nominee', 2).toLowerCase() });
      }
      return this.$t('utils.selectedValue', { value: this.$tc('shareholderId.response', 2).toLowerCase() });
    },
    selectedTotalAmount() {
      if (!this.selectedItems || this.selectedItems.length === 0) {
        return 0;
      }
      return this.selectedItems.reduce((acc, item) => acc + item.amount.value, 0);
    },
    idsOfSelectedItems() {
      return new Set(this.selectedItems.map((row) => row.id));
    },
    selectedItemsColumns() {
      return [
        {
          key: this.origin === 'responses' ? 'nomineeName' : 'responderName',
          template: 'title',
          label: this.origin === 'responses' ? this.$t('shareholderId.nominee') : this.$t('shareholderId.responder'),
          sortable: true,
          width: '30',
        },
        {
          key: 'accountServicerName',
          template: 'title',
          label: this.$t('shareholderId.accountServicer'),
          sortable: true,
          width: '25',
        },
        {
          key: 'accountNumber',
          label: this.$tc('gen.account', 1),
          sortable: true,
          width: '10',
        },
        {
          key: 'amount',
          template: 'amount',
          label: this.$t('gen.amount'),
          sortable: true,
          width: '15',
        },
        {
          key: 'matchedAmountCategory',
          template: 'status',
          label: this.$t('utils.status'),
          sortable: true,
          width: '15',
        },
        {
          key: 'removable',
          template: 'removable',
          width: '5',
        },
      ];
    },
    availableItemsLabel() {
      if (this.origin === 'responses') {
        return `${this.$tc('shareholderId.nominee', 2)} (${this.totalAvailableItemsMinusSelected})`;
      }
      return `${this.$tc('shareholderId.response', 2)} (${this.totalAvailableItemsMinusSelected})`;
    },
    totalAvailableItemsMinusSelected() {
      if (this.totalAvailableItems - this.idsOfSelectedItems.size < 0) {
        return 0;
      }
      return this.totalAvailableItems - this.idsOfSelectedItems.size;
    },
    availableItemsEmptyMessage() {
      if (this.origin === 'responses') {
        return this.$t('shareholderId.noResults', { data: this.$tc('shareholderId.nominee', 2).toLowerCase() });
      }
      return this.$t('shareholderId.noResults', { data: lowerCaseFirstChar(this.$tc('shareholderId.response', 2)) });
    },
    availableItemsColumns() {
      return [
        {
          key: this.origin === 'responses' ? 'nomineeName' : 'responderName',
          template: 'title',
          label: this.origin === 'responses' ? this.$t('shareholderId.nominee') : this.$t('shareholderId.responder'),
          sortable: true,
          width: '30',
        },
        {
          key: 'accountServicerName',
          template: 'title',
          label: this.$t('shareholderId.accountServicer'),
          sortKey: this.origin === 'responses' ? 'responderName' : 'accountServicerName',
          sortable: true,
          width: '25',
        },
        {
          key: 'accountNumber',
          label: this.$tc('gen.account', 1),
          sortable: true,
          width: '10',
        },
        {
          key: 'amount',
          template: 'amount',
          label: this.$t('gen.amount'),
          sortable: true,
          width: '15',
        },
        {
          key: 'matchedAmountCategory',
          template: 'status',
          label: this.$t('utils.status'),
          sortable: true,
          width: '15',
        },
        {
          key: 'addable',
          template: 'addable',
          width: '5',
        },
      ];
    },
    actionBarAvailableItems() {
      return {
        search: {
          searchOptionsKey: 'shareholderId.matchingSearchOptions',
          type: 'input',
        },
      };
    },
  },
  watch: {
    /**
     * Resets modal by cleaning selectingItems, preSelectedItems and search params of availableItems
     * Only if PARTIAL_MATCH or MATCHED are in the preSelectedItems list
     * will the context be retrieved. The full context is retrieved, items added to
     * preSelectedList and selectedList. Then the availableItems are retrieved.
    */
    openMatchingModal: {
      async handler(newOpenMatchingModal) {
        if (newOpenMatchingModal) {
          this.selectedItems = [];
          this.preSelectedItemsRows = sortObjects(
            [...this.preSelectedItems],
            this.preSelectedItemsSortOn,
            this.preSelectedItemsSortDirection,
          );
          const preSelectedCategories = new Set(
            this.preSelectedItems.map((item) => item.matchedAmountCategory.value),
          );
          if (preSelectedCategories.has('PARTIAL_MATCH') || preSelectedCategories.has('MATCHED')) {
            await this.getMatchingContext();
          }
          if (this.preSelectedItems.length > 0) {
            await this.getAvailableItems();
          }
        }
      },
    },
  },
  methods: {
    formatNumber,
    sortData(event, selectionGroup) {
      if (event.sortOn) {
        if (selectionGroup === 'pre-selected') {
          this.preSelectedItemsSortOn = event.sortOn;
          this.preSelectedItemsSortDirection = event.sortDir;
          this.preSelectedItemsRows = sortObjects(
            this.preSelectedItemsRows,
            this.preSelectedItemsSortOn,
            this.preSelectedItemsSortDirection,
          );
        } else {
          this.selectedItemsSortOn = event.sortOn;
          this.selectedItemsSortDirection = event.sortDir;
          this.selectedItems = sortObjects(
            this.selectedItems,
            this.selectedItemsSortOn,
            this.selectedItemsSortDirection,
          );
        }
      }
    },
    async getMatchingContext() {
      if (this.origin === 'responses') {
        const matchingContext = await getMatchedContextResponses(
          this.preSelectedItems.map((i) => i.id),
        );
        this.createDataStructureNomineeAccounts(matchingContext.matchingNomineeAccounts)
          .forEach((item) => this.selectedItems.push(item));
        this.createDataStructureResponseAccounts(matchingContext.matchingResponseAccounts)
          .filter((item) => !this.idsOfPreselectedItems.has(item.id))
          .forEach((item) => this.preSelectedItemsRows.push(item));
      } else {
        const matchingContext = await getMatchedContextNominees(
          this.preSelectedItems.map((i) => i.id),
        );
        this.createDataStructureResponseAccounts(matchingContext.matchingResponseAccounts)
          .forEach((item) => this.selectedItems.push(item));
        this.createDataStructureNomineeAccounts(matchingContext.matchingNomineeAccounts)
          .filter((item) => !this.idsOfPreselectedItems.has(item.id))
          .forEach((item) => this.preSelectedItemsRows.push(item));
      }
      this.sortData({ sortOn: 'amount', sortDir: 'DESC' }, 'selected');
      this.sortData({ sortOn: 'amount', sortDir: 'DESC' }, 'pre-selected');
    },
    async getAvailableItems() {
      this.availableItemsLoading = true;
      this.availableItemsError = null;
      try {
        if (this.origin === 'responses') {
          // if navigating from responses, you want to retrieve available nominees for matching
          const data = await getNomineeAccounts(
            this.$route.params.sidRequestUuid,
            this.pageNumber,
            this.pageSize,
            this.sortOn,
            this.sortDirection,
            this.filters,
          );
          this.availableItems = this.createDataStructureNomineeAccounts(data)
            .filter((item) => !this.idsOfSelectedItems.has(item.id));
          this.totalAvailableItems = data.totalItems;
        } else {
          // if navigating from nominees, you want to retrieve available responses for matching
          const data = await getResponseAccounts(
            this.$route.params.sidRequestUuid,
            this.pageNumber,
            this.pageSize,
            this.sortOn,
            this.sortDirection,
            this.filters,
          );
          this.availableItems = this.createDataStructureResponseAccounts(data)
            .filter((item) => !this.idsOfSelectedItems.has(item.id));
          this.totalAvailableItems = data.totalItems;
        }
        this.availableItems = this.updateAvailableItemsDisabled();
      } catch (e) {
        if (this.origin === 'responses') {
          this.availableItemsError = `${this.$t('shareholderId.errors.getNomineeAccounts')} ${e.code}: ${this.$t(e.messageKey)}`;
        } else {
          this.availableItemsError = `${this.$t('shareholderId.errors.getResponseAccounts')} ${e.code}: ${this.$t(e.messageKey)}`;
        }
      } finally {
        this.availableItemsLoading = false;
      }
    },
    async updateAvailableItems(event) {
      this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
      this.pageNumber = event.pageNumber ? event.pageNumber : this.pageNumber;
      this.sortOn = event.sortOn ? event.sortOn : this.sortOn;
      this.sortDirection = event.sortDir ? event.sortDir : this.sortDirection;
      await this.getAvailableItems();
    },
    async processActionBarEvent(event) {
      if (event.event === 'search') {
        this.filters.searchBy = event.value;
        await this.getAvailableItems();
      } else {
        // warning for developer - does not need translating
        console.warn(`Action bar generated unknown event: ${event.event}`);
      }
    },
    selectedItemClicked(event) {
      const selectedItem = this.selectedItems.find((item) => item.id === event.rowId);
      this.selectedItems = this.selectedItems.filter((item) => item.id !== event.rowId);
      this.availableItems.push(selectedItem);
      sortObjects(this.availableItems, 'amount', 'DESC');
      this.availableItems = this.updateAvailableItemsDisabled();
    },
    async availableItemClicked(event) {
      const selectedItem = this.availableItems.find((item) => item.id === event.rowId);
      this.selectedItems.push(selectedItem);
      if (selectedItem.matchedAmountCategory.value === 'PARTIAL_MATCH' || selectedItem.matchedAmountCategory.value === 'MATCHED') {
        if (this.origin === 'responses') {
          const matchingContext = await getMatchedContextNominees([event.rowId]);
          this.createDataStructureNomineeAccounts(matchingContext.matchingNomineeAccounts)
            .filter((item) => !this.idsOfSelectedItems.has(item.id))
            .forEach((item) => this.selectedItems.push(item));
          this.createDataStructureResponseAccounts(matchingContext.matchingResponseAccounts)
            .filter((item) => !this.idsOfPreselectedItems.has(item.id))
            .forEach((item) => this.preSelectedItemsRows.push(item));
        } else {
          const matchingContext = await getMatchedContextResponses([event.rowId]);
          this.createDataStructureResponseAccounts(matchingContext.matchingResponseAccounts)
            .filter((item) => !this.idsOfSelectedItems.has(item.id))
            .forEach((item) => this.selectedItems.push(item));
          this.createDataStructureNomineeAccounts(matchingContext.matchingNomineeAccounts)
            .filter((item) => !this.idsOfPreselectedItems.has(item.id))
            .forEach((item) => this.preSelectedItemsRows.push(item));
        }
      }
      this.availableItems = this.availableItems
        .filter((item) => !this.idsOfSelectedItems.has(item.id));
      this.sortData({ sortOn: 'amount', sortDir: 'DESC' }, 'selected');
      this.sortData({ sortOn: 'amount', sortDir: 'DESC' }, 'pre-selected');
      this.availableItems = this.updateAvailableItemsDisabled();
    },
    updateAvailableItemsDisabled() {
      const selectedCategories = this.selectedItems
        .map((row) => row.matchedAmountCategory.value);
      // only disable rows when there are previously matched selected rows
      if (selectedCategories.includes('PARTIAL_MATCH') || selectedCategories.includes('MATCHED')) {
        return this.availableItems.map((item) => {
          const newItem = item;
          // do not disable any of the selected items
          if (this.idsOfSelectedItems.has(item.id)) {
            return newItem;
          }
          // disable rows that are already previously matched
          if (item.matchedAmountCategory.value !== 'UNMATCHED') {
            newItem.disabled = true;
            return newItem;
          }
          return newItem;
        });
      }
      return this.availableItems.map((item) => {
        const newItem = item;
        newItem.disabled = false;
        return newItem;
      });
    },
    /**
     * Confirms the manual matches with the backend
     */
    applyMatches() {
      let nomineeAccountIds;
      let responseAccountIds;
      if (this.origin === 'responses') {
        nomineeAccountIds = this.selectedItems.map((i) => i.id);
        responseAccountIds = this.preSelectedItems.map((i) => i.id);
      } else {
        nomineeAccountIds = this.preSelectedItems.map((i) => i.id);
        responseAccountIds = this.selectedItems.map((i) => i.id);
      }
      createManualMatches(nomineeAccountIds, responseAccountIds, this.origin)
        .then(() => {
          this.resetAvailableItemsTable();
          this.$emit('close-manual-matching');
          this.$emit('manual-matches-created');
        })
        .catch(() => {
          this.manualMatchingError = this.$tc('shareholderId.errors.manualMatchCreationError');
        });
    },
    resetAvailableItemsTable() {
      this.filters.searchBy = null;
      this.availableItemsTable.clearInputValue();
    },
    createDataStructureResponseAccounts(data) {
      return Object.keys(data.responseAccounts)
        .map((key) => ({
          id: data.responseAccounts[key].responseAccountUuid,
          disabled: false,
          responderName: responderNameConverter(data.responseAccounts[key]),
          accountServicerName: accountServicerNameConverter(data.responseAccounts[key]),
          accountNumber: data.responseAccounts[key].accountNumber,
          amount: amountConverter(data.responseAccounts[key].amount, 'decimal'),
          matchedAmountCategory: matchedAmountConverter(
            data.responseAccounts[key].matchedAmountCategory,
          ),
        }));
    },
    createDataStructureNomineeAccounts(data) {
      return Object.keys(data.nomineeAccounts)
        .map((key) => ({
          id: data.nomineeAccounts[key].nomineeAccountUuid,
          disabled: false,
          nomineeName: nomineeNameConverter(data.nomineeAccounts[key]),
          accountServicerName: accountServicerNameConverter(data.nomineeAccounts[key]),
          accountNumber: data.nomineeAccounts[key].accountNumber,
          amount: amountConverter(data.nomineeAccounts[key].amount, 'decimal'),
          matchedAmountCategory: matchedAmountConverter(
            data.nomineeAccounts[key].matchedAmountCategory,
          ),
        }));
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.pt_1;

.title-pre-selected-items {
  padding: 0 1rem 0.75rem 1rem;
}

.title-selected-items {
  padding: 0.75rem 1rem 0.75rem 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
