import axios from 'axios';
import constructQueryParams from '@/utils/serviceHelpers/genericHelpers';
import createError from '@/utils/errors';
import createCsdData from '@/utils/serviceHelpers/csdHelpers';

/**
 * Get the list of central securities depositories (CSD).
 * If pageNumber and pageSize are null, then all CSDs.
 * SortBy is by default on name
 * @name getCsds
 * @param pageNumber       the pageNumber
 * @param pageSize         the pageSize
 * @returns {Array}        list of CSDs
 */
export default async function getCsds(pageNumber, pageSize) {
  const queryString = constructQueryParams({
    pageNumber, pageSize,
  }, null);
  return axios.get(`v1/csds${queryString}`)
    .then((resp) => createCsdData(resp.data))
    .catch((error) => createError(error));
}
