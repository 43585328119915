<template>
  <base-tile>
    <form
      class="login-form"
      @keyup.enter="login()"
    >
      <div class="col px-0">
        <div class="row pt-3 mx-2">
          <aab-input
            id="input-email"
            :text="v$.email.$error.message"
          >
            <label
              slot="label"
              for="email"
            >
              {{ $t('user.email') }}
            </label>
            <input
              id="email"
              v-model="email"
              name="email"
              type="text"
              required
            >
          </aab-input>
        </div>
        <div class="row pt-3 mx-2">
          <aab-input id="input-password">
            <label
              slot="label"
              for="password"
            >
              {{ $t('user.password') }}
            </label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              required
              autocomplete="on"
            >
          </aab-input>
        </div>
      </div>
    </form>
    <div
      v-if="invalidForm"
      id="invalid-form"
      class="pt-3 mx-2"
    >
      <aab-notification type="warning">
        <span>{{ $t('auth.invalidLogin') }}</span>
      </aab-notification>
    </div>
    <div
      v-if="loginFailed"
      id="login-failed"
      class="pt-3 mx-2"
    >
      <aab-notification type="warning">
        <span>{{ $t('auth.authenticationFailed') }}</span>
      </aab-notification>
    </div>
    <template #footer>
      <div class="py-3 px-2 mt-4 form-footer">
        <base-button
          id="login"
          :style-type="'primary'"
          :text="$t('auth.login')"
          icon="sy_tools_lock"
          :disabled="loading"
          full-width
          @click="login()"
        />
      </div>
    </template>
  </base-tile>
</template>

<script>
import { n300 } from '@aab/sc-styles-colors';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import useUserStore from '@/stores/UserStore';
import navItems from '@/utils/reference/navItems';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@/utils/customValidation';
import { helpers } from '@vuelidate/validators';

export default {
  name: 'LoginForm',
  components: { BaseTile, BaseButton },
  data() {
    return {
      v$: useVuelidate(),
      email: null,
      password: null,
      iconColor: n300,
      invalidForm: false,
      closeMessage: false,
      loginFailed: false,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.invalidForm = false;
      this.loginFailed = false;
      this.loading = true;
      if (!await this.v$.$validate()) {
        this.loading = false;
        this.invalidForm = true;
        return;
      }
      const store = useUserStore();
      store.auth(this.email, this.password)
        .then(() => {
          const returnUrl = store.getReturnUrl;
          if (returnUrl) {
            store.setReturnUrl(null);
            this.$router.push(returnUrl);
          } else {
            this.$router.push(navItems.sidHome.route);
          }
        })
        .catch(() => {
          this.loginFailed = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  validations() {
    return {
      email: { required: helpers.withParams({ field: this.$t('user.email') }, required) },
      password: { required: helpers.withParams({ field: this.$t('user.password') }, required) },
    };
  },
};
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-3;
@include lib.mt-4;
@include lib.py-4;
@include lib.row;

.login-form {
  width: 450px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

aab-input,
aab-inputblock-password {
  width: 100%;
}

</style>
