// This library is for determining which navigation items are shown to the user
// Based on authentication status and permissions
// The values in the arrays need to match the name (unique ID) of the route in routes.js

import navLists from '@/utils/reference/navLists';

function checkRoles(listItem, userRoles) {
  if (listItem.requiresRoles && userRoles) {
    return listItem.requiresRoles.some((value) => userRoles.includes(value));
  }
  return false;
}

function constructNavItems(list, routes, isLoggedIn, userRoles) {
  return Object.keys(list)
    // filter to check whether auth is required and if so whether the user is logged in
    .filter((key) => !list[key].requiresAuth || (list[key].requiresAuth && isLoggedIn))
    // filter to check whether roles are required and if so whether the user has those roles
    .filter((key) => !list[key].requiresRoles || checkRoles(list[key], userRoles))
    .map((key) => ({
      label: list[key].i18nKey,
      routeName: list[key].routeName,
      route: Object.values(routes)
        .filter((route) => route.name === list[key].routeName)
        .map((route) => route.path)
        .toString(),
    }));
}

/**
 * Get the navItems based on whether the user is logged in
 * @name getNavItems
 * @params  {Boolean} isLoggedIn verification from state that user is logged in
 * @params  {Array}   userConstants      The user's userConstants
 * @params  {Object}  routes     The routes defined in the router
 * @returns {Array}              List of navItems the user is allowed to access
 */
export function getNavItems(isLoggedIn, userRoles, routes) {
  return constructNavItems(navLists.navItemsMain, routes, isLoggedIn, userRoles);
}

/**
 * Get the account navItems - can only be called if user is logged in
 * @name getNavItems
 * @params  {Object}  routes     The routes defined in the router
 * @returns {Array}              List of navItems the user is allowed to access
 */
export function getAccountNavItems(routes, userRoles) {
  return constructNavItems(navLists.navItemsAccount, routes, null, userRoles);
}
