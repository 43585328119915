<template>
  <div class="position-relative">
    <aab-input
      id="searchInput"
      icon-position="right"
      :message="statusMessage"
      :message-type="variant"
      class="mr-2"
      @keyup.enter="setSelectedOption(inputValue)"
    >
      <label
        v-if="label"
        slot="label"
        for="inputDropdown"
      >
        {{ label }}
      </label>
      <input
        :id="`input-value-${idTag}`"
        v-model="inputValue"
        name="inputDropdown"
        type="text"
        @blur="filteredOptions.length === 0 && $emit('on-blur')"
      >
      <base-icon
        id="searchIcon"
        slot="icon"
        :label="$t('utils.search')"
        icon-name="sy_tools_search"
        size="3"
        :color="iconColor"
        @click="$emit('click-on-search-icon',
                      { $event: 'search', value: inputValue })"
      />
    </aab-input>
    <ul
      :class="`dropdown ${isOpen ? 'd-block' : 'd-none'}`"
      tabindex="-1"
      role="listbox"
      aria-label="Dropdown"
    >
      <li
        v-for="(option, index) in filteredOptions"
        :id="`dropdown-option-${index}`"
        :key="index"
        role="option"
        tabindex="0"
        :aria-selected="selectedOption === option.name ? 'true' : 'false'"
        class="option"
        @click="setSelectedOption(option.name)"
        @keyup.enter="setSelectedOption(option.name)"
        @keyup.down.prevent="focusOnNextOption(index)"
        @keyup.up.prevent="focusOnPreviousOption(index)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { g300 } from '@aab/sc-styles-colors';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default defineComponent({
  name: 'BaseInputDropdown',
  components: { BaseIcon },
  props: {
    options: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    idTag: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: '',
    },
    statusMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['on-select', 'on-change', 'click-on-search-icon', 'on-blur'],
  data() {
    return {
      iconColor: g300,
      inputValue: '',
      selectedOption: null,
      filteredOptions: [],
    };
  },
  computed: {
    isOpen() {
      return this.inputValue && this.inputValue.length > 0 && !this.selectedOption;
    },
  },
  watch: {
    inputValue(newValue) {
      // resets the selectedOption when user does not use dropdown to input value
      if (newValue !== this.selectedOption) {
        this.selectedOption = null;
      }
      this.filterOptions(newValue);
      this.$emit('on-change', newValue);
    },
  },
  methods: {
    setSelectedOption(value) {
      this.selectedOption = value;
      this.inputValue = value;
      this.$emit('on-select', value);
      this.$emit('on-blur');
    },
    filterOptions(inputValue) {
      if (inputValue && typeof inputValue === 'string') {
        this.filteredOptions = this.options.filter(
          (option) => option.name.toLowerCase().includes(inputValue.toLowerCase()),
        );
      } else {
        this.filteredOptions = this.options;
      }
    },
    focusOnNextOption(currentIndex) {
      const lengthFilteredOptions = this.filteredOptions.length;
      if (currentIndex + 1 === lengthFilteredOptions) {
        const firstOption = document.getElementById('dropdown-option-0');
        firstOption.focus();
      } else {
        const nextOption = document.getElementById(`dropdown-option-${currentIndex + 1}`);
        nextOption.focus();
      }
    },
    focusOnPreviousOption(currentIndex) {
      const lengthFilteredOptions = this.filteredOptions.length;
      if (currentIndex === 0) {
        const indexLastOption = lengthFilteredOptions - 1;
        const lastOption = document.getElementById(`dropdown-option-${indexLastOption}`);
        lastOption.focus();
      } else {
        const previousOption = document.getElementById(`dropdown-option-${currentIndex - 1}`);
        previousOption.focus();
      }
    },
    clearInputValue() {
      this.inputValue = '';
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.position_relative;

.dropdown {
  top: 100%;
  max-height: 220px;
  outline: none;
  background-color: #fff;
  z-index: 400;
  list-style-type: none;
  width: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  box-shadow: 0 8px 16px rgba(34, 34, 34, 0.3), 0 0 8px rgba(34, 34, 34, 0.0224185);
  overflow: auto;
}

.option {
  line-height: 24px;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 16px 8px 16px;
}

.option:hover {
  background-color: lib.$g55;
}

input {
  width: 270px;
}

#searchIcon {
  &:hover {
    cursor: pointer;
  }
}

</style>
