<template>
  <div class="d-flex flex-column min-h-screen">
    <div
      id="main"
      role="main"
      class="flex-grow-1 pb-6 pb-lg-7"
    >
      <section
        class="banner-container"
      >
        <div
          class="form-hero py-5"
        >
          <base-button
            id="backButton"
            :text="backButtonTitle"
            icon="sy_arrow_chevron_leftlarge"
            style-type="action"
            @click="onClick(backButtonPath)"
          />
          <slot name="titleBar">
            <base-typology
              tag="h2"
              class="px-2"
            >
              <span class="title-long">{{ titleLong }}</span>
              <span class="title-short">{{ titleShort }}</span>
            </base-typology>
          </slot>
        </div>
      </section>
      <div
        class="inner-page-container pt-5 px-2"
      >
        <div class="content">
          <slot>
            <p>This is where the form should go</p>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import navItems from '@/utils/reference/navItems';

/**
 * This is a full-width focussed form layout
 *
 * @name LayoutForm
 * @vue-prop {String} [titleLong]  - The long title to be used on desktop
 * @vue-prop {String} [titleShort] - The shorter title to be used for mobile
 * @vue-prop {String} [backButtonPath] - Path the back button links to
 * @vue-prop {String} [backButtonTitle] - Title of the back button
 */
export default defineComponent({
  name: 'LayoutForm',
  components: {
    BaseTypology,
    BaseButton,
  },
  props: {
    titleLong: {
      type: String,
      required: true,
    },
    titleShort: {
      type: String,
      required: true,
    },
    backButtonPath: {
      type: String,
      default: null,
    },
    backButtonTitle: {
      type: String,
      default: null,
    },
  },
  methods: {
    onClick() {
      this.$router.push({ name: navItems.sidHome.list.routeName });
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.pt_3;
@include lib.pt_5;
@include lib.pb-6;
@include lib.pb-lg-7;

.banner-container {
  background-color: lib.$gm55;
  grid-template-columns: [padding-left] 1fr [form-hero] 6fr [padding-right] 1fr ;
  display: grid;

  @media (max-width: 920px) {
    grid-template-columns: [padding-left] 0fr [form-hero] 8fr [padding-right] 0fr ;
  }

  .form-hero {
    grid-column: form-hero / padding-right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-long {
    display: block;

    @media (max-width: 920px) {
      display: none;
    }
  }
  .title-short {
    display: none;

    @media (max-width: 920px) {
      display: block;
    }
  }
}

.inner-page-container {
  display: grid;
  grid-template-columns: [padding-left] 1fr [content] 4fr [padding-right] 3fr;

  @media (max-width: 920px) {
    grid-template-columns: [padding-left] 0fr [content] 8fr [padding-right] 0fr ;
  }

  .content {
    grid-column: content / padding-right;
  }
}
</style>
