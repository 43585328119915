export function HttpError(code, messageKey) {
  this.code = code.toString();
  this.messageKey = messageKey;
}

export default function createError(error) {
  const errorCode = error.status || error.response.status;
  switch (errorCode) {
    case 400:
      throw new HttpError(errorCode, 'errors.400');
    case 403:
      throw new HttpError(errorCode, 'errors.403');
    case 404:
      throw new HttpError(errorCode, 'errors.404');
    case 409:
      throw new HttpError(errorCode, 'errors.409');
    default:
      throw new HttpError(errorCode, 'errors.500');
  }
}
