<template>
  <div>
    <div
      v-if="includeLabel"
      id="address-label"
    >
      <base-typology tag="label">
        {{ $t('user.address') }}
      </base-typology><br>
    </div>
    <div
      v-if="address.addressLines"
      id="address-lines"
    >
      <base-typology
        v-for="line in address.addressLines"
        :key="line"
      >
        {{ line }}<br>
      </base-typology>
    </div>
    <base-typology
      v-if="address.streetName"
      id="street-name"
    >
      {{ address.streetName }} {{ address.buildingNumber }}<br>
    </base-typology>
    <base-typology
      v-if="address.postalBox"
      id="postal-box"
    >
      {{ $t('user.postalBox') }}: {{ address.postalBox }}<br>
    </base-typology>
    <base-typology
      v-if="address.postalCode || address.townName"
      id="code-and-town"
    >
      {{ address.postalCode }} {{ address.townName }}<br>
    </base-typology>
    <base-typology
      v-if="address.countrySubDivision || address.country"
      id="country"
    >
      {{ address.countrySubDivision }} {{ address.country }}
    </base-typology>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';

export default defineComponent({
  name: 'BaseAddress',
  components: { BaseTypology },
  props: {
    address: {
      type: Object,
      required: true,
    },
    includeLabel: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
