import getCountryName from '@/utils/country';
import i18n from '@/i18n';

/**
 * Creates BaseDataTable data cell model for accountServicerName
 * assuming title or mainTitle template
 * @name accountServicerNameConverter
 * @param data         the accountServicerName data
 * @returns {Object}   the accountServicerName data modelled to fit BaseDataTable
 */
function accountServicerNameConverter(data) {
  if (!data) {
    return null;
  }
  if (data.nomineeAccountUuid) {
    return {
      value: data.responderName,
      subtitle: data.responderIdentifier,
      icon: data.responderCountry
        ? `flag_${data.responderCountry.toLowerCase()}` : null,
      iconLabel: data.responderCountry
        ? getCountryName(data.responderCountry) : null,
      iconSubtitle: data.responderCountry,
    };
  }
  return {
    value: data.accountServicerName,
    subtitle: data.accountServicerIdentifier,
    icon: data.accountServicerCountry
      ? `flag_${data.accountServicerCountry.toLowerCase()}` : null,
    iconLabel: data.accountServicerCountry
      ? getCountryName(data.accountServicerCountry) : null,
    iconSubtitle: data.accountServicerCountry,
  };
}

/**
 * Creates BaseDataTable data cell model for responderName, assuming title or mainTitle template
 * @name responderNameConverter
 * @param data         the responderName data
 * @returns {Object}   the responderName data modelled to fit BaseDataTable
 */
function responderNameConverter(data) {
  if (!data) {
    return null;
  }
  return {
    value: data.responderName,
    subtitle: data.responderIdentifier,
    icon: data.responderCountry
      ? `flag_${data.responderCountry.toLowerCase()}` : null,
    iconLabel: data.responderCountry
      ? getCountryName(data.responderCountry) : null,
    iconSubtitle: data.responderCountry,
  };
}

function issuerNameConverter(sidRequest) {
  if (!sidRequest) {
    return null;
  }
  return {
    value: sidRequest.issuerName ? sidRequest.issuerName : i18n.global.t('utils.unknownValue'),
    subtitle: sidRequest.isin,
    icon: sidRequest.country
      ? `flag_${sidRequest.country.toLowerCase()}` : null,
    iconLabel: sidRequest.country
      ? getCountryName(sidRequest.country) : null,
    iconSubtitle: sidRequest.country,
  };
}

/**
 * Creates BaseDataTable data cell model for nomineeName, assuming title or mainTitle template
 * @name nomineeNameConverter
 * @param data         the nomineeName data
 * @returns {Object}   the nomineeName data modelled to fit BaseDataTable
 */
function nomineeNameConverter(data) {
  if (!data) {
    return null;
  }
  return {
    value: data.nomineeName,
    subtitle: data.nomineeIdentifier,
    icon: data.nomineeCountry
      ? `flag_${data.nomineeCountry.toLowerCase()}` : null,
    iconLabel: data.nomineeCountry
      ? getCountryName(data.nomineeCountry) : null,
    iconSubtitle: data.nomineeCountry,
  };
}

/**
 * Creates BaseDataTable data cell model for amount, assuming amount template
 * @name amountConverter
 * @param amount         the amount
 * @param format         the format for the amount (values for formatNumber)
 * @returns {Object}     the amount data modelled to fit BaseDataTable
 */
function amountConverter(amount, format) {
  if (!amount) {
    return null;
  }
  return {
    value: amount,
    type: format,
  };
}

/**
 * Creates BaseDataTable data cell model for [partialValue] of [value],
 * assuming amountOfAmount template.
 * The format of the values is always decimal
 * @name amountOfAmountConverter
 * @param partialValue    the partial amount
 * @param value           the total amount
 * @returns {Object}      the amounts modelled to fit BaseDataTable
 */
function amountOfAmountConverter(partialValue, value) {
  if ((!partialValue && partialValue !== 0) || (!value && value !== 0)) {
    return null;
  }
  return {
    partialValue,
    value,
  };
}

/**
 * Creates BaseDataTable data cell model for matchedAmountCategory
 *
 * @param matchCategory the category of the matched amount
 * @returns {{statusMessageKey:string, styleType: string, value}}
 * the category modelled to fit BaseDataTable
 */
function matchedAmountConverter(matchCategory) {
  let styleType = 'inactive';
  let statusMessage = i18n.global.t('shareholderId.matchedAmountCategories.unmatched');
  if (matchCategory === 'PARTIAL_MATCH') {
    styleType = 'warning';
    statusMessage = i18n.global.t('shareholderId.matchedAmountCategories.partialMatch');
  }
  if (matchCategory === 'MATCHED') {
    styleType = 'positive';
    statusMessage = i18n.global.t('shareholderId.matchedAmountCategories.matched');
  }
  return {
    value: matchCategory,
    styleType,
    statusMessage,
  };
}

export {
  accountServicerNameConverter, nomineeNameConverter,
  amountConverter, responderNameConverter,
  amountOfAmountConverter, matchedAmountConverter, issuerNameConverter,
};
