<template>
  <base-typology tag="h3">
    {{ identifiers.detailsByNominee.nomineeName
      || identifiers.detailsByAccountServicer.nomineeName }}
  </base-typology>
  <section
    id="nominee-reference-information"
    :aria-label="`${$t('utils.reference')} ${identifiers.detailsByNominee.nomineeName}`"
    class="mb-2"
  >
    <div
      id="nominee-response-identifier"
      class="d-flex justify-content-start"
    >
      <base-typology
        class="label-column"
        tag="label"
      >
        {{ $t('shareholderId.responseId') }}
      </base-typology>
      <base-typology>
        {{ nomination.reference.nomineeResponseIdentifier }}
      </base-typology>
    </div>
    <div
      id="nominee-response-datetime"
      class="d-flex justify-content-start"
    >
      <base-typology
        class="label-column"
        tag="label"
      >
        {{ $t('gen.timestamp') }}
      </base-typology>
      <base-typology>
        {{ nomination.reference.nomineeResponseDateTime }}
      </base-typology>
    </div>
    <div
      id="nominee-email"
      class="d-flex justify-content-start"
    >
      <base-typology
        class="label-column"
        tag="label"
      >
        {{ $t('user.email') }}
      </base-typology>
      <div v-if="typeof nomination.reference.nomineeEmail === 'string'">
        <base-typology>
          {{ nomination.reference.nomineeEmail }}
        </base-typology>
      </div>
      <div v-else>
        <div
          v-for="(email, index) in nomination.reference.nomineeEmail"
          :key="email"
        >
          <base-typology
            tag="a"
            :href="`mailto:${email.toLowerCase()}`"
          >
            {{ email.toLowerCase() }}
          </base-typology>
          <base-typology
            v-if="(index + 1) !== nomination.reference.nomineeEmail.length"
            style="white-space: pre"
          >
            {{ ', ' }}
          </base-typology>
        </div>
      </div>
    </div>
  </section>
  <table
    :aria-label="
      `${$t('shareholderId.matchingDetails')} ${identifiers.detailsByNominee.nomineeName}`
    "
  >
    <colgroup>
      <col style="width: 18%">
      <col style="width: 35%">
      <col style="width: 35%">
      <col style="width: 12%">
    </colgroup>
    <tbody>
      <tr id="header-row">
        <th></th>
        <th datatype="disclosed-by-account-servicer">
          <base-typology tag="label">
            {{ $t('shareholderId.disclosedAccountServicer') }}
          </base-typology>
        </th>
        <th datatype="disclosed-by-responder">
          <base-typology tag="label">
            {{ $t('shareholderId.disclosedResponder') }}
          </base-typology>
        </th>
        <th datatype="match-quality">
          <base-typology tag="label">
            {{ $t('shareholderId.quality') }}
          </base-typology>
        </th>
      </tr>
      <tr id="row-account-servicer-name">
        <td datatype="header-column">
          <base-typology>
            {{ $t('shareholderId.accountServicerName') }}:
          </base-typology>
        </td>
        <td datatype="disclosed-by-account-servicer">
          <base-typology>
            {{ identifiers.detailsByAccountServicer.accountServicerName }}
          </base-typology>
        </td>
        <td datatype="disclosed-by-responder">
          <base-typology>
            {{ identifiers.detailsByNominee.accountServicerName }}
          </base-typology>
        </td>
        <td datatype="match-quality">
          <base-typology>
            -
          </base-typology>
        </td>
      </tr>
      <tr id="row-responder-name">
        <td datatype="header-column">
          <base-typology>
            {{ $t('shareholderId.responderName') }}:
          </base-typology>
        </td>
        <td datatype="disclosed-by-account-servicer">
          <base-typology>
            {{ identifiers.detailsByAccountServicer.nomineeName }}
          </base-typology>
        </td>
        <td datatype="disclosed-by-responder">
          <base-typology>
            {{ identifiers.detailsByNominee.nomineeName }}
          </base-typology>
        </td>
        <td datatype="match-quality">
          <base-typology>
            -
          </base-typology>
        </td>
      </tr>
      <tr id="row-account-servicer-identifier">
        <td datatype="header-column">
          <base-typology>
            {{ $t('shareholderId.accountServicerId') }}:
          </base-typology>
        </td>
        <td datatype="disclosed-by-account-servicer">
          <base-typology>
            {{ identifiers.detailsByAccountServicer.accountServicerIdentifier }}
          </base-typology>
        </td>
        <td datatype="disclosed-by-responder">
          <base-typology>
            {{ identifiers.detailsByNominee.accountServicerIdentifier }}
          </base-typology>
        </td>
        <td datatype="match-quality">
          <aab-status
            :status-message="identifiers.accountServicerIdentifierMatchQuality.statusMessage"
            :style-type="identifiers.accountServicerIdentifierMatchQuality.styleType"
            has-background
          />
        </td>
      </tr>
      <tr id="row-responder-identifier">
        <td datatype="header-column">
          <base-typology>
            {{ $t('shareholderId.responderId') }}:
          </base-typology>
        </td>
        <td datatype="disclosed-by-account-servicer">
          <base-typology>
            {{ identifiers.detailsByAccountServicer.nomineeIdentifier }}
          </base-typology>
        </td>
        <td datatype="disclosed-by-responder">
          <base-typology>
            {{ identifiers.detailsByNominee.nomineeIdentifier }}
          </base-typology>
        </td>
        <td datatype="match-quality">
          <aab-status
            :status-message="identifiers.nomineeIdentifierMatchQuality.statusMessage"
            :style-type="identifiers.nomineeIdentifierMatchQuality.styleType"
            has-background
          />
        </td>
      </tr>
      <tr id="row-sub-total">
        <td datatype="header-column">
          <base-typology>
            {{ $t('gen.subTotal') }}:
          </base-typology>
        </td>
        <td datatype="disclosed-by-account-servicer">
          <base-typology>
            {{ formatNumber(totalNomination.totalBalanceByAccountServicer, 'decimal') }}
          </base-typology>
        </td>
        <td datatype="disclosed-by-responder">
          <base-typology>
            {{ formatNumber(totalNomination.totalBalanceByNominee, 'decimal') }}
          </base-typology>
        </td>
        <td datatype="match-quality">
          <aab-status
            :status-message="totalNomination.matchQuality.statusMessage"
            :style-type="totalNomination.matchQuality.styleType"
            has-background
          />
        </td>
      </tr>
      <tr id="accounts-header-row">
        <th :rowspan="accounts.length + 1"></th>
        <th datatype="disclosed-by-account-servicer">
          <div class="d-flex justify-content-between">
            <base-typology tag="label">
              {{ $t('gen.account') }}
            </base-typology>
            <base-typology tag="label">
              {{ $t('gen.balance') }}
            </base-typology>
          </div>
        </th>
        <th datatype="disclosed-by-responder">
          <div class="d-flex justify-content-between">
            <base-typology tag="label">
              {{ $t('gen.account') }}
            </base-typology>
            <base-typology tag="label">
              {{ $t('gen.balance') }}
            </base-typology>
          </div>
        </th>
        <th :rowspan="accounts.length + 1"></th>
      </tr>
      <tr
        v-for="(accountGroup, index1) in accounts"
        :id="`row-accounts-${index1}`"
        :key="index1"
      >
        <td
          datatype="disclosed-by-account-servicer"
          class="account-cell"
        >
          <div
            v-for="(account, index2) in accountGroup.accountServicerAccounts"
            :key="index2"
            :datatype="`account-${index2}`"
            class="d-flex justify-content-between"
          >
            <base-typology>{{ account.accountNumber }}</base-typology>
            <base-typology>{{ formatNumber(account.accountBalance, 'decimal') }}</base-typology>
          </div>
        </td>
        <td
          datatype="disclosed-by-responder"
          class="account-cell"
        >
          <div
            v-for="(account, index3) in accountGroup.responderAccounts"
            :key="index3"
            :datatype="`account-${index3}`"
            class="d-flex justify-content-between"
          >
            <base-typology>{{ account.accountNumber }}</base-typology>
            <base-typology>{{ formatNumber(account.accountBalance, 'decimal') }}</base-typology>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { formatNumber } from '@/utils/formatLib';

export default defineComponent({
  name: 'MatchingDetailsNomination',
  components: { BaseTypology },
  props: {
    nomination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    identifiers() {
      return this.nomination.matching.identifiers;
    },
    totalNomination() {
      return this.nomination.totalNomination;
    },
    accounts() {
      return this.nomination.matching.accounts;
    },
  },
  methods: {
    formatNumber,
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.d_flex;
@include lib.justify_content_start;
@include lib.justify_content_between;
@include lib.mb_2;

.label-column {
  flex-basis: 10%;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

tr {
  td, th {
    padding: 0.75rem 1rem 0.75rem 1rem;
    vertical-align: top;
    overflow-wrap: break-word;
    border: 1px solid lib.$n65;
  }
  td.account-cell div:not(:first-child) {
    padding: 1.5rem 0 0 0;
  }
}
</style>
