<template>
  <div>
    <base-data
      id="nominees"
      :columns="columnsAggregateNominees"
      :rows="aggNominees"
      :title="`${$tc('shareholderId.nominee', 2)} (${totalItems})`"
      :loading="loading"
      :error="error"
      :empty-message="$t('shareholderId.noResults',
                         { data: $tc('shareholderId.nominee', 2).toLowerCase() }
      )"
      :sort-on="sortBy"
      :sort-direction="sortDirection"
      :page-size="pageSize"
      :page-number="pageNumber"
      :total-items="totalItems"
      :action-bar="actionBar"
      selectable-rows
      @row-selected="toggleAccordion($event)"
      @request-update="updateAggregateNominees($event)"
      @action-bar-event="processActionBarEvent($event)"
    >
      <template
        v-for="nominee in aggNominees"
        :key="nominee.id"
        #[`accordion-slot-${nominee.id}`]
      >
        <nominee-accounts
          :nominee-uuid="nominee.id"
        />
      </template>
    </base-data>
    <aggregated-filters
      :open-modal="openFiltersModal"
      @close-filters="openFiltersModal = null"
      @apply-filters="updateAggregateNominees($event)"
      @reset-filters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getAggregatedNominees } from '@/services/sidService';
import useSidRequestStore from '@/stores/SidRequestStore';
import { em_folder as emptyIcon } from '@aab/sc-aab-icon-set';
import {
  amountOfAmountConverter,
  nomineeNameConverter,
} from '@/utils/modelConverters/baseDataTableConverters';
import BaseData from '@/components/base/BaseData.vue';
import NomineeAccounts from '@/components/shareholderId/nominees/NomineeAccounts.vue';
import AggregatedFilters from '@/components/shareholderId/nominees/AggregatedFilters.vue';

export default defineComponent({
  name: 'RequestNominees',
  components: {
    AggregatedFilters,
    NomineeAccounts,
    BaseData,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      aggNominees: [],
      loading: false,
      error: null,
      pageNumber: 1,
      pageSize: 25,
      totalItems: 0,
      filters: {
        searchBy: null,
        totalAmountFrom: null,
        totalAmountTo: null,
        responseRateFrom: null,
        responseRateTo: null,
        quality: null,
      },
      sortBy: 'aggregatedAmount',
      sortDirection: 'DESC',
      openFiltersModal: null,
      emptyIcon,
    };
  },
  computed: {
    actionBar() {
      return {
        search: {
          searchOptionsKey: 'shareholderId.nomineeSearchOptions',
          type: 'input',
        },
        filters: true,
      };
    },
    columnsAggregateNominees() {
      return [
        {
          key: 'officialName',
          label: this.$tc('shareholderId.nominee', 1),
          sortable: true,
          template: 'title',
          width: '45',
        },
        {
          key: 'aggregatedAmount',
          label: this.$t('gen.amount'),
          sortable: true,
          template: 'amountOfAmount',
          width: '20',
        },
        {
          key: 'responseRate',
          label: this.$t('shareholderId.responseRate'),
          sortable: true,
          template: 'progressBar',
          width: '15',
        },
        {
          key: 'matchingQuality',
          label: this.$t('shareholderId.quality'),
          sortable: true,
          template: 'status',
          width: '15',
        },
        {
          key: 'accordionOpen',
          template: 'accordion',
          width: '5',
        },
      ];
    },
  },
  async created() {
    await this.getAggregatedNominees();
  },
  methods: {
    async getAggregatedNominees() {
      this.loading = true;
      this.error = null;
      this.aggNominees = [];
      try {
        const data = await getAggregatedNominees(
          this.sidRequestStore.getSidUuid,
          this.pageNumber,
          this.pageSize,
          this.sortBy,
          this.sortDirection,
          this.filters,
        );
        this.aggNominees = Object.keys(data.aggNominees)
          .map((key) => ({
            id: data.aggNominees[key].uuid,
            officialName: nomineeNameConverter(data.aggNominees[key]),
            aggregatedAmount: amountOfAmountConverter(
              data.aggNominees[key].matchedAmount,
              data.aggNominees[key].aggregatedAmount,
            ),
            responseRate: data.aggNominees[key].responseRate,
            matchingQuality: data.aggNominees[key].matchingCategory,
            accordionOpen: false,
          }));
        this.totalItems = data.totalItems;
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getAggregatedNominees')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    async updateAggregateNominees(event) {
      if (event.pageSize) {
        this.pageSize = event.pageSize;
        this.pageNumber = event.pageNumber;
      } else if (event.sortOn || event.sortDir) {
        // aggregated is default when sorting is turned off
        this.sortBy = event.sortOn || 'aggregatedAmount';
        this.sortDirection = event.sortDir;
      } else if (event.filters) {
        this.filters.quality = event.filters.quality;
        this.filters.responseRateFrom = event.filters.responseRateFrom;
        this.filters.responseRateTo = event.filters.responseRateTo;
        this.filters.totalAmountFrom = event.filters.totalAmountFrom;
        this.filters.totalAmountTo = event.filters.totalAmountTo;
      }
      await this.getAggregatedNominees();
    },
    async resetFilters() {
      this.filters = {
        searchBy: null,
        totalAmountFrom: null,
        totalAmountTo: null,
        responseRateFrom: null,
        responseRateTo: null,
        quality: null,
      };
      this.pageNumber = 1;
      this.pageSize = 25;
      this.sortBy = 'aggregatedAmount';
      this.sortDirection = 'DESC';
      await this.getAggregatedNominees();
    },
    async processActionBarEvent(event) {
      if (event.event === 'search') {
        this.filters.searchBy = event.value;
        this.pageNumber = 1;
        await this.getAggregatedNominees();
      } else if (event.event === 'open-filters') {
        this.openFiltersModal = true;
      } else {
        // warning for developer - does not need translating
        console.warn(`Action bar generated unknown event: ${event.event}`);
      }
    },
    toggleAccordion(event) {
      this.aggNominees = this.aggNominees
        .map((nominee) => (nominee.id === event.rowId ? {
          ...nominee,
          accordionOpen: !nominee.accordionOpen,
        } : nominee));
    },
  },
});
</script>
