/**
 * Adjusts the date by a specified number of work days, skipping weekends.
 * @param {number} unix - The unix timestamp (ms).
 * @param {number} days - The number of work days to adjust by.
 * @returns {Date} - The adjusted date.
 */
function adjustWorkDays(unix, days) {
  const date = new Date(unix); // Create a copy of the date
  let i = 0;
  const increment = days > 0 ? 1 : -1;
  while (i !== days) {
    date.setDate(date.getDate() + increment);
    if (date.getDay() === 6) { // Saturday
      date.setDate(date.getDate() + (increment > 0 ? 2 : -1));
    } else if (date.getDay() === 0) { // Sunday
      date.setDate(date.getDate() + (increment > 0 ? 1 : -2));
    }
    i += increment;
  }
  return date;
}

/**
 * Adds work days to unix timestamp. It will skip weekend days.
 * @name addWorkDaysToUnix
 * @param {number} unix - The unix timestamp (ms).
 * @param {number} numberOfDays - Number of days to add.
 * @returns {Date|null} - The new date or null if parameters are invalid.
 */
export function addWorkDaysToUnix(unix, numberOfDays) {
  if (numberOfDays !== 0 && (Number.isNaN(Number(unix)) || !unix
    || Number.isNaN(Number(numberOfDays)) || !numberOfDays)) {
    return null;
  }
  return adjustWorkDays(unix, numberOfDays);
}

/**
 * Subtracts work days from unix timestamp. It will skip weekend days.
 * @name subtractWorkDaysToUnix
 * @param {number} unix - The unix timestamp (ms).
 * @param {number} numberOfDays - Number of days to subtract.
 * @returns {Date|null} - The new date or null if parameters are invalid.
 */
export function subtractWorkDaysToUnix(unix, numberOfDays) {
  if (numberOfDays !== 0 && (Number.isNaN(Number(unix)) || !unix
      || Number.isNaN(Number(numberOfDays)) || !numberOfDays)) {
    return null;
  }
  return adjustWorkDays(unix, -numberOfDays);
}
