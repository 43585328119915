<template>
  <div>
    <!--loading-->
    <div
      v-if="loading"
      class="my-1"
    >
      <aab-spinner
        size="5"
        hide-overlay
      />
    </div>
    <!--error-->
    <div
      v-else-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
    <div v-else>
      <div
        class="holder-detail"
        style="margin-bottom: 15px"
      >
        <div
          class="d-flex justify-content-start"
          datatype="holder-name"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('disclosureDetails.holderName') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.holderName }}
          </base-typology>
        </div>
        <div
          class="d-flex justify-content-start"
          datatype="holder-identifier"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('disclosureDetails.identifier') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.holderIdentifier }}
          </base-typology>
        </div>
        <div
          class="d-flex justify-content-start"
          datatype="holder-amount"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('gen.amount') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.securities }}
          </base-typology>
        </div>
        <div
          class="d-flex justify-content-start"
          datatype="holder-entity"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('disclosureDetails.entityType') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.entityType }}
          </base-typology>
        </div>
        <div
          class="d-flex justify-content-start"
          datatype="holder-email"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('disclosureDetails.email') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.email }}
          </base-typology>
        </div>
        <div
          class="d-flex justify-content-start"
          datatype="holder-address"
        >
          <base-typology
            tag="label"
            class="table-column"
          >
            {{ $t('disclosureDetails.address') }}:
          </base-typology>
          <base-typology>
            {{ disclosureDetails.address.streetName ? disclosureDetails.address.streetName
              + ' - ' + disclosureDetails.address.townName : disclosureDetails.address.townName }}
          </base-typology>
        </div>
      </div>
      <base-data-table
        caption="Disclosure details"
        :rows="respondingIntermediaries"
        :columns="columns"
        variant="grid"
        selectable-rows
        row-selected
        @row-selected="openAccountsAndHoldings($event)"
      />
    </div>
  </div>
  <accounts-holdings
    v-if="openModal"
    :open-modal="openModal"
    :intermediary-name="activeIntermediaryName"
    :accounts-holdings="activeAccountsAndHoldings"
    @close-modal="closeAccountsAndHoldings"
  />
</template>

<script>
import useSidRequestStore from '@/stores/SidRequestStore';
import { getDisclosureDetails } from '@/services/sidService';
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import AccountsHoldings from '@/components/shareholderId/report/AccountsHoldings.vue';

export default {
  name: 'HolderDetails',
  components: { AccountsHoldings, BaseDataTable, BaseTypology },
  props: {
    shareholder: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      disclosureDetails: null,
      respondingIntermediaries: null,
      openModal: false,
      activeAccountsAndHoldings: null,
      activeIntermediaryName: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          key: 'name',
          label: this.$t('shareholderId.accountServicer'),
          sortable: false,
          width: '45',
          template: 'title',
        },
        {
          key: 'lastResponse',
          label: this.$t('shareholderId.lastResponse'),
          sortable: false,
          width: '35',
          template: 'title',
        },
        {
          key: 'contactPerson',
          label: this.$t('shareholderId.contactPerson'),
          sortable: false,
          width: '20',
          template: 'title',
        },
      ];
    },
  },
  async created() {
    await this.getDisclosureDetails();
  },
  methods: {
    setAccountServicerName(resp) {
      return {
        value: resp.name,
        subtitle: resp.intermediaryIdentifier,
      };
    },
    setAccountServicerContact(resp) {
      return {
        value: resp.contactPerson ? resp.contactPerson.name : this.$t('utils.undisclosed'),
        subtitle: resp.contactPerson ? resp
          .contactPerson.email : this.$t('utils.undisclosed'),
      };
    },
    setLastResponse(resp) {
      return {
        value: resp.responseId,
        subtitle: resp.disclosureDate,
      };
    },
    openAccountsAndHoldings(row) {
      const id = row.rowId;
      this.activeAccountsAndHoldings = this.disclosureDetails
        .respondingIntermediaries[id].accountsAndHoldings;
      this.activeIntermediaryName = this.disclosureDetails
        .respondingIntermediaries[id].name;
      this.openModal = true;
    },
    closeAccountsAndHoldings() {
      this.activeAccountsAndHoldings = null;
      this.activeIntermediaryName = null;
      this.openModal = null;
    },
    async getDisclosureDetails() {
      this.loading = true;
      this.error = null;
      try {
        const data = await getDisclosureDetails(
          this.sidRequestStore.getSidUuid,
          this.shareholder.disclosureResponseId,
        );
        if (data) {
          this.disclosureDetails = data;
          this.respondingIntermediaries = data.respondingIntermediaries.map((resp, index) => ({
            id: index,
            name: this.setAccountServicerName(resp),
            contactPerson: this.setAccountServicerContact(resp),
            lastResponse: this.setLastResponse(resp),
          }));
        }
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getDisclosureDetails')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

.table-column {
  flex-basis: 15%;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

#error {
  width: 25%;
  margin: auto;
}

</style>
