<template>
  <table
    :aria-label="caption"
  >
    <colgroup>
      <col
        v-for="column in columns"
        :key="column.key"
        :class="`width${column.width}`"
      >
    </colgroup>
    <tbody>
      <tr
        id="header-row"
        :class="variant"
      >
        <th
          v-for="(column) in columns"
          :key="column.key"
        >
          <div
            :id="`header-${column.key}`"
            :class="`d-flex align-items-centre header-${column.template}`"
          >
            <div
              v-if="column.template !== 'checkbox'"
              :id="`header-label-${column.key}`"
              :class="`header-content ${column.sortable ? 'sortable-label' : ''}
              ${column.template}`"
              @keyup.enter="column.sortable && sortColumn(column.sortKey||column.key)"
              @click="column.sortable && sortColumn(column.sortKey||column.key)"
            >
              <base-typology
                :tag="column.tag || 'label'"
                :color="!column.tag ? 'green' : null"
                :class="column.sortable ? 'sortable-label' : ''"
                :variant="column.variant"
              >
                {{ column.label }}
              </base-typology>
              <base-icon
                v-if="column.sortable"
                tabindex="0"
                :aria-label="getHeaderAriaLabel(column.key)"
                :label="getHeaderAriaLabel(column.key)"
                role="button"
                class="sort-icon"
                :class="sortDir === 'ASC' &&
                  sortedField === (column.sortKey||column.key) ? 'rotate' : ''"
                size="3"
                :color="sortedField === (column.sortKey||column.key) ? sortActive : sortInactive"
                icon-name="sy_arrow_sort"
              />
            </div>
            <aab-checkbox
              v-if="column.template === 'checkbox' && column.selectAll"
              :id="`header-checkbox-${column.key}`"
            >
              <input
                id="selectAll"
                slot="input-slot"
                name="selectAll"
                type="checkbox"
                aria-label="checkbox for select all rows in table"
                style="margin: auto"
                :checked="allRowsChecked(column.key)"
                @click="$emit('select-all-rows')"
                @keyup.enter="$emit('select-all-rows')"
              >
            </aab-checkbox>
            <aab-info-popover
              v-if="column.tooltip"
              :id="`info-${column.key}`"
              class="info-popover"
            >
              <base-typology
                v-if="column.tooltip.title"
                tag="h4"
              >
                {{ column.tooltip.title }}
              </base-typology>
              <base-typology tag="p">
                {{ column.tooltip.text }}
              </base-typology>
            </aab-info-popover>
          </div>
        </th>
      </tr>
      <template
        v-for="(row, index) in rows"
        :key="row.id"
      >
        <tr
          :id="`row-${index}`"
          tabindex="0"
          :class="tableRowClass(row)"
          @click="selectableRows && !row.disabled && rowSelected(row.id)"
          @keyup.enter="selectableRows && !row.disabled && rowSelected(row.id)"
        >
          <td
            v-for="(column, key) in columns"
            :key="key"
            :class="column.template"
            :datatype="column.key"
            class="center-align"
          >
            <div>
              <aab-checkbox v-if="column.template === 'checkbox'">
                <input
                  slot="input-slot"
                  name="individualCheckbox"
                  type="checkbox"
                  aria-label="checkbox for select all rows in table"
                  style="margin: auto"
                  :checked="row[column.key]"
                  @keyup.enter="selectableRows && rowSelected(row.id)"
                >
              </aab-checkbox>
              <div v-else-if="row[column.key] && column.template === 'amount'">
                <base-typology>
                  {{ formatNumber(row[column.key].value, row[column.key].type) }}
                </base-typology>
              </div>
              <div v-else-if="row[column.key] && column.template === 'amountOfAmount'">
                <base-typology>
                  {{ formatNumber(row[column.key].partialValue, 'decimal') }}{{ $t('gen.of') }}
                </base-typology>
                <base-typology variant="bold">
                  {{ formatNumber(row[column.key].value, 'decimal') }}
                </base-typology>
              </div>
              <div
                v-else-if="(row[column.key] || row[column.key] === 0)
                  && column.template === 'progressBar'"
              >
                <aab-progress-bar
                  background-color="grey"
                  progress-max="100"
                  :textual-progress-value="formatNumber(row[column.key] / 100, 'percent')"
                  textual-progress-aria-label="Textual representation of progressbar"
                  :visual-progress-value="row[column.key]"
                  visual-progress-aria-label="Visual representation of progressbar"
                ></aab-progress-bar>
              </div>
              <div v-else-if="row[column.key] && column.template === 'status'">
                <aab-status
                  :status-message="row[column.key].statusMessage"
                  :style-type="row[column.key].styleType"
                  has-background
                />
              </div>
              <div
                v-else-if="row[column.key]
                  && (column.template === 'mainTitle' || column.template === 'title')"
                class="title-container"
              >
                <div
                  v-if="row[column.key].icon"
                  class="icon-container"
                  datatype="icon"
                >
                  <base-icon
                    :icon-name="row[column.key].icon"
                    size="3"
                    :label="row[column.key].iconLabel"
                  />
                  <base-typology variant="small">
                    {{ row[column.key].iconSubtitle }}
                  </base-typology>
                </div>
                <div
                  datatype="text"
                >
                  <base-typology
                    :variant="column.template === 'mainTitle' ? 'bold' : null"
                    :color="column.template === 'mainTitle' ? 'light-green' : null"
                  >
                    {{ row[column.key].value }}
                  </base-typology><br>
                  <base-typology
                    variant="small"
                    color="gray"
                  >
                    {{ row[column.key].subtitle }}
                  </base-typology>
                </div>
              </div>
              <div
                v-else-if="column.template === 'removable'"
                class="d-flex justify-content-center"
              >
                <base-icon
                  icon-name="sy_shapes_close_large"
                  size="3"
                  label="remove row from table"
                />
              </div>
              <div
                v-else-if="column.template === 'addable'"
                class="d-flex justify-content-center"
              >
                <base-icon
                  icon-name="sy_shapes_plus_large"
                  size="3"
                  label="add row to different table"
                />
              </div>
              <div
                v-else-if="column.template === 'accordion'"
                class="d-flex justify-content-center"
              >
                <span :class="`icon icon-rotate ${row.accordionOpen ? 'expand' : ''}`">
                  <base-icon
                    icon-name="sy_arrow_chevron_down"
                    size="3"
                    :label="row.accordionOpen
                      ? $t('utils.clickCloseAccordion')
                      : $t('utils.clickOpenAccordion')"
                  />
                </span>
              </div>
              <base-typology v-else>
                {{ row[column.key] }}
              </base-typology>
            </div>
          </td>
        </tr>
        <transition name="accordion">
          <tr
            v-if="row.accordionOpen"
            :id="`row-${index}-accordion`"
            :class="variant"
          >
            <td :colspan="columns.length">
              <slot :name="`accordion-slot-${row.id}`"></slot>
            </td>
          </tr>
        </transition>
      </template>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { g300, gm75 } from '@aab/sc-styles-colors';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { formatNumber } from '@/utils/formatLib';
import getCountryName from '@/utils/country';

export default defineComponent({
  name: 'BaseDataTable',
  components: { BaseIcon, BaseTypology },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sortOn: {
      type: String,
      default: null,
    },
    sortDirection: {
      type: String,
      validator(val) {
        return ['ASC', 'DESC'].includes(val);
      },
      default: null,
    },
    variant: {
      type: String,
      validator(val) {
        return ['grid', 'horizontalOnly', 'bar'].includes(val);
      },
      default: 'grid',
    },
    caption: {
      type: String,
      required: true,
    },
    selectableRows: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['request-update', 'row-selected', 'select-all-rows', 'remove-row'],
  data() {
    return {
      sortActive: g300,
      sortInactive: gm75,
      sortDir: this.sortDirection,
      sortedField: this.sortOn,
    };
  },
  methods: {
    formatNumber,
    getCountryName,
    sortColumn(fieldKey) {
      if (this.sortedField === fieldKey) {
        if (this.sortDir === 'ASC') {
          this.sortDir = 'DESC';
          this.sortedField = '';
        } else {
          this.sortDir = 'ASC';
        }
      } else {
        this.sortedField = fieldKey;
        this.sortDir = 'DESC';
      }
      this.$emit('request-update', { sortOn: this.sortedField, sortDir: this.sortDir });
    },
    getHeaderAriaLabel(fieldKey) {
      if (fieldKey === this.sortedField) {
        return this.sortDir === 'ASC' ? 'Stop sorting' : 'Sort ascending';
      }
      return 'Sort descending';
    },
    rowSelected(id) {
      this.$emit('row-selected', { rowId: id });
    },
    allRowsChecked(key) {
      const amountSelected = this.rows
        .filter((item) => item[key])
        .length;
      return amountSelected === this.rows.length;
    },
    tableRowClass(row) {
      let classes = this.variant;
      if (row.disabled) {
        classes = classes.concat(' disabled');
        return classes;
      }
      if (this.selectableRows) {
        classes = classes.concat(' selectable');
      }
      if (row.accordionOpen) {
        classes = classes.concat(' accordion-open');
      }
      if (row.checked && this.checkIfColumnContainsKey('checked')) {
        classes = classes.concat(' selected');
      }
      return classes;
    },
    checkIfColumnContainsKey(key) {
      const columnKeys = this.columns.map((column) => column.key);
      return (columnKeys.includes(key));
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.align_items_center;
@include lib.d_flex;
@include lib.col_1;
@include lib.col_11;
@include lib.text_center;
@include lib.no_gutters;
@include lib.justify_content_center;
@include lib.row;
@include lib.pl_1;
@include lib.m_0;

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

tr:has(th) {
  &.grid {
    border: 1px solid lib.$n65;
  }

  &.horizontalOnly {
    border-bottom: 1px solid lib.$n65;
  }

  th {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
}

tr:has(td) {
  &.disabled {
    opacity: 0.33;
  }
  &:hover.selectable {
    cursor: pointer;
    background-color: lib.$g50;
  }
  &.selected{
    background-color: lib.$gm55;
  }
  &.bar, &.grid {
    border: 1px solid lib.$n65;
  }
  &.horizontalOnly:not(.accordion-open) {
    border-bottom: 1px solid lib.$n65;
  }
}

tr {
  td {
    padding: 0.75rem 1rem 0.75rem 1rem;
    vertical-align: top;
    overflow-wrap: break-word;

    &:hover.removable {
      cursor: pointer;
      background-color: lib.$g50;
    }
  }
}

.title-cell {
  padding: 0 1rem 0 1rem;
}

.header-content {
  display: flex;
  align-items: center;
  &.amount,
  &.amountOfAmount {
    flex-direction: row-reverse;
  }
}

.header-amount,
.header-amountOfAmount {
  justify-content: end;
}

.header-checkbox {
  justify-content: center;
  vertical-align: middle;
}

.sortable-label:hover, {
  text-decoration: underline;
  cursor: pointer;
  color: lib.$g300;
}

.info-popover {
  margin-left: 8px;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.width40 {
  width: 40%;
}

.width45 {
  width: 45%;
}

.sort-icon {
  transform: rotate( 0deg );
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sort-icon.rotate {
  transform: rotate(-180deg);
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}

.checkbox {
  border-right: none;
  margin-right: 0;
  text-align: center;
  vertical-align: middle;
}

.center-align {
  vertical-align: middle;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.center-block {
  margin: 0;
}

.amount,
.amountOfAmount {
  text-align: right;
}

.icon-rotate {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.expand {
  transform: rotate(-180deg);
}

.accordion-enter-active,
.accordion-leave-active {
  transition: line-height 0.3s ease;
}

.accordion-enter-from,
.accordion-leave-to {
  line-height: 0;
}

</style>
