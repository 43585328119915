<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue';

/**
 * SidHomeView component contains the router view for everything related to SID
 * The individual views (SidCreateView, SidListView and SidRequestView)
 * each take care of their own layout.
 */
export default defineComponent({
  name: 'SidHomeView',
});
</script>
