import * as validators from '@vuelidate/validators';
import { specialCharacters } from '@/utils/reference/globalConstants';
import i18n from '@/i18n';

const { t } = i18n.global;

// create custom messageParams, so we can pass our own params to i18n
const messageParams = (params) => ({
  ...params,
});

// create custom messagePath, so we can determine where in i18n our validation error messages are
const messagePath = ({ $validator }) => `validationErrors.${$validator}`;

// wrapping validations in our i18n instance so error messages are automated
const withI18nMessage = validators.createI18nMessage(
  { t: t.bind(i18n), messageParams, messagePath },
);

// create generic regular expressions to be used in validations
const atLeastOneLowerCaseCharRegExp = new RegExp(`^(?=.*[a-z])[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneUpperCaseCharRegExp = new RegExp(`^(?=.*[A-Z])[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneDigitRegExp = new RegExp(`^(?=.*\\d)[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneSpecialCharRegExp = new RegExp(`^(?=.*[${specialCharacters}])[A-Za-z\\d${specialCharacters}]+$`);

const isinRegExp = /^[A-Z]{2}[A-Z0-9]{9}[0-9]$/;

/**
 * Create custom validation with custom messagePath and messageParams.
 * @param validator
 * @param messagePathKey - if not passed, default messagePath will be used
 * @param messageParamsValue - if not passed, default messageParams will be used
 * @param withArguments - has to be true if validator has arguments
 * @returns {*}
 */
function createCustomValidation(
  validator,
  messagePathKey,
  messageParamsValue,
  withArguments,
) {
  const options = {
    messagePath,
    messageParams,
    withArguments,
  };
  if (messagePathKey) {
    options.messagePath = () => messagePathKey;
  }
  if (messageParamsValue) {
    options.messageParams = () => messageParamsValue;
  }
  return withI18nMessage(
    validator,
    options,
  );
}

const required = createCustomValidation(
  validators.required,
  null,
  null,
  false,
);

const requiredSelect = createCustomValidation(
  validators.required,
  'validationErrors.selectValue',
  null,
  false,
);

const requiredEnterValidValue = createCustomValidation(
  validators.required,
  'validationErrors.invalidValue',
  null,
  false,
);

const maxValue = createCustomValidation(
  validators.maxValue,
  'validationErrors.maxValue',
  null,
  true,
);

const minValue = createCustomValidation(
  validators.minValue,
  'validationErrors.minValue',
  null,
  true,
);

const minLength = createCustomValidation(
  validators.minLength,
  null,
  null,
  true,
);

const sameAs = createCustomValidation(
  validators.sameAs,
  null,
  null,
  true,
);

const atLeastOneLowerCaseChar = createCustomValidation(
  validators.helpers.regex(atLeastOneLowerCaseCharRegExp),
  null,
  null,
  false,
);

const atLeastOneUpperCaseChar = createCustomValidation(
  validators.helpers.regex(atLeastOneUpperCaseCharRegExp),
  null,
  null,
  false,
);

const atLeastOneDigit = createCustomValidation(
  validators.helpers.regex(atLeastOneDigitRegExp),
  null,
  null,
  false,
);

const atLeastOneSpecialChar = createCustomValidation(
  validators.helpers.regex(atLeastOneSpecialCharRegExp),
  null,
  null,
  false,
);

const shouldMatchIsinPattern = createCustomValidation(
  validators.helpers.regex(isinRegExp),
  'validationErrors.invalidValue',
  { field: i18n.global.t('shareholderId.isin') },
  false,
);

const dateShouldNotBeBefore = createCustomValidation(
  validators.minValue,
  'validationErrors.dateShouldNotBeBefore',
  null,
  true,
);

export {
  atLeastOneLowerCaseChar,
  atLeastOneUpperCaseChar,
  atLeastOneDigit,
  atLeastOneSpecialChar,
  required,
  requiredSelect,
  requiredEnterValidValue,
  maxValue,
  minValue,
  minLength,
  sameAs,
  shouldMatchIsinPattern,
  dateShouldNotBeBefore,
};
