<template>
  <div class="chart-wrapper">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Chart from 'chart.js/auto';
import { formatNumberForCharts } from '@/utils/formatLib';

export default defineComponent({
  name: 'BaseBarChart',
  props: {
    chartId: {
      required: true,
      type: String,
    },
    data: {
      required: true,
      type: Array,
    },
    categories: {
      required: true,
      type: Array,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    units: {
      type: String,
      default: null,
    },
  },
  mounted() {
    /* eslint-disable no-new */
    new Chart(
      document.getElementById(this.chartId),
      {
        type: 'bar',
        data: {
          labels: Object.keys(this.data).map((d) => this.data[d].label),
          datasets: Object.keys(this.categories)
            .map((key) => ({
              axis: this.horizontal ? 'y' : 'x',
              label: this.categories[key].name,
              data: Object.keys(this.data).map((d) => {
                const entry = this.data[d];
                return entry[this.categories[key].key];
              }),
              backgroundColor: this.categories[key].colour,
            })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: this.horizontal ? 'y' : 'x',
          locale: this.$i18n.locale,
          scales: {
            x: {
              ticks: {
                /* eslint-disable-next-line */
                callback: (value) => formatNumberForCharts(value, this.units),
              },
            },
          },
        },
      },
    );
  },
});
</script>

<style scoped lang="scss">
.chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
