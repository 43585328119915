import moment from 'moment-timezone';
import i18n from '@/i18n';

function formatDateCountdown(iso8601DateStr, maxDaysRemaining) {
  const todaysDateFormatted = moment().local().format('YYYY-MM-DD');
  const localLocale = moment.parseZone(iso8601DateStr, 'YYYY-MM-DD');
  const response = { inFutureRange: false, text: '' };
  if (iso8601DateStr === i18n.global.t('utils.unknown')) {
    response.text = i18n.global.t('utils.unknown');
    return response;
  }
  if (iso8601DateStr === todaysDateFormatted) {
    response.text = i18n.global.t('days.today');
    return response;
  }
  let daysRemaining = localLocale.diff(todaysDateFormatted, 'days');
  if (daysRemaining > 0 && daysRemaining <= maxDaysRemaining) {
    response.inFutureRange = true;
    if (daysRemaining === 1) {
      response.text = i18n.global.t('days.tomorrow');
    } else {
      response.text = `${daysRemaining} ${i18n.global.t('days.daysLeft')}`;
    }
    return response;
  }
  if (Math.abs(daysRemaining) < maxDaysRemaining) {
    daysRemaining -= 1;
    if (daysRemaining === -1) {
      response.text = i18n.global.t('days.yesterday');
      return response;
    }
    response.text = `${Math.abs(daysRemaining)} ${i18n.global.t('days.daysAgo')}`;
    return response;
  }
  response.text = localLocale.format('MMM D, YYYY');
  return response;
}

const formatDate = (iso8601DateStr) => {
  if (iso8601DateStr === i18n.global.t('utils.unknown')) {
    return i18n.global.t('utils.unknown');
  }
  const localLocale = moment.parseZone(iso8601DateStr);
  localLocale.locale('en');
  return localLocale.format('MMM D, YYYY');
};

const formatDateBackEnd = (iso8601DateStr) => {
  if (!iso8601DateStr || Number.isNaN(Date.parse(iso8601DateStr))) {
    return null;
  }
  const localLocale = moment.parseZone(iso8601DateStr);
  return localLocale.format('YYYY-MM-DD');
};

const formatDateLong = (iso8601DateStr) => {
  const localLocale = moment.parseZone(iso8601DateStr);
  localLocale.locale('en');
  const formatted = localLocale.format('DD MMMM YYYY');
  if (formatted === 'Invalid date') {
    return null;
  }
  return formatted;
};

/**
 * Create a formatted dateTime with fully written out timezone
 * adjusted for the user's local timezone.
 * I.e. when user is in CEST timezone, 2023-06-23T14:25:00+02:00
 * becomes Jun 23, 2023 - 14:25 CEST.
 * When user is in GMT timezone, 2023-06-23T14:25:00+01:00
 * becomes Jun 23, 2023 - 12:25 GMT
 * @name formatDateTime
 * @param iso8601DateStr   the raw matching details data
 * @returns {String}       Matching details data, formatted
 */
const formatDateTime = (iso8601DateStr) => {
  const timezone = moment.tz.guess(true);
  const localLocale = moment(iso8601DateStr).tz(timezone);
  localLocale.locale('en');
  return localLocale.format('MMM D, YYYY - HH:mm z');
};

const formatDateTimeFromUnix = (unix) => moment.tz(new Date(unix * 1000), 'Europe/Amsterdam').format();

const formatUnixFromDateTime = (dateTime) => Math.round(new Date(dateTime).getTime() / 1000);

/**
 * Format number according to i18n, catching values that are not numbers
 * @name formatNumber
 * @param value        the value to be formatted
 * @param format       format of number (decimal or percent)
 * @returns {String}   formatted number
 */
const formatNumber = (value, format) => {
  if ((value || value === 0)
    && typeof Number(value) === 'number'
    && !Number.isNaN(Number(value))) {
    return i18n.global.n(value, format);
  }
  return value;
};

/**
 * Format numbers for charts.
 * If formatting random numbers not generated for axes by Chartjs,
 * this function needs changing to adjust for additional formatting.
 * @name formatNumber
 * @param value        the value to be formatted
 * @param units        unit (thousand, million, or trillion)
 * @returns {String}   formatted number
 */
const formatNumberForCharts = (value, units) => {
  if (typeof Number(value) === 'number' && !Number.isNaN(Number(value))) {
    if (units === 'thousand') {
      return `${value / 1e3}`;
    }
    if (units === 'million') {
      return `${value / 1e6}`;
    }
    if (units === 'trillion') {
      return `${value / 1e9}`;
    }
    return value;
  }
  return value;
};

/**
 * Sets first char to lower case. Useful for i18n concatenation.
 *
 * @name lowerCaseFirstChar
 * @param string
 * @returns {*|string}
 */
const lowerCaseFirstChar = (string) => {
  if (!string || typeof string !== 'string') {
    return string;
  }
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export {
  formatDate,
  formatDateBackEnd,
  formatDateLong,
  formatDateTime,
  formatDateTimeFromUnix,
  formatUnixFromDateTime,
  formatNumber,
  formatNumberForCharts,
  lowerCaseFirstChar,
  formatDateCountdown,
};
