import axios from 'axios';
import getEnv from '@/utils/env';
import useUserStore from '@/stores/UserStore';
import router from '@/router/index';

window.axios = axios;
axios.defaults.withCredentials = false;

const backendUrl = getEnv('VUE_APP_BACKEND_URL');
if (backendUrl) {
  axios.defaults.baseURL = `${backendUrl}/api/`;
} else {
  axios.defaults.baseURL = '/api/';
}

function refreshToken() {
  return axios.post('v1/refresh', {
    refreshToken: localStorage.getItem('refreshToken'),
  });
}

function updateTokens(tokens) {
  localStorage.setItem('accessToken', tokens.accessToken);
  localStorage.setItem('refreshToken', tokens.refreshToken);
}

/* eslint-disable no-param-reassign */
axios.interceptors.request.use(
  async (config) => {
    if (!config.headers) {
      config.headers = {};
    }

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  async (error) => {
    Promise.reject(error);
  },
);
/* eslint-enable no-param-reassign */

axios.interceptors.response.use(
  async (res) => res,
  async (err) => {
    if (err.response) {
      if (err.response.status === 401 && err.config.url !== 'v1/login' && err.config.url !== 'v1/account/activate') {
        // Access Token was expired
        const userStore = useUserStore();
        try {
          const refreshResponse = await refreshToken();
          updateTokens(refreshResponse.data);
          return axios(err.config);
        } catch (innerErr) {
          if (innerErr.config.url === 'v1/refresh') {
            // refresh token failed (probably because it also expired)
            userStore.logout();
            await router.push({ name: 'LoginView' });
          }
          return Promise.reject(innerErr);
        }
      }
    }

    return Promise.reject(err);
  },
);
