/**
 * Generates a validation message for a specific field based on Vuelidate errors.
 *
 * @param {Object} vuelidateErrors - The Vuelidate errors object containing validation errors.
 * @param {Object} vuelidateData - The Vuelidate data object containing validation state.
 * @param {string} vuelidateFieldName - The name of the field
 *   for which the validation message is generated.
 * @returns {string} - The validation message for the specified field,
 *   or an empty string if there are no errors.
 *
 * @example
 * // Assuming `vuelidateErrors` and `vuelidateData` are provided by Vuelidate
 * const message = createValidationMessage(vuelidateErrors, vuelidateData, 'issuerUuid');
 * console.log(message); // Outputs the validation message for the 'issuerUuid' field
 */
export default function createValidationMessage(
  vuelidateErrors,
  vuelidateData,
  vuelidateFieldName,
) {
  if (!vuelidateErrors || !vuelidateData[vuelidateFieldName] || !vuelidateFieldName) {
    return '';
  }
  return vuelidateData[vuelidateFieldName].$error ? vuelidateErrors.filter((e) => e.$property === vuelidateFieldName)[0].$message : '';
}
