<template>
  <div class="z-1 position-absolute">
    <div class="em-brand-wrapper overflow-show d-flex">
      <div class="em-brand d-inline-block align-bottom">
        <div class="em-brand-logo">
          <img
            class="em-brand-logo-width"
            :src="logo"
            alt="ABN AMRO Home"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brandLogo from '@/assets/brand-logo.svg';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AabLogo',
  data() {
    return {
      logo: brandLogo,
    };
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.align_bottom;
@include lib.d_flex;
@include lib.d_inline_block;
@include lib.overflow_show;
@include lib.position_absolute;

.z-1 {
  z-index: 1;
}

@media (max-width: 576px) {
  .em-brand-logo {
    margin: 1px;
    width: 32px;
    overflow: hidden;
  }

  .em-brand-logo-width {
    width: 176px;
  }

  .container-fluid {
    padding: 0;
  }
}

@media (min-width: 576px) {
  .em-brand-wrapper .em-brand {
    width: 176px;
    background-size: contain;
  }

  .em-brand-wrapper .em-brand.em-brand-privatebanking {
    background-size: 333px 44px;
    width: 333px;
  }
}

@media (max-width: 768px) {
  .em-brand-wrapper .em-brand {
    background-size: 176px 44px !important;
    width: 32px !important;
  }

  .em-brand-wrapper .em-brand.em-brand-privatebanking {
    background-size: 333px 44px !important;
  }

  .em-brand-logo {
    margin: 1px;
    width: 32px;
    overflow: hidden;
  }

  .em-brand-logo-width {
    width: 176px;
  }
}

@media (min-width: 992px) {
  .em-header .em-brand-wrapper .em-brand .em-brand-logo:before {
    content: "";
    background: url('@/assets/background-brand-logo.svg') left top no-repeat;
    width: 33px;
    height: 17px;
    margin-top: 51px;
    position: absolute;
  }
}

</style>
