<template>
  <div id="wrapper">
    <div class="controls mb-2">
      <base-button
        id="add-user"
        class="ml-2"
        :text="$t('userManagement.addUser')"
        :style-type="'primary'"
        icon="sy_shapes_plus_large"
        full-width
        @click="handleAddUser()"
      />
    </div>
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <div v-else>
      <base-table
        :fields="fields"
        :items="users"
        :error="error"
        :empty-message="$t('userManagement.noUsers')"
        @icon-clicked="handleClick($event)"
      ></base-table>
      <aab-pagination
        items-per-page="[25,50,75]"
        :selected-page-item="pageSize"
        :selected-page-number="pageNumber"
        :total-items="totalItems"
        @aab-pagination-updated="updateUsers($event)"
      />
    </div>
  </div>
  <div>
    <aab-modal
      modal-size="medium"
      :open="showModal"
      @modal-close="cancelDelete()"
    >
      <div slot="modal-content">
        <base-typology>
          {{ $t('utils.deleteConfirmation',
                { item: deletionCheckMessage }) }}
        </base-typology>
      </div>
      <!-- eslint-disable-next-line -->
      <div slot="modal-footer" class="footer">
        <base-button
          id="cancelDelete"
          style-type="secondary"
          :text="$t('utils.cancel')"
          icon="sy_arrow_chevron_left"
          @click="cancelDelete()"
        />
        <base-button
          id="confirmDelete"
          style-type="primary"
          :text="$t('utils.continue')"
          icon="sy_tools_trash"
          @click="confirmDelete()"
        />
      </div>
    </aab-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTable from '@/components/base/BaseTable.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import {
  sy_tools_trash as deleteIcon,
} from '@aab/sc-aab-icon-set';
import { deleteUser, getUsers } from '@/services/userService';
import navItems from '@/utils/reference/navItems';
import { g300 } from '@aab/sc-styles-colors';
import BaseTypology from '@/components/base/BaseTypology.vue';

export default defineComponent({
  name: 'UserList',
  components: { BaseTable, BaseButton, BaseTypology },
  emits: ['change-title'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'username',
          label: 'Username',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'roles',
          label: 'Roles',
          sortable: false,
        },
        {
          key: 'accountStatus',
          label: 'Account status',
          sortable: true,
          template: 'label',
        },
        {
          key: 'lockedStatus',
          label: 'Locked',
          sortable: true,
          template: 'label',
        },
        {
          key: 'action',
          label: 'Actions',
          sortable: false,
          template: 'icon',
        },
      ],
      users: [],
      selectedUser: null,
      pageSize: 25,
      pageNumber: 1,
      totalItems: null,
      error: null,
      loading: false,
      showModal: false,
    };
  },
  computed: {
    deletionCheckMessage() {
      return this.selectedUser ? `user ${this.selectedUser.name}?` : '';
    },
  },
  created() {
    this.getDataUsers();
    this.$emit('change-title', this.$t('userManagement.userOverview'), this.$t('userManagement.label'));
  },
  methods: {
    async getDataUsers() {
      this.loading = true;
      try {
        const data = await getUsers(this.pageSize, this.pageNumber);
        if (data) {
          this.users = data.users
            .map((user) => ({
              ...user,
              action: [{
                size: '3',
                svg: deleteIcon,
                color: g300,
                action: 'delete',
                data: user.id,
                ariaLabel: 'delete user',
                role: 'button',
              }],
            }));
          this.totalItems = data.totalItems;
        }
      } catch (e) {
        this.error = `${this.$t('userManagement.errors.getUsers')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    handleAddUser() {
      this.$router.push(navItems.userManagement.addUser.route);
    },
    async updateUsers(event) {
      this.pageSize = event.detail.value.itemsPerPage;
      this.pageNumber = event.detail.value.pageNumber;
      await this.getDataUsers();
    },
    handleClick(event) {
      if (event.detail.name === 'delete') {
        this.selectedUser = this.users
          .find((user) => user.id === event.detail.data);
        this.showModal = true;
      }
    },
    async confirmDelete() {
      this.showModal = false;
      try {
        await deleteUser(this.selectedUser.id);
        await this.getDataUsers();
      } catch (e) {
        this.error = `${this.$t('userManagement.errors.deleteUser')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.selectedUser = null;
      }
    },
    cancelDelete() {
      this.showModal = false;
      this.selectedUser = null;
    },
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mb-2;
@include lib.ml-2;

.controls {
  display: flex;
  justify-content: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
}

</style>
