<template>
  <aab-modal
    id="disclosure-email-modal"
    modal-size="large"
    :is-open="openDisclosureEmailModal"
  >
    <div slot="modal-title">
      <base-typology
        tag="h3"
        variant="margin-0"
      >
        {{ sidRequestStore.getNomineeAccountName }}
      </base-typology>
    </div>
    <div slot="modal-content">
      <aab-spinner
        v-if="loading"
        size="8"
        hide-overlay
      />
      <aab-notification
        v-if="error"
        id="error"
        type="warning"
        type-aria-label="Warning notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
      <section v-if="!loading && !error">
        <div
          id="subject"
          class="header-email-frame"
        >
          <base-typology tag="label">
            {{ $t('utils.subject') }}:
          </base-typology>
          <base-typology>{{ emailSubject }}</base-typology>
        </div>
        <div
          id="email-list"
          class="header-email-frame"
        >
          <base-typology tag="label">
            {{ $t('utils.to') }}:
          </base-typology>
          <base-typology>{{ emailAddresses }}</base-typology>
        </div>
        <iframe
          class="iframe-modal"
          title="Preview of email content"
          name="Preview of email content"
          :srcdoc="email"
        >
        </iframe>
      </section>
    </div>
    <div slot="modal-footer">
      <div class="modal-footer">
        <base-button
          id="email-close-button"
          :text="$t('utils.cancel')"
          style-type="secondary"
          @click="$emit('close-email-modal')"
          @keyup.enter="$emit('close-email-modal')"
        />
        <base-button
          id="email-send-button"
          :disabled="!!error || loading"
          class="ml-1"
          :text="$t('utils.sendEmail')"
          style-type="primary"
          @click="sendEmail"
          @keyup.enter="sendEmail"
        />
      </div>
    </div>
  </aab-modal>
</template>

<script>
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { getPreviewEmail, sendDisclosureEmail } from '@/services/sidService';
import useSidRequestStore from '@/stores/SidRequestStore';

export default {
  name: 'DisclosureEmailModal',
  components: { BaseButton, BaseTypology },
  props: {
    openDisclosureEmailModal: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['close-email-modal', 'email-sent'],
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      email: null,
      emailSubject: null,
      emailAddresses: null,
      emailAddressesRaw: null,
    };
  },
  watch: {
    openDisclosureEmailModal: {
      async handler(newOpenDisclosureEmailModal) {
        if (newOpenDisclosureEmailModal) {
          this.loading = true;
          this.error = null;
          try {
            const data = await getPreviewEmail(
              this.$route.params.sidRequestUuid,
              this.$route.params.nomineeUuid,
              this.$route.params.accountServicerUuid,
            );
            this.email = data.body;
            this.emailSubject = data.title;
            this.emailAddressesRaw = data.to;
            this.emailAddresses = data.to.length > 0 ? data.to.join('; ') : null;
          } catch (e) {
            this.error = `${this.$t('shareholderId.errors.disclosureEmailPreviewError')} ${e.code}: ${this.$t(e.messageKey)}`;
          } finally {
            this.loading = false;
          }
        }
      },
    },
  },
  methods: {
    async sendEmail() {
      try {
        await sendDisclosureEmail(
          this.$route.params.sidRequestUuid,
          this.$route.params.nomineeUuid,
          this.$route.params.accountServicerUuid,
          this.emailSubject,
          this.email,
          this.emailAddressesRaw,
        );
        this.$emit('email-sent');
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.disclosureEmailError')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.header-email-frame {
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 14px;
  border: 1px solid lib.$n65;
  margin: -1px;
}

.iframe-modal {
  position: relative;
  width: 100%;
  height: 100vh;
  border: 1px solid lib.$n65;
}
</style>
