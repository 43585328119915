<template>
  <div>
    <base-typology tag="label">
      {{ $t('shareholderId.topTen') }}
    </base-typology>
    <section aria-label="Status">
      <!--loading-->
      <div v-if="loading">
        <aab-spinner
          size="5"
          hide-overlay
        />
      </div>
    </section>
    <section
      v-if="!loading"
      aria-label="Top ten shareholders"
    >
      <base-data-table
        :selectable-rows="true"
        :columns="columns"
        :rows="shareholders"
        :error="error"
        :empty-message="$t('shareholderId.noShareholders')"
        variant="horizontalOnly"
        caption="Top ten beneficial holders"
      />
      <div
        id="topTenStats"
        class="mt-2 text-center"
      >
        <base-typology tag="label">
          {{ $t('shareholderId.topTenShares') }}
        </base-typology><br>
        <base-typology>{{ formatNumber(topTenShares, 'decimal') }}</base-typology>
        <base-typology>{{ ` (${formatNumber(topTenPercentage, 'percent')})` }}</base-typology>
      </div>
    </section>
  </div>
</template>

<script>
import BaseTypology from '@/components/base/BaseTypology.vue';
import { defineComponent } from 'vue';
import { getSidRequestShareholders } from '@/services/sidService';
import useSidRequestStore from '@/stores/SidRequestStore';
import { formatNumber } from '@/utils/formatLib';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import { amountConverter } from '@/utils/modelConverters/baseDataTableConverters';

export default defineComponent({
  name: 'TopTenTable',
  components: { BaseDataTable, BaseTypology },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      pageSize: 10,
      pageNumber: 1,
      loading: false,
      error: null,
      shareholders: [],
      columns: [
        {
          key: 'rank',
          label: '',
          width: '10',
        },
        {
          key: 'name',
          label: 'Name',
          width: '70',
        },
        {
          key: 'securities',
          label: 'Securities',
          template: 'amount',
          width: '20',
        },
      ],
    };
  },
  computed: {
    topTenShares() {
      if (this.shareholders) {
        return this.shareholders
          .reduce((accumulator, shareholder) => accumulator + shareholder.securitiesValue, 0);
      }
      return 0;
    },
    topTenPercentage() {
      return this.topTenShares / this.sidRequestStore.getSidTotalOutstandingShares;
    },
  },
  async created() {
    await this.getTopTenShareholders();
  },
  methods: {
    formatNumber,
    async getTopTenShareholders() {
      this.loading = true;
      try {
        const filters = {
          shareholdingType: 'BENE',
        };
        const data = await getSidRequestShareholders(
          this.sidRequestStore.getSidUuid,
          this.pageNumber,
          this.pageSize,
          filters,
        );
        if (data.shareholders) {
          this.shareholders = Object.keys(data.shareholders)
            .map((key) => ({
              rank: data.shareholders[key].rank,
              name: data.shareholders[key].names,
              securities: amountConverter(data.shareholders[key].quantity, 'decimal'),
              securitiesValue: data.shareholders[key].quantity,
            }));
        }
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getSidRequestShareholders')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.mt_2;
@include lib.text_center;

</style>
