<template>
  <aab-modal
    id="aggregated-nominees-filter-modal"
    modal-size="medium"
    :is-open="openModal"
    @modal-close="$emit('close-filters')"
  >
    <div slot="modal-title">
      <base-typology
        tag="h3"
        variant="margin-0"
      >
        {{ $t('utils.filters') }}
      </base-typology>
    </div>
    <div slot="modal-content">
      <div class="column-container">
        <div class="content-item">
          <base-button
            id="reset-filters-button"
            style-type="action"
            :text="$t('utils.resetFilters')"
            icon="sy_arrow_arrow_right"
            @click="resetFilters"
          />
        </div>
        <!-- total amount filter-->
        <div class="content-item mt-2">
          <aab-input class="filterInput">
            <label
              slot="label"
              for="totalAmountFrom"
            >
              {{ $t('gen.total', { value: $t('gen.amount').toLowerCase() }) }}
            </label>
            <input
              id="total-amount-from"
              v-model="filters.totalAmountFrom"
              name="totalAmountFrom"
              type="text"
              :placeholder="$t('utils.from')"
              aria-label="The minimum value for total amount of shares"
              class="filterInput"
            >
          </aab-input>
          <aab-input class="filterInput">
            <input
              id="total-amount-to"
              v-model="filters.totalAmountTo"
              name="totalAmountTo"
              type="text"
              :placeholder="$t('utils.to')"
              aria-label="The maximum value for total amount of shares"
            >
          </aab-input>
        </div>
        <!-- response rate filter-->
        <div class="content-item mt-2">
          <aab-slider
            id="response-rate-filter"
            class="filterInput"
            :label-text="$t('shareholderId.responseRate')"
            slider-increment="1"
            minimum-amount="0"
            maximum-amount="100"
            range-slider
            slider-width="100"
            :amount-prefix="''"
            :min-label="`${$t('utils.min')}.`"
            :max-label="`${$t('utils.max')}.`"
            show-input
            :start-value="sliderLeftValue"
            :end-value="sliderRightValue"
            @aab-slider-value="updateResponseRate"
          />
        </div>
        <!-- match quality filter-->
        <div class="content-item">
          <div class="column-container">
            <base-typology
              class="mb-1"
              tag="label"
            >
              {{ $t('shareholderId.quality') }}
            </base-typology>
            <aab-checkbox
              v-for="(value, key) in qualities"
              :key="key"
            >
              <input
                :id="`quality-${key}`"
                slot="input-slot"
                type="checkbox"
                checked
                :value="value"
              >
              <label
                slot="label-slot"
                :for="`quality-${key}`"
              >
                {{ value.label }}
              </label>
            </aab-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <div id="modalFooter">
        <base-button
          id="cancel-button"
          style-type="secondary"
          :text="$t('utils.cancel')"
          class="mr-2"
          @click="$emit('close-filters')"
          @keyup.enter="$emit('close-filters')"
        />
        <base-button
          id="apply-button"
          style-type="primary"
          :text="$t('utils.apply')"
          @click="applyFilters"
          @keyup.enter="applyFilters"
        />
      </div>
    </div>
  </aab-modal>
</template>

<script>
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';

export default defineComponent({
  name: 'AggregatedFilters',
  components: { BaseButton, BaseTypology },
  props: {
    openModal: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['close-filters', 'apply-filters', 'reset-filters'],
  data() {
    return {
      sliderLeftValue: 0,
      sliderRightValue: 100,
      filters: {
        totalAmountFrom: null,
        totalAmountTo: null,
        responseRateFrom: null,
        responseRateTo: null,
        quality: null,
      },
    };
  },
  computed: {
    qualities() {
      return {
        low: {
          value: '0-33',
          label: this.$t('utils.reliability', { value: `0 - ${this.$n(0.33, 'percent')}` }),
        },
        medium: {
          value: '34-66',
          label: this.$t('utils.reliability', { value: `34 - ${this.$n(0.66, 'percent')}` }),
        },
        high: {
          value: '67-100',
          label: this.$t('utils.reliability', { value: `67 - ${this.$n(1, 'percent')}` }),
        },
        unmatched: {
          value: 'UNMATCHED',
          label: this.$t('utils.pending'),
        },
      };
    },
  },
  methods: {
    applyFilters() {
      this.filters.quality = [];
      Object.entries(this.qualities)
        .forEach(([key, value]) => {
          if (document.getElementById(`quality-${key}`).checked) {
            this.filters.quality.push(value.value);
          }
        });
      if (this.filters.quality === []) {
        this.filters.quality = null;
      }
      this.$emit('close-filters');
      this.$emit('apply-filters', { filters: this.filters });
    },
    resetFilters() {
      this.filters.totalAmountFrom = null;
      this.filters.totalAmountTo = null;
      this.filters.responseRateFrom = null;
      this.filters.responseRateTo = null;
      Object.keys(this.qualities)
        .forEach((key) => {
          document.getElementById(`quality-${key}`).checked = true;
        });
      this.filters.quality = null;
      this.$emit('reset-filters');
    },
    updateResponseRate(event) {
      this.filters.responseRateFrom = event.detail.startValue === 0
        ? null : event.detail.startValue;
      this.filters.responseRateTo = event.detail.endValue === 100
        ? null : event.detail.endValue;
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;
@include lib.mt_2;
@include lib.mb_1;

#filterContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.containerItem {
  display: flex;
  align-items: flex-end;
}

.column-container {
  display: flex;
  flex-direction: column;
}

.content-item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
}

.filterInput {
  width: 100%;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
}

#searchInput {
  width: 300px;
}
</style>
