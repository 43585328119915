<template>
  <layout-plain
    :title-long="$t('title.long')"
    :title-short="$t('title.short')"
    large-title-bar
  >
    <div class="row">
      <div class="mx-auto">
        <login-form v-if="action === 'login'" />
        <activate-account v-if="action === 'activate'" />
      </div>
    </div>
  </layout-plain>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutPlain from '@/layouts/LayoutPlain.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import ActivateAccount from '@/components/login/ActivateAccount.vue';

// This component is for views for users that are logged out
// and supply functionality to allow logging in. This includes:
// - Actually logging in
// - Activating the account by setting a password
// - Resetting a forgotten password upon login attempt
export default defineComponent({
  name: 'LoginView',
  components: {
    ActivateAccount,
    LoginForm,
    LayoutPlain,
  },
  props: {
    action: {
      type: String,
      validator(val) {
        return ['login', 'activate', 'forgotten'].includes(val);
      },
      required: true,
    },
  },
});
</script>
