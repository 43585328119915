<template>
  <div>
    <section
      :aria-label="$t('shareholderId.sidDetailsButtonsTile')"
    >
      <base-tile
        id="title-tile"
        class="p-3 mb-2"
      >
        <div class="header-tile title">
          <base-typology
            tag="h3"
            variant="margin-0"
          >
            {{ $t('gen.details') }}
          </base-typology>
          <div
            class="header-btn-container"
          >
            <base-button
              v-if="canEditRequest"
              id="edit-request-button"
              style-type="action"
              :text="$t('utils.actions.edit')"
              icon="sy_tools_pencil"
              class="edit-btn"
              @click="onEditButtonClick"
              @keyup.enter="onEditButtonClick"
            />
            <BaseTileDivider
              v-if="canEditRequest && getButton()"
              orientation="vertical"
            />
            <base-button
              v-if="getButton()"
              id="primary-action-button"
              icon="sy_form_valid"
              size="3"
              label="remove row from table"
              :text="getButton().buttonText"
              style-type="primary"
              @click="onClickButton(getButton())"
              @keyup.enter="onClickButton(getButton());"
            />
          </div>
        </div>
      </base-tile>
    </section>
    <div
      v-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
  </div>
  <!--  First tile-->
  <base-tile
    id="first-tile"
    class="p-3 mb-2"
  >
    <div class="main-body">
      <div class="section">
        <div
          id="sidID"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.sidID') }}
          </base-typology>
          <base-typology>
            {{ sidRequest.id }}
          </base-typology>
        </div>
        <div
          id="recordDate"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.recordDate') }}
          </base-typology>
          <base-typology>
            {{ sidRequest.recordDate }}
          </base-typology>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div
          id="status"
          style="margin-bottom: 1rem;"
        >
          <base-typology tag="label">
            {{ $t('utils.status') }}
          </base-typology><br>
          <aab-status
            id="sidStatus"
            :status-message="sidRequest.status.statusMessage"
            :style-type="sidRequest.status.styleType"
            has-background
          />
        </div>
        <div
          id="progress"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.responseRate') }}
          </base-typology>
          <div class="typology-container">
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div
          id="lastModified"
          class="standard-layout"
        >
          <base-typology tag="label">
            {{ $t('utils.lastModified') }}
          </base-typology>
          <base-typology>
            -
          </base-typology>
        </div>
      </div>
    </div>
  </base-tile>
  <!--  Second tile-->
  <base-tile
    id="second-tile"
    class="p-3 mb-2"
  >
    <div class="main-body">
      <div class="section">
        <div id="identification">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.identification') }}
          </base-typology>
          <div
            id="issuer"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('issuer.issuer') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.issuerName }}
            </base-typology>
          </div>
          <div
            id="isin"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.isin') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.isin }}
            </base-typology>
          </div>
          <div
            id="csd"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.csdShort') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.csd }}
            </base-typology>
          </div>
          <div
            id="detailsEmail"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('user.email') }}
            </base-typology>
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div id="shares">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.shares') }}
          </base-typology>
          <div
            id="totalOutstandingShares"
            class="shares-section"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.totalOutstandingShares') }}
            </base-typology>
            <div>
              <div
                v-if="sidRequest.status.value === generalStatus.inProgress"
                id="pencil-button"
                @click="onButtonOutstandingSharesClick"
                @keyup.enter="onButtonOutstandingSharesClick"
              >
                <base-icon
                  icon-name="sy_tools_pencil"
                  size="3"
                  class="icon"
                  :label="''"
                />
              </div>
              <base-typology>
                {{ sidRequest.totalOutstandingShares }}
              </base-typology>
            </div>
          </div>
          <BaseTileDivider orientation="horizontal"></BaseTileDivider>
          <div class="shares-group">
            <div
              id="threshold"
              class="shares-section"
            >
              <base-typology tag="label">
                {{ $t('shareholderId.threshold') }}
              </base-typology>
            </div>
            <div
              id="percentage"
              class="shares-section"
            >
              <base-typology>
                {{ $t('gen.percentage') }}
              </base-typology>
              <base-typology>
                {{ sidRequest.threshold }}
              </base-typology>
            </div>
            <div
              id="quantity"
              class="shares-section"
            >
              <base-typology>
                {{ $t('gen.quantity') }}
              </base-typology>
              <base-typology>
                {{ sidRequest.shareThreshold }}
              </base-typology>
            </div>
          </div>
          <div
            id="amountDisclosedByCsd"
            class="shares-section"
          >
            <base-typology
              tag="label"
              class="margin-bottom"
            >
              {{ $t('shareholderId.amountDisclosedByCsd') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.totalOutstandingSharesCsd }}
            </base-typology>
          </div>
          <div
            id="registeredShares"
            class="shares-section"
          >
            <base-typology
              tag="label"
              class="margin-bottom"
            >
              {{ $t('shareholderId.registeredShares') }}
            </base-typology>
            <base-typology>
              -
            </base-typology>
          </div>
        </div>
      </div>
      <BaseTileDivider orientation="vertical"></BaseTileDivider>
      <div class="section">
        <div id="timeline">
          <base-typology
            tag="h3"
          >
            {{ $t('gen.timeline') }}
          </base-typology>
          <div
            id="recordDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.recordDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.recordDate }}
            </base-typology>
          </div>
          <div
            id="creationDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.creationDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.createdDate }}
            </base-typology>
            <base-typology variant="small">
              {{ $t('gen.by') }} {{ sidRequest.createdBy }}
            </base-typology>
          </div>
          <div
            id="startDate"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.startDate') }}
              <aab-tooltip :tooltip-text="$t('shareholderId.startDateTooltip')">
                <base-icon
                  slot="tooltip-control"
                  icon-name="sy_others_info"
                  size="2"
                  class="icon"
                  :label="''"
                />
              </aab-tooltip>
            </base-typology>
            <base-typology>
              {{ sidRequest.startDate }}
            </base-typology>
          </div>
          <div
            id="disclosureDeadline"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.disclosureDeadlineDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.disclosureDeadlineDate }}
              <aab-status
                v-if="disclosureDeadlineDateWarning?.inFutureRange"
                :status-message="disclosureDeadlineDateWarning.text"
                class="warning-status"
                style-type="warning"
                has-icon="true"
              />
            </base-typology>
          </div>
          <div
            id="reportDeadline"
            class="standard-layout"
          >
            <base-typology tag="label">
              {{ $t('shareholderId.reportDeadlineDate') }}
            </base-typology>
            <base-typology>
              {{ sidRequest.reportDeadlineDate }}
              <aab-status
                v-if="reportDeadlineDateWarning?.inFutureRange"
                :status-message="reportDeadlineDateWarning.text"
                class="warning-status"
                style-type="warning"
                has-icon="true"
              />
            </base-typology>
          </div>
        </div>
      </div>
    </div>
  </base-tile>
  <aab-modal
    id="adjustOutstandingSharesModal"
    modal-size="small"
    :is-open="openModal"
    @modal-close="onButtonCancelModalClick()"
  >
    <div slot="modal-title">
      <div id="modalTitle">
        <h3> {{ $t('shareholderId.adjustTotalOutstandingShares') }}</h3>
      </div>
    </div>
    <div slot="modal-content">
      <aab-input
        :message="modalValidationMessage.text ? modalValidationMessage.text : ''"
        :message-type="modalValidationMessage.status"
      >
        <label
          slot="label"
          for="totalOutstandingSharesInput"
        >
          {{ $t('shareholderId.totalOutstandingShares') }}
        </label>
        <input
          id="totalOutstandingSharesInput"
          ref="totalOutstandingSharesInput"
          v-thousand-separator
          :value="sidRequest.totalOutstandingShares"
          name="totalOutstandingSharesInput"
        >
      </aab-input>
    </div>
    <div slot="modal-footer">
      <div id="modalFooter">
        <base-button
          id="cancelModalButton"
          :text="$t('utils.cancel')"
          style-type="secondary"
          class="mr-2"
          @click="onButtonCancelModalClick()"
        />
        <base-button
          id="applyModalButton"
          :text="$t('utils.confirm')"
          style-type="primary"
          @click="onButtonConfirmModalClick()"
        />
      </div>
      <div
        v-if="modalError"
        id="modal-error"
      >
        <aab-notification
          type="negative"
          type-aria-label="Error notification"
          close-icon
          :notification-title="$t('errors.somethingWentWrong')"
          @aab-notification-close-icon-clicked="modalError = null;"
        >
          <base-typology>{{ modalError }}</base-typology>
        </aab-notification>
      </div>
    </div>
  </aab-modal>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import navItems from '@/utils/reference/navItems';
import {
  formatDateCountdown,
  formatNumber,
} from '@/utils/formatLib';
import {
  setSidRequestSidTotalOutstandingShares,
  setSidRequestStatus,
} from '@/services/sidService';
import { generalStatus } from '@/utils/reference/globalConstants';
import BaseTileDivider from '@/components/base/BaseTileDivider.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import '@aab/sc-aab-modal';
import useToastStore from '@/stores/ToastStore';

export default defineComponent({
  name: 'RequestDetails',
  components: {
    BaseButton,
    BaseIcon,
    BaseTileDivider,
    BaseTypology,
    BaseTile,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    const toastStore = useToastStore();
    return { sidRequestStore, toastStore };
  },
  data() {
    return {
      modalValidationMessage: {
        status: false,
        text: '',
      },
      error: null,
      modalError: null,
      openModal: null,
      generalStatus,
    };
  },
  computed: {
    sidRequest() {
      return this.formatSidRequestValues(this.sidRequestStore.getSidRequest);
    },
    canEditRequest() {
      const allowedStatuses = [
        generalStatus.draft,
        generalStatus.agentApproval,
        generalStatus.issuerApproval,
      ];
      return this.sidRequest && allowedStatuses.includes(this.sidRequest.status.value);
    },
    disclosureDeadlineDateWarning() {
      return this.dateWarning(this.sidRequestStore.getSidRequest.disclosureDeadlineDate);
    },
    reportDeadlineDateWarning() {
      return this.dateWarning(this.sidRequestStore.getSidRequest.reportDeadlineDate);
    },
  },
  methods: {
    formatSidRequestValues(request) {
      const formattedRequest = { ...request };
      formattedRequest.csd = request.csd || '-';
      if (formattedRequest.thresholdUnit === 'percentage') {
        formattedRequest.threshold = request.threshold ? formatNumber((request.threshold / 100), 'percent') : '-';
        formattedRequest.shareThreshold = request.shareThreshold ? formatNumber(request.shareThreshold, 'decimal') : '-';
      } else {
        formattedRequest.threshold = '-';
        formattedRequest.shareThreshold = request.shareThreshold ? formatNumber(request.shareThreshold, 'decimal') : '-';
      }
      formattedRequest.totalOutstandingShares = request.totalOutstandingShares ? formatNumber(request.totalOutstandingShares, 'decimal') : '-';
      formattedRequest.totalOutstandingSharesCsd = request.totalOutstandingSharesCsd ? formatNumber(request.totalOutstandingSharesCsd, 'decimal') : '-';
      formattedRequest.recordDate = request.recordDate ? this.$d(request.recordDate, 'short') : '-';
      formattedRequest.startDate = request.startDate ? this.$d(request.startDate, 'short') : '-';
      formattedRequest.createdDate = this.$d(request.createdDate, 'short');
      formattedRequest.disclosureDeadlineDate = request.disclosureDeadlineDate ? this.$d(request.disclosureDeadlineDate, 'short') : '-';
      formattedRequest.reportDeadlineDate = request.reportDeadlineDate ? this.$d(request.reportDeadlineDate, 'short') : '-';
      return formattedRequest;
    },
    getButton() {
      const buttonInfo = 'utils.actions';
      if (this.sidRequest.status.value === generalStatus.agentApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.approve')),
          onClickValue: generalStatus.approved,
          toastMessage: this.$t('shareholderId.success.requestApproved'),
        };
      }
      if (this.sidRequest.status.value === generalStatus.issuerApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.approveOnBehalfIssuer')),
          onClickValue: generalStatus.approved,
          toastMessage: this.$t('shareholderId.success.requestApproved'),
        };
      }
      if (this.sidRequest.status.value === generalStatus.inProgress) {
        return {
          buttonText: this.$t(buttonInfo.concat('.complete')),
          onClickValue: generalStatus.completed,
          toastMessage: this.$t('shareholderId.success.requestCompleted'),
        };
      }
      return null;
    },
    dateWarning(date) {
      if (date !== '-') {
        return formatDateCountdown(
          date,
          2,
        );
      }
      return null;
    },
    async refreshPage(newSidRequestData) {
      await this.sidRequestStore.setSidRequestDetails(newSidRequestData);
    },
    onEditButtonClick() {
      this.$router.push({
        name: navItems.sidHome.create.routeName,
        params: { sidRequestUuid: this.sidRequest.uuid },
      });
    },
    async onClickButton(buttonOptions) {
      try {
        const requestResponse = await setSidRequestStatus(
          this.sidRequestStore.getSidUuid,
          buttonOptions.onClickValue,
          '',
        );
        this.toastStore.setToastMessage(buttonOptions.toastMessage);
        await this.refreshPage(requestResponse);
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.disclosureApprovalError')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    onButtonOutstandingSharesClick() {
      this.openModal = true;
    },
    async onButtonConfirmModalClick() {
      try {
        const updatedShares = this.$refs.totalOutstandingSharesInput.value.replace(/,/g, '');
        if (updatedShares === '0') {
          this.modalValidationMessage = {
            status: 'negative',
            text: this.$t('errors.totalOutstandingSharesZero'),
          };
          return;
        }
        const requestResponse = await setSidRequestSidTotalOutstandingShares(
          this.sidRequestStore.getSidUuid,
          updatedShares,
        );
        this.openModal = null;
        await this.refreshPage(requestResponse);
        this.toastStore.setToastMessage(this.$t('shareholderId.success.totalOutstandingSharesUpdated'));
      } catch (e) {
        this.modalError = `${this.$t('errors.modalUnsuspectedError')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    onButtonCancelModalClick() {
      // reset input validation error message.
      if (this.modalValidationMessage.status === 'negative') {
        this.modalValidationMessage = {
          status: 'positive',
          text: '',
        };
      }
      this.openModal = null;
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.p_3;

.header-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

.header-btn-container {
  display: flex;
  gap: 1rem;
}

.section {
  width:33%;
}

.section h3 {
  margin-bottom: 16px;
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  position: relative;
}

  .shares-section {
    display: flex;
    justify-content: space-between;
  }

.icon {
  vertical-align: middle;
  line-height: 1;
}

.shares-group {
  margin-bottom: 1rem;
}

.shares-group p {
  margin-bottom: 0;
}

.typology-container {
  margin-top:  0;
}

.standard-layout {
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  flex-direction: column;
  margin-bottom: 1rem;
}

.warning-status {
  padding: 2px 8px;
}

.margin-bottom {
  margin-bottom: 1rem;
}

#pencil-button {
  display: inline;
  cursor: pointer;
  margin-right: 8px;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

#modalTitle {
  padding-right: 2rem;
}

#modal-error {
  margin-top: 1rem;
}

input {
  text-align: right;
}

</style>
