<template>
  <layout-full-width
    :title-long="$t('shareholderId.listing')"
    :title-short="$t('shareholderId.label')"
    large-title-bar
  >
    <request-listing />
  </layout-full-width>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutFullWidth from '@/layouts/LayoutFullWidth.vue';
import RequestListing from '@/components/shareholderId/sidRequest/RequestListing.vue';

/**
 * SidListView is the route component for the list of SID Requests
 */
export default defineComponent({
  name: 'SidListView',
  components: { RequestListing, LayoutFullWidth },
});
</script>
