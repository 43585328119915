const numberFormats = {
  'en-GB': {
    decimal: {
      style: 'decimal',
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
      useGrouping: true,
    },
  },
};

export default numberFormats;
