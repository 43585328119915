<template>
  <section
    class="form-section"
    :aria-label="$t('utils.formSection', { value: $t('gen.identification') })"
  >
    <base-typology tag="h3">
      {{ $t('gen.identification') }}
    </base-typology>
    <div class="input-field">
      <base-input-dropdown
        id="select-issuer"
        ref="issuerDropdown"
        id-tag="issuer"
        :label="$t('issuer.issuer')"
        :options="issuers"
        :variant="issuerValidationMessage ? 'negative' : ''"
        :status-message="issuerValidationMessage"
        @on-change="selectedIssuerName = $event"
        @on-select="selectedIssuerName = $event"
      />
    </div>
    <div class="input-field">
      <base-input-dropdown
        id="select-isin"
        ref="isin"
        id-tag="isin"
        :label="$t('shareholderId.isin')"
        :options="isins"
        :variant="isinValidationMessage ? 'negative' : ''"
        :status-message="isinValidationMessage"
        @on-change="setIsin($event)"
        @on-blur="v$.form.isin.$touch()"
        @on-select="setIsin($event)"
      />
    </div>
    <div class="input-field">
      <aab-select
        id="select-csd"
        :aab-select-label="$t('shareholderId.csd')"
        :aab-select-value="form.csdUuid"
        :aab-select-items="JSON.stringify(csds)"
        :aab-select-status-message="csdValidationMessage"
        :variant="csdValidationMessage ? 'error' : ''"
        @aab-select-value-change="setCsd"
      ></aab-select>
    </div>
    <div
      v-if="error"
      id="error-notification"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
  </section>
</template>

<script>
import { defineComponent, nextTick, ref } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseInputDropdown from '@/components/base/BaseInputDropdown.vue';
import { getIssuers, getPreviousIsins } from '@/services/issuerService';
import getCsds from '@/services/csdService';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { shouldMatchIsinPattern, requiredSelect } from '@/utils/customValidation';
import createValidationMessage from '@/utils/validationUtils';

/**
 * FormIdentification is the identification section of the SID Create form.
 * To function it requires a list of issuers, ISINs and CSDs. In the event an issuer is selected
 * first, then the ISIN list is filtered to only show the list of ISINs that have been used
 * in past SID requests for that issuer.
 * Validation rules apply for all three values and are specified at the bottom of the component.
 */
export default defineComponent({
  name: 'FormIdentification',
  components: {
    BaseInputDropdown,
    BaseTypology,
  },
  props: {
    formData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const issuerDropdown = ref(null);
    const isin = ref(null);
    return {
      issuerDropdown,
      isin,
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      error: null,
      issuers: [],
      isins: [],
      csds: [],
      selectedIssuerName: null,
      selectedIsin: null,
      form: {
        issuerUuid: null,
        isin: null,
        csdUuid: null,
      },
    };
  },
  computed: {
    issuerValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'issuerUuid');
    },
    isinValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'isin');
    },
    csdValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'csdUuid');
    },
  },
  watch: {
    formData: {
      handler() {
        this.initializeForm();
      },
    },
    async selectedIssuerName(newValue) {
      const matchingIssuer = this.issuers.filter(
        (i) => i.name.toLowerCase() === newValue.toLowerCase(),
      );
      this.form.issuerUuid = matchingIssuer[0] ? matchingIssuer[0].uuid : null;
      if (this.form.issuerUuid) {
        this.v$.form.issuerUuid.$touch();
        await this.getPreviousIsins(this.form.issuerUuid);
      } else {
        await this.getPreviousIsins(null);
      }
    },
  },
  async created() {
    await this.getIssuers();
    await this.getPreviousIsins(null);
    await this.getCsds();
  },
  methods: {
    async initializeForm() {
      if (this.formData) {
        // fills in the dropdown, triggers the watcher and sets the issuerUuid
        if (this.$refs.issuerDropdown) {
          this.$refs.issuerDropdown.setSelectedOption(this.formData.issuerName);
        }
        // fills in the dropdown and sets the isin
        if (this.$refs.isin) {
          this.$refs.isin.setSelectedOption(this.formData.isin);
        }
        this.form.csdUuid = this.formData.csdUuid;
        // ensure form is clean after setting values
        await nextTick();
        this.v$.$reset();
      }
    },
    setIsin(selectedIsin) {
      this.form.isin = selectedIsin;
    },
    setCsd(event) {
      this.form.csdUuid = event.detail.value;
      this.v$.form.csdUuid.$touch();
    },
    async getIssuers() {
      this.issuers = [];
      try {
        const data = await getIssuers(
          null,
          null,
          true,
          'GM',
          null,
        );
        this.issuers = Object.keys(data.issuers)
          .map((key) => ({
            uuid: data.issuers[key].uuid,
            name: data.issuers[key].name,
          }));
        if (this.formData?.issuerUuid) {
          this.form.issuerUuid = this.formData.issuerUuid;
        }
      } catch (e) {
        this.error = `${this.$t('issuer.errors.getIssuers')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    async getPreviousIsins(issuerUuid) {
      this.isins = [];
      try {
        const data = await getPreviousIsins(null, null, issuerUuid);
        this.isins = data.map((value) => ({
          name: value,
        }));
      } catch (e) {
        this.error = `${this.$t('issuer.errors.getIsins')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    async getCsds() {
      this.csds = [];
      try {
        const data = await getCsds(null, null);
        this.csds = data.csds.map((csd) => ({
          name: csd.name,
          value: csd.uuid,
        }));
      } catch (e) {
        this.error = `${this.$t('csd.errors.getCsds')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
  },
  validations() {
    return {
      form: {
        issuerUuid: { requiredSelect: helpers.withParams({ field: this.$t('issuer.issuer').toLowerCase() }, requiredSelect) },
        isin: {
          shouldMatchIsinPattern,
          requiredSelect: helpers.withParams({ field: this.$t('shareholderId.isin') }, requiredSelect),
        },
        csdUuid: { requiredSelect: helpers.withParams({ field: this.$t('shareholderId.csd').toLowerCase() }, requiredSelect) },
      },
    };
  },
});
</script>
