export default function createCsdData(data) {
  const csds = Object.keys(data.list)
    .map((key) => ({
      uuid: data.list[key].csdUuid,
      name: data.list[key].name,
    }));
  return {
    csds,
    totalItems: data.totalElements,
  };
}
