<template>
  <div>
    <!--loading-->
    <div
      v-if="loading"
      class="my-1"
    >
      <aab-spinner
        size="5"
        hide-overlay
      />
    </div>
    <!--error-->
    <div
      v-else-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
    <base-data-table
      v-else
      caption="Nominee accounts"
      :rows="accounts"
      :columns="columns"
      :sort-on="sortOn"
      :sort-direction="sortDirection"
      variant="grid"
      selectable-rows
      @row-selected="openMatchingDetails($event)"
      @request-update="sortData($event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { sortObjects } from '@/utils/arrayTransformation';
import { formatNumber } from '@/utils/formatLib';
import { getAccountServicerLevelNominees } from '@/services/sidService';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import {
  amountOfAmountConverter, nomineeNameConverter,
} from '@/utils/modelConverters/baseDataTableConverters';
import BaseTypology from '@/components/base/BaseTypology.vue';
import navItems from '@/utils/reference/navItems';

export default defineComponent({
  name: 'NomineeAccounts',
  components: { BaseTypology, BaseDataTable },
  props: {
    nomineeUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      accounts: [],
      sortOn: 'amount',
      sortDirection: 'DESC',
      activeAccountServicerUuid: null,
      openModal: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          key: 'nomineeName',
          label: this.$tc('shareholderId.nominee', 1),
          sortable: true,
          width: '25',
          template: 'title',
        },
        {
          key: 'lastResponse',
          label: this.$t('shareholderId.lastResponse'),
          sortable: false,
          width: '20',
          template: 'title',
        },
        {
          key: 'lastEmailSent',
          label: this.$t('utils.lastEmailSent'),
          sortable: false,
          width: '20',
          template: 'title',
        },
        {
          key: 'amount',
          label: this.$t('gen.amount'),
          sortable: true,
          width: '20',
          template: 'amountOfAmount',
        },
        {
          key: 'matchingQuality',
          label: this.$t('shareholderId.quality'),
          sortable: true,
          width: '15',
          template: 'status',
        },
      ];
    },
  },
  async created() {
    await this.getNomineeAccounts();
  },
  methods: {
    formatNumber,
    async getNomineeAccounts() {
      this.loading = true;
      this.error = null;
      try {
        const data = await getAccountServicerLevelNominees(this.nomineeUuid);
        this.accounts = Object.keys(data.nomineeAccounts)
          .map((key) => ({
            // use accountServicerUuid as rowId to enable event processing
            id: data.nomineeAccounts[key].accountServicerUuid,
            nomineeName: nomineeNameConverter(data.nomineeAccounts[key]),
            lastResponse: {
              value: data.nomineeAccounts[key].matchedResponseIdentifier,
              subtitle: data.nomineeAccounts[key].matchedResponseReceivedDateTime,
            },
            lastEmailSent: {
              value: data.nomineeAccounts[key].lastEmailSentBy,
              subtitle: data.nomineeAccounts[key].lastEmailSentOn,
            },
            nomineeAccountCountry: data.nomineeAccounts[key].nomineeCountry,
            responseId: {
              value: data.nomineeAccounts[key].responseId,
            },
            amount: amountOfAmountConverter(
              data.nomineeAccounts[key].matchedShareholdingBalance,
              data.nomineeAccounts[key].shareholdingBalance,
            ),
            matchingQuality: data.nomineeAccounts[key].matchingCategory,
          }));
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getAccountLevelNominees')} ${this.$t(e.messageKey, { email: 'cbpsupport@nl.abnamro.com' })}`;
      } finally {
        this.loading = false;
      }
    },
    sortData(event) {
      this.accounts = sortObjects(this.accounts, event.sortOn, event.sortDir);
    },
    openMatchingDetails(event) {
      this.$router.push({
        name: navItems.sidHome.sidRequest.matchingDetails.routeName,
        params: {
          nomineeUuid: this.nomineeUuid,
          accountServicerUuid: event.rowId,
        },
      });
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.my_1;
@include lib.m_3;

</style>
