<template>
  <div>
    <div class="controls mb-2">
      <base-button
        id="back-button"
        class="mr-2"
        :text="$t('utils.toUserList')"
        :style-type="'secondary'"
        icon="sy_arrow_chevron_left"
        full-width
        @click="handleBack()"
      />
    </div>
    <div
      v-if="error"
      class="mb-2"
    >
      <aab-notification
        type="warning"
        type-aria-label="Warning notification"
      >
        <p>{{ error }}</p>
      </aab-notification>
    </div>
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <form
      v-else
      class="form"
      @submit.prevent="handleSubmit"
    >
      <div class="mt-2 form-name-container">
        <div class="form-name">
          <aab-input
            id="first-name"
            :message="firstNameValidationMessage"
            :message-type="firstNameValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="firstName"
            >
              {{ $t('user.firstName') }}
            </label>
            <input
              v-model="user.firstName"
              name="firstName"
              type="text"
              placeholder="Jane"
            >
          </aab-input>
        </div>
        <div class="form-name">
          <aab-input
            id="last-name"
            :message="lastNameValidationMessage"
            :message-type="lastNameValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="lastName"
            >
              {{ $t('user.lastName') }}
            </label>
            <input
              v-model="user.lastName"
              name="lastName"
              type="text"
              placeholder="Doe"
            >
          </aab-input>
        </div>
      </div>
      <div class="mt-2">
        <aab-input
          id="username"
          :message="usernameValidationMessage"
          :message-type="usernameValidationMessage ? 'warning' : ''"
        >
          <label
            slot="label"
            for="username"
          >
            {{ $t('user.username') }}
          </label>
          <input
            v-model="user.username"
            name="username"
            type="text"
            placeholder="X00000"
          >
        </aab-input>
      </div>
      <div class="mt-2">
        <aab-input
          id="email"
          :message="emailValidationMessage"
          :message-type="emailValidationMessage ? 'warning' : ''"
        >
          <label
            slot="label"
            for="email"
          >
            {{ $t('user.email') }}
          </label>
          <input
            v-model="user.email"
            name="email"
            type="email"
            placeholder="jane.doe@nl.abnamro.com"
          >
        </aab-input>
      </div>
      <div class="mt-2">
        <aab-select
          :options="roles"
          :aab-select-label="$t('user.role')"
          :aab-select-value="user.role"
          @aab-select-value-change="handleChangeRole($event)"
        >
        </aab-select>
      </div>
      <div class="mt-2 controls">
        <base-button
          id="submit-button"
          class="mr-2"
          :text="$t('userManagement.addUser')"
          :style-type="'primary'"
          icon="sy_shapes_plus_large"
          full-width
          :disabled="loading"
          @keyup.enter="handleSubmit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import { roles } from '@/utils/reference/globalConstants';
import { addUser } from '@/services/userService';
import { sentenceCase } from 'change-case';
import BaseButton from '@/components/base/BaseButton.vue';
import createValidationMessage from '@/utils/validationUtils';

export default defineComponent({
  name: 'UserForm',
  components: { BaseButton },
  emits: ['change-title'],
  data() {
    return {
      v$: useVuelidate(),
      user: {
        firstName: null,
        lastName: null,
        username: null,
        email: null,
        role: 'GENERAL_AGENT',
      },
      error: null,
      loading: false,
    };
  },
  computed: {
    firstNameValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.user, 'firstName');
    },
    lastNameValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.user, 'lastName');
    },
    usernameValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.user, 'username');
    },
    emailValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.user, 'email');
    },
    roles() {
      return Object.values(roles).map((role) => ({
        value: role,
        name: sentenceCase(role),
      }));
    },
  },
  async created() {
    this.$emit('change-title', this.$t('userManagement.addUser'), null);
  },
  methods: {
    handleBack() {
      this.$router.push('/users/overview');
    },
    async handleSubmit() {
      this.loading = true;
      this.error = null;
      if (!await this.v$.$validate()) {
        this.loading = false;
        return;
      }
      try {
        await addUser(this.user);
        this.$router.push('/users/overview');
      } catch (e) {
        this.error = `${this.$t('userManagement.errors.addUser')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    handleChangeRole(event) {
      this.user.role = event.detail.value;
    },
  },
  // Role does not need to be validated as the UI does not allow the user to deselect a value
  validations() {
    return {
      user: {
        firstName: { required: helpers.withParams({ field: this.$t('user.firstName') }, required) },
        lastName: { required: helpers.withParams({ field: this.$t('user.lastName') }, required) },
        username: { required: helpers.withParams({ field: this.$t('user.username') }, required) },
        email: { required: helpers.withParams({ field: this.$t('user.email') }, required) },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mr-2;
@include lib.mt-2;
@include lib.row;
@include lib.d-flex;
@include lib.justify_content_between;
@include lib.col_6;
@include lib.col_sm-12;

.controls {
  display: flex;
  justify-content: flex-start;
}

.form-name-container {
  display: flex;
  justify-content: space-between;
  gap: 16px
}

.form-name {
  flex-grow: 1;
}

.form {
  width: 500px;
}
</style>
