<template>
  <div>
    <base-typology tag="label">
      {{ $t('shareholderId.disclosureProgress') }}
    </base-typology>
    <section
      id="disclosureProgress"
      aria-label="Disclosure progress"
    >
      <!--loading-->
      <div v-if="loading">
        <aab-spinner
          size="5"
          hide-overlay
        />
      </div>
      <div v-if="!loading && !error">
        <div id="chartArea">
          <base-donut-chart
            :data="donutData"
            chart-id="'disclosure-progress-chart'"
          ></base-donut-chart>
        </div>
      </div>
      <div v-if="!loading">
        <div id="disclosure-details-table">
          <base-data-table
            :selectable-rows="true"
            :columns="columns"
            :rows="disclosureData"
            :error="error"
            :empty-message="$t('shareholderId.noShareholders')"
            variant="horizontalOnly"
            caption="Disclosure progress"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import { getDisclosureProgressData } from '@/services/sidService';
import BaseDonutChart from '@/components/base/BaseDonutChart.vue';
import {
  g200, g400, g55, n55,
} from '@aab/sc-styles-colors';
import { lowerCaseFirstChar } from '@/utils/formatLib';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import { amountConverter } from '@/utils/modelConverters/baseDataTableConverters';

export default defineComponent({
  name: 'DisclosureProgress',
  components: {
    BaseDataTable, BaseDonutChart, BaseTypology,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      progress: [],
      donutData: [],
      disclosureData: [],
      columns: [
        {
          key: 'category',
          label: this.$t('shareholderId.holdingType'),
          width: '60',
        },
        {
          key: 'securities',
          label: this.$t('gen.securities'),
          template: 'amount',
          width: '25',
        },
        {
          key: 'percentage',
          label: '%',
          template: 'amount',
          width: '15',
        },
      ],
    };
  },
  async created() {
    await this.getProgressData();
  },
  methods: {
    async getProgressData() {
      this.loading = true;
      try {
        this.progress = await getDisclosureProgressData(this.sidRequestStore.getSidUuid);
        if (this.progress) {
          const total = this.progress.totalOutstandingShares;
          this.disclosureData = [
            {
              category: this.$t('shareholderId.beneficiary'),
              securities: amountConverter(this.progress.beneAmountOfSharesDisclosed, 'decimal'),
              percentage: amountConverter(this.progress.beneAmountOfSharesDisclosed / total, 'percent'),
            },
            {
              category: this.$t('shareholderId.OnOwnAccount'),
              securities: amountConverter(this.progress.ooacAmountOfSharesDisclosed, 'decimal'),
              percentage: amountConverter(this.progress.ooacAmountOfSharesDisclosed / total, 'percent'),
            },
            {
              category: this.$t('utils.unknown'),
              securities: amountConverter(this.progress.unknownAmountOfSharesDisclosed, 'decimal'),
              percentage: amountConverter(this.progress.unknownAmountOfSharesDisclosed / total, 'percent'),
            },
            {
              category: this.$t('gen.total', { value: this.$t('shareholderId.response').toLowerCase() }),
              securities: amountConverter(this.progress.totalAmountOfSharesDisclosed, 'decimal'),
              percentage: amountConverter(this.progress.totalAmountOfSharesDisclosed / total, 'percent'),
            },
            {
              category: this.$t('gen.total', { value: lowerCaseFirstChar(this.$t('shareholderId.outstandingSharesCsd')) }),
              securities: amountConverter(total, 'decimal'),
              percentage: amountConverter(1, 'percent'),
            },
          ];
          this.donutData = [
            {
              label: this.$t('shareholderId.beneficiary'),
              value: this.progress.beneAmountOfSharesDisclosed,
              color: g400,
            },
            {
              label: this.$t('shareholderId.OnOwnAccount'),
              value: this.progress.ooacAmountOfSharesDisclosed,
              color: g200,
            },
            {
              label: this.$t('utils.unknown'),
              value: this.progress.unknownAmountOfSharesDisclosed,
              color: g55,
            },
            {
              label: this.$t('utils.undisclosed'),
              value: total - this.progress.totalAmountOfSharesDisclosed,
              color: n55,
            },
          ];
        }
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getDisclosureProgress')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
#chartArea {
  width: 75%;
  margin: auto;
}

</style>
