/**
 * Create the string of query parameters - default query parameters, does not include filters
 * @name constructQueryParams
 * @param paramList   list of query parameters, i.e. { key1: value1, key2: value2, etc... }
 * @param filters     optional list of filters, i.e. { key1: value1, key2: value2, etc... }
 * @returns {String}  query parameters
 */
export default function constructQueryParams(paramList, filters) {
  let paramString = '';
  let filterString = '';
  let i = 0;
  if (paramList) {
    Object.entries(paramList)
      .forEach(([key, value]) => {
        if (i === 0 && value) {
          paramString = `?${key}=${encodeURIComponent(value.toString())}`;
          i += 1;
        } else if (value) {
          paramString = `${paramString}&${key}=${encodeURIComponent(value.toString())}`;
          i += 1;
        }
      });
  }
  if (filters) {
    Object.entries(filters)
      .forEach(([key, value]) => {
        if (i === 0 && value) {
          filterString = `?${key}=${encodeURIComponent(value.toString())}`;
          i += 1;
        } else if (value) {
          filterString = `${filterString}&${key}=${encodeURIComponent(value.toString())}`;
          i += 1;
        }
      });
  }
  return `${paramString}${filterString}`;
}
