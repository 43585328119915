<template>
  <aab-modal
    id="matchingFilterModal"
    modal-size="medium"
    :is-open="openModal"
    @modal-close="$emit('close-filters')"
  >
    <div slot="modal-title">
      <base-typology
        tag="h3"
        variant="margin-0"
      >
        {{ $t('utils.filters') }}
      </base-typology>
    </div>
    <div slot="modal-content">
      <div class="column-container">
        <div class="content-item">
          <base-button
            id="reset-filters-button"
            style-type="action"
            :text="$t('utils.resetFilters')"
            icon="sy_arrow_arrow_right"
            @click="resetFilters"
            @keyup.enter="resetFilters"
          />
        </div>
        <!-- total amount filter-->
        <div class="content-item">
          <aab-input class="filterInput">
            <label
              slot="label"
              for="amountFrom"
            >
              {{ $t('gen.amount') }}
            </label>
            <input
              id="amount-from"
              v-model="filters.amountFrom"
              name="amountFrom"
              type="text"
              :placeholder="$t('utils.from')"
              :aria-label="$t('aria.minimumValueField', { key: 'gen.securities' })"
              class="filterInput"
            >
          </aab-input>
          <aab-input class="filterInput">
            <input
              id="amount-to"
              v-model="filters.amountTo"
              name="amountTo"
              type="text"
              :placeholder="$t('utils.to')"
              :aria-label="$t('aria.maximumValueField', { key: 'gen.securities' })"
            >
          </aab-input>
        </div>
        <!-- matching category filter-->
        <div class="content-item matching-categories-title">
          <base-typology
            tag="label"
          >
            {{ $t('utils.status') }}
          </base-typology>
        </div>
        <div class="content-item matching-categories">
          <aab-checkbox>
            <input
              id="unmatched"
              slot="input-slot"
              v-model="filters.unmatched"
              type="checkbox"
              :aria-label="$t('shareholderId.matchedAmountCategories.unmatched')"
            >
          </aab-checkbox>
          <label
            for="unmatched"
          >
            {{ $t('shareholderId.matchedAmountCategories.unmatched') }}
          </label>
        </div>
        <div class="content-item matching-categories">
          <aab-checkbox>
            <input
              id="partialMatched"
              slot="input-slot"
              v-model="filters.partialMatched"
              type="checkbox"
              :aria-label="$t('shareholderId.matchedAmountCategories.partialMatch')"
            >
          </aab-checkbox>
          <label
            for="partialMatched"
          >
            {{ $t('shareholderId.matchedAmountCategories.partialMatch') }}
          </label>
        </div>
        <div class="content-item matching-categories">
          <aab-checkbox>
            <input
              id="matched"
              slot="input-slot"
              v-model="filters.matched"
              type="checkbox"
              :aria-label="$t('shareholderId.matchedAmountCategories.matched')"
            >
          </aab-checkbox>
          <label
            for="matched"
          >
            {{ $t('shareholderId.matchedAmountCategories.matched') }}
          </label>
        </div>
      </div>
    </div>
    <div slot="modal-footer">
      <div id="modalFooter">
        <base-button
          id="cancel-button"
          style-type="secondary"
          :text="$t('utils.cancel')"
          class="mr-2"
          @click="$emit('close-filters')"
          @keyup.enter="$emit('close-filters')"
        />
        <base-button
          id="apply-button"
          style-type="primary"
          :text="$t('utils.apply')"
          @click="applyFilters"
          @keyup.enter="applyFilters"
        />
      </div>
    </div>
  </aab-modal>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'MatchingFilters',
  components: { BaseButton, BaseTypology },
  props: {
    openModal: {
      type: Boolean,
      default: null,
    },
    amountFrom: {
      type: String,
      default: null,
    },
    amountTo: {
      type: String,
      default: null,
    },
    matched: {
      type: Boolean,
      default: false,
    },
    partialMatched: {
      type: Boolean,
      default: false,
    },
    unmatched: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close-filters', 'apply-filters', 'reset-filters'],
  data() {
    return {
      filters: {
        amountFrom: null,
        amountTo: null,
        matched: false,
        partialMatched: true,
        unmatched: true,
      },
    };
  },
  methods: {
    applyFilters() {
      this.$emit('apply-filters', {
        amountFrom: this.filters.amountFrom,
        amountTo: this.filters.amountTo,
        matched: this.filters.matched,
        partialMatched: this.filters.partialMatched,
        unmatched: this.filters.unmatched,
      });
      this.$emit('close-filters');
    },
    resetFilters() {
      this.filters.amountFrom = null;
      this.filters.amountTo = null;
      this.filters.matched = false;
      this.filters.partialMatched = true;
      this.filters.unmatched = true;
      this.$emit('reset-filters');
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;

.column-container {
  display: flex;
  flex-direction: column;
}

.content-item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  :deep(button) {
    padding-left: 0;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  }

  &.matching-categories {
    width: fit-content;
    margin-top: 0.5rem;

    input[type='checkbox'] {
      margin-top: 5px;
      margin-bottom: -5px;
      margin-right: 0;
    }
  }

  &.matching-categories-title {
    width: fit-content;
    margin-top: 1rem;
  }
}

.filterInput {
  width: 100%;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
}
</style>
