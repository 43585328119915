import constructQueryParams from '@/utils/serviceHelpers/genericHelpers';
import axios from 'axios';
import createError from '@/utils/errors';
import createIssuerData from '@/utils/serviceHelpers/issuerHelpers';

/**
 * Get the issuer data.
 * If pageNumber and pageSize are null, then all issuers.
 * If productCode is null then by default both GM and DWT product codes
 * SortBy is by default on name
 * @name getIssuers
 * @param pageNumber       the pageNumber
 * @param pageSize         the pageSize
 * @param active           the status of the issuers
 * @param productCode      the issuer associated product code (GM / DWT)
 * @param sortBy           the sort field
 * @returns {Promise}      Aggregated nominee data, formatted
 */
export async function getIssuers(pageNumber, pageSize, active, productCode, sortBy) {
  const queryString = constructQueryParams({
    pageNumber, pageSize, active, productCode, sortBy,
  }, null);
  return axios.get(`v1/issuers${queryString}`)
    .then((resp) => createIssuerData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the list of ISINs for which there have been SID requests for in the past.
 * If issuerUuid is passed, get ISINs for that specific issuer
 * @name getPreviousIsins
 * @param pageNumber         the pageNumber
 * @param pageSize           the pageSize
 * @param issuerUuid         the issuerUuid(s) to filter by
 * @returns {Promise}        list of Isins
 */
export async function getPreviousIsins(pageNumber, pageSize, issuerUuid) {
  const queryString = constructQueryParams({
    pageNumber, pageSize, issuerUuid,
  }, null);
  return axios.get(`v1/sid/isins${queryString}`)
    .then((resp) => resp.data)
    .catch((error) => createError(error));
}
