<template>
  <div class="chart-wrapper">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Chart from 'chart.js/auto';

export default defineComponent({
  name: 'BaseDonutChart',
  props: {
    chartId: {
      required: true,
      type: String,
    },
    data: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    /* eslint-disable no-new */
    new Chart(
      document.getElementById(this.chartId),
      {
        type: 'doughnut',
        data: {
          labels: Object.keys(this.data).map((key) => this.data[key].label),
          datasets: [{
            label: 'My First Dataset',
            data: Object.keys(this.data).map((key) => this.data[key].value),
            backgroundColor: Object.keys(this.data).map((key) => this.data[key].color),
            hoverOffset: 4,
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
            },
          },
          locale: this.$i18n.locale,
        },
      },
    );
  },
});
</script>

<style scoped lang="scss">
.chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%
}
</style>
