<template>
  <layout-form
    :title-short="$t('shareholderId.newRequest')"
    :title-long="$t('shareholderId.newRequestLong')"
    :back-button-path="navItems.sidHome.list.route"
    :back-button-title="$t('utils.closeForm')"
  >
    <div>
      <request-create
        ref="form"
        :sid-request-uuid="sidRequestUuid"
        @confirm-leave="confirmLeave = true"
      />
      <aab-modal
        modal-size="small"
        :is-open="confirmLeaveModal"
        @model-close="cancelLeave()"
      >
        <div slot="modal-title">
          <base-typology
            tag="h3"
            variant="margin-0"
          >
            {{ $t('utils.unsavedChanges') }}
          </base-typology>
        </div>
        <div slot="modal-content">
          <base-typology>
            {{ $t('utils.unsavedChangesLong') }}
          </base-typology>
        </div>
        <div slot="modal-footer">
          <div id="modal-footer">
            <base-button
              id="close-button"
              style-type="secondary"
              :text="$t('utils.close')"
              class="mr-2"
              @click="cancelLeave()"
              @keyup.enter="cancelLeave()"
            />
            <base-button
              id="continue-button"
              style-type="primary"
              :text="$t('utils.continue')"
              @click="continueLeave()"
              @keyup.enter="continueLeave()"
            />
          </div>
        </div>
      </aab-modal>
    </div>
  </layout-form>
</template>

<script>
import { defineComponent, ref } from 'vue';
import LayoutForm from '@/layouts/LayoutForm.vue';
import RequestCreate from '@/components/shareholderId/sidRequest/createForm/RequestCreate.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import navItems from '@/utils/reference/navItems';

/**
 * SidCreateView RouteComponent
 * This creates the form view and the logic
 * necessary for navigation guards
 */
export default defineComponent({
  name: 'SidCreateView',
  components: {
    BaseButton,
    BaseTypology,
    RequestCreate,
    LayoutForm,
  },
  beforeRouteLeave(to) {
    // navigation guard has to be on route component level
    if (this.form.v$.$anyDirty && !this.confirmLeave && to.name !== 'LoginView') {
      this.queuedRoute = to.path;
      this.confirmLeaveModal = true;
      return false;
    }
    return true;
  },
  props: {
    sidRequestUuid: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const form = ref(null);
    return { form };
  },
  data() {
    return {
      confirmLeaveModal: null,
      confirmLeave: false,
      queuedRoute: null,
      navItems,
    };
  },
  methods: {
    cancelLeave() {
      this.confirmLeaveModal = null;
      this.confirmLeave = false;
    },
    continueLeave() {
      this.confirmLeave = true;
      this.$router.push(this.queuedRoute);
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;

#modal-footer {
  display: flex;
  justify-content: flex-end;
}

</style>
