<template>
  <base-data
    :columns="columns"
    :rows="shareholders"
    :title="`${
      $t('shareholderId.identifiedHolders',
      )
    } (${totalItems})`"
    selectable-rows
    :loading="loading"
    :error="error"
    :empty-message="$t('shareholderId.noShareholders')"
    :sort-direction="sortDirection"
    :sort-on="sortBy"
    :page-size="pageSize"
    :page-number="pageNumber"
    :total-items="totalItems"
    :action-bar="actionBar"
    @action-bar-event="downloadSidReport"
    @row-selected="toggleAccordion($event)"
    @aab-pagination-updated="reloadShareholdersPagination($event)"
  >
    <template
      v-for="shareholder in shareholders"
      :key="shareholder.id"
      #[`accordion-slot-${shareholder.id}`]
    >
      <holder-item
        :shareholder="shareholder"
        :index="shareholder.rank"
      />
    </template>
  </base-data>
</template>

<script>
import useSidRequestStore from '@/stores/SidRequestStore';
import { getExcelReport, getSidRequestShareholders } from '@/services/sidService';
import HolderItem from '@/components/shareholderId/report/HolderDetails.vue';
import { em_folder as emptyIcon } from '@aab/sc-aab-icon-set';
import BaseData from '@/components/base/BaseData.vue';
import { amountConverter } from '@/utils/modelConverters/baseDataTableConverters';
import getCountryName from '@/utils/country';

export default {
  name: 'HoldersList',
  components: { BaseData, HolderItem },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      sortBy: '',
      sortDirection: 'DESC',
      pageSize: 25,
      pageNumber: 1,
      totalItems: 0,
      shareholders: [],
      emptyIcon,
      downloadDisabled: false,
    };
  },
  computed: {
    dataPresent() {
      return this.shareholders.length > 0;
    },
    columns() {
      // these settings should match the width settings of the content in HolderItem
      return [
        {
          key: 'rank',
          label: this.$t('shareholderId.rank'),
          sortable: false,
          width: '10',
        },
        {
          key: 'shareHolderNames',
          template: 'mainTitle',
          label: this.$t('shareholderId.holder'),
          sortable: false,
          width: '35',
        },
        {
          key: 'holdingType',
          label: this.$t('shareholderId.holdingType'),
          sortable: false,
          width: '15',
        },
        {
          key: 'entityType',
          label: this.$t('shareholderId.entityType'),
          sortable: false,
          width: '15',
        },
        {
          key: 'amount',
          template: 'amount',
          label: this.$t('gen.amount'),
          sortable: false,
          width: '20',
        },
        {
          key: 'accordionOpen',
          template: 'accordion',
          width: '5',
        },
      ];
    },
    actionBar() {
      return {
        primaryAction: {
          text: this.downloadDisabled
            ? this.$t('shareholderId.downloadingReport')
            : this.$t('shareholderId.downloadReport'),
          icon: 'sy_arrow_download',
          disabled: this.downloadDisabled,
          event: 'download-excel',
        },
      };
    },
  },
  async created() {
    await this.getShareholders();
  },
  methods: {
    async getShareholders() {
      this.loading = true;
      this.error = null;
      this.shareholders = [];
      try {
        const data = await getSidRequestShareholders(
          this.sidRequestStore.getSidUuid,
          this.pageNumber,
          this.pageSize,
          null,
        );
        if (data.shareholders) {
          this.shareholders = Object.keys(data.shareholders)
            .map((key) => ({
              id: data.shareholders[key].identifications,
              rank: data.shareholders[key].rank,
              disclosureResponseId: data.shareholders[key].disclosureResponseId,
              entityType: data.shareholders[key].entityType,
              holdingType: data.shareholders[key].holdingType,
              shareHolderNames: {
                value: data.shareholders[key].names,
                subtitle: data.shareholders[key].identifications,
                icon: data.shareholders[key].country !== 'null' ? `flag_${data.shareholders[key].country.toLowerCase()}` : 'null',
                iconLabel: getCountryName(data.shareholders[key].country),
                iconSubtitle: data.shareholders[key].country,
              },
              amount: amountConverter(data.shareholders[key].quantity),
              accordionOpen: false,
            }));
          this.totalItems = data.totalItems;
        }
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getSidRequestShareholders')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    async reloadShareholdersPagination(event) {
      this.pageSize = event.detail.value.itemsPerPage;
      this.pageNumber = event.detail.value.pageNumber;
      await this.getShareholders();
    },
    toggleAccordion(event) {
      this.shareholders = this.shareholders
        .map((shareholder) => (shareholder.id === event.rowId ? {
          ...shareholder,
          accordionOpen: !shareholder.accordionOpen,
        } : shareholder));
    },
    async downloadSidReport() {
      this.downloadDisabled = true;
      await getExcelReport(this.sidRequestStore.getSidUuid, () => {
        this.downloadDisabled = false;
      }, () => {
        this.downloadDisabled = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 0;
}

// to accommodate accordion chevron space
#header {
  margin-right: 40px;
}

#error {
  width: 25%;
  margin: auto;
}
</style>
