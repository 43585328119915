<template>
  <div class="mx-3">
    <h2 class="h2">
      {{ $t('account.myAccount.label') }}
    </h2>
    <div class="row">
      <div class="col-12 col-md-6">
        <div
          v-if="error"
          class="mb-2"
        >
          <aab-notification
            type="warning"
            type-aria-label="Warning notification"
          >
            <p>{{ error }}</p>
          </aab-notification>
        </div>
        <div class="mb-2">
          <base-typology tag="label">
            {{ $t('user.launcherId') }}<br>
          </base-typology>
          <base-typology>{{ details.username }}</base-typology>
        </div>
        <div class="mb-2">
          <base-typology tag="label">
            {{ $t('user.name') }}<br>
          </base-typology>
          <base-typology>{{ fullName }}</base-typology>
        </div>
        <div class="mb-2">
          <base-typology tag="label">
            {{ $t('user.email') }}<br>
          </base-typology>
          <base-typology>{{ details.email }}</base-typology>
        </div>
        <div
          v-if="details.roles"
          id="roles"
          class="mb-2"
        >
          <base-typology tag="label">
            {{ $tc('user.role', details.roles.length) }}<br>
          </base-typology>
          <base-typology
            v-for="role in details.roles"
            :key="role"
          >
            {{ role }}<br>
          </base-typology>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { getUser } from '@/services/userService';
import * as changeCase from 'change-case';

export default defineComponent({
  name: 'MyAccount',
  components: { BaseTypology },
  data() {
    return {
      loading: false,
      error: null,
      details: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
      },
    };
  },
  computed: {
    fullName() {
      return `${this.details.firstName} ${this.details.lastName}`;
    },
  },
  created() {
    this.getDataUser();
  },
  methods: {
    async getDataUser() {
      this.loading = true;
      this.error = null;
      try {
        const data = await getUser();
        if (data) {
          this.details.username = changeCase.capitalCase(data.username);
          this.details.firstName = data.firstName;
          this.details.lastName = data.lastName;
          this.details.email = data.email;
          this.details.roles = data.roles.map((role) => changeCase.sentenceCase(role));
        }
      } catch (e) {
        this.error = `${this.$t('account.errors.getUser')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.h2;
@include lib.mx_3;
@include lib.mb_2;
@include lib.row;
@include lib.col_md_6;

</style>
