function getSortVector(sortValue1, sortValue2, sortDir) {
  if (sortDir === 'DESC') {
    if (sortValue1 > sortValue2) {
      return -1;
    }
    if (sortValue1 < sortValue2) {
      return 1;
    }
    return 0;
  }
  // sort ascending
  if (sortValue1 > sortValue2) {
    return 1;
  }
  if (sortValue1 < sortValue2) {
    return -1;
  }
  return 0;
}

/**
 * Sort an array of objects depending on sort direction and key.
 * Accepts only single key (String) and is more efficient at sorting objects
 * than sortObjectsMultipleProps() when it comes to a single key.
 * @name sortObjects
 * @param array       the array of objects
 * @param key         the property key on which to sort (String)
 * @param sortDir     sort direction, ASC or DESC (String)
 * @returns {Array}   Objects, sorted
 */
export function sortObjects(array, key, sortDir) {
  return array.sort((item1, item2) => {
    let sortValue1;
    let sortValue2;
    // adjust for undefined, nested and null values
    if (item1[key] === undefined || item1[key] === null) {
      sortValue1 = 0;
    } else {
      sortValue1 = item1[key].value === undefined ? item1[key] : item1[key].value;
      sortValue1 = sortValue1 === null ? 0 : sortValue1;
    }
    if (item2[key] === undefined || item2[key] === null) {
      sortValue2 = 0;
    } else {
      sortValue2 = item2[key].value === undefined ? item2[key] : item2[key].value;
      sortValue2 = sortValue2 === null ? 0 : sortValue2;
    }
    return getSortVector(sortValue1, sortValue2, sortDir);
  });
}

/**
 * Sort an array of objects.
 * Accepts an array of keys, values of which will be aggregated.
 * The aggregated value is used to sort the objects.
 * If single key is passed then this function is identical to sortObjects().
 * However, sortObjects() is more efficient in that scenario.
 * @name sortObjects
 * @param array       The array of objects
 * @param keys        The property keys to add up and sort on. Array of Strings.
 * @param sortDir     Sort direction, ASC or DESC (String)
 * @returns {Array}   Objects, sorted
 */
export function sortObjectsMultipleProps(array, keys, sortDir) {
  return array.sort((item1, item2) => {
    const sortValueItem1 = Object.values(keys)
      .map((key) => item1[key])
      .reduce((partialSum, a) => partialSum + a, 0);
    const sortValueItem2 = Object.values(keys)
      .map((key) => item2[key])
      .reduce((partialSum, a) => partialSum + a, 0);
    return getSortVector(sortValueItem1, sortValueItem2, sortDir);
  });
}

/**
 * Group objects in array so array does not exceed max amount of items
 * @name sortObjects
 * @param data                   the complete array of objects
 * @param maxUngroupedAmount     the max amount of ungrouped objects in array.
 *                               Final array size will be maxUngroupedAMount + 1.
 * @param keys                   An array of keys the objects will be grouped on.
 *                               Objects to be grouped must contain property.
 * @param additionalProps        An array of objects, like so: [{ key: x, value: y}].
 *                               These key value pairs will be added to the aggregated object.
 * @returns {Array}              Array, with the objects past maxUngroupedAmount aggregated,
 *                               with potential additionalProps added.
 */
export function groupData(data, maxUngroupedAmount, keys, additionalProps) {
  // check whether aggregation is necessary
  if (data.length <= maxUngroupedAmount) {
    return data;
  }
  // split data into two arrays, one to be aggregated
  const ungroupedItems = data.slice(0, maxUngroupedAmount);
  const others = data.slice(maxUngroupedAmount);
  // create base aggregate based off of keys for reduce()
  const baseAcc = {};
  keys.forEach((key) => {
    baseAcc[key] = 0;
  });
  // aggregate the items to be grouped based on keys
  const aggregate = others.reduce(
    (acc, item) => {
      keys.forEach((key) => {
        if (item[key]) {
          acc[key] += item[key];
        }
      });
      return acc;
    },
    baseAcc,
  );
  // add additional properties to aggregated object
  if (additionalProps) {
    additionalProps.forEach((prop) => {
      aggregate[prop.key] = prop.value;
    });
  }
  // add aggregate object to ungrouped items and return
  ungroupedItems.push(aggregate);
  return ungroupedItems;
}
