<template>
  <div>
    <section id="dashboard">
      <dashboard-grid></dashboard-grid>
    </section>
    <section id="shareholders"></section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/shareholderId/report/DashboardGrid.vue';

export default defineComponent({
  name: 'RequestReport',
  components: { DashboardGrid },
});
</script>
