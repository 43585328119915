export const roles = {
  generalAgent: 'GENERAL_AGENT',
  userManagement: 'USER_MANAGEMENT',
};

export const lockedStatus = {
  false: 'Unlocked',
  true: 'Locked',
};

export const generalStatus = {
  draft: 'DRAFT',
  agentApproval: 'AWAITING_AGENT_APPROVAL',
  issuerApproval: 'AWAITING_ISSUER_APPROVAL',
  approved: 'APPROVED',
  inProgress: 'IN_PROGRESS',
  completed: 'COMPLETED',
  rejected: 'REJECTED',
  cancelled: 'CANCELLED',
};

export const specialCharacters = '_!@#$%^&()*';

export const entityTypes = {
  legal: 'LEGAL',
  legalPerson: 'LEGAL_PERSON',
  naturalPerson: 'NATURAL_PERSON',
  natural: 'NATURAL',
};

export const identificationTypes = {
  alien: 'ALIEN_REGISTRATION_NUMBER',
  bic: 'BIC',
  clientId: 'CLIENT_IDENTIFICATION',
  custom: 'CUSTOM',
  driver: 'DRIVER_LICENSE_NUMBER',
  idCard: 'IDENTITY_CARD_NUMBER',
  lei: 'LEI',
  nationalRegistrationId: 'NATIONAL_REGISTRATION_IDENTIFICATION_NUMBER',
  nationalRegistration: 'NATIONAL_REGISTRATION_NUMBER',
  passport: 'PASSPORT_NUMBER',
  propId: 'PROPRIETARY_IDENTIFICATION',
  socialSec: 'SOCIAL_SECURITY_NUMBER',
  taxId: 'TAX_IDENTIFICATION_NUMBER',
  unknown: 'UNKNOWN',
};

export const holdingTypes = {
  bene: 'BENE',
  ooac: 'OOAC',
  unknown: 'UKWN',
  nominee: 'NOMI',
};

export const partyRoleTypes = {
  decm: 'DecisionMaker',
  lgrd: 'LegalGuardian',
  dist: 'Distributor',
};
