import navItems from '@/utils/reference/navItems';

const navItemsMain = [
  navItems.sidHome,
];

const navItemsAccount = [
  navItems.account.myAccount,
  navItems.account.changePassword,
];

export default {
  navItemsMain,
  navItemsAccount,
};
