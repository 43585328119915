import {
  entityTypes,
  generalStatus as status,
  holdingTypes, identificationTypes, partyRoleTypes,
} from '@/utils/reference/globalConstants';
import i18n from '@/i18n';
import { formatDateLong, formatDateTime } from '@/utils/formatLib';

const unknown = i18n.global.t('utils.unknown');
const undisclosed = i18n.global.t('utils.undisclosed');

export function createStatus(value) {
  const statusObj = {
    value,
    statusMessage: '',
    styleType: '',
  };
  if (value === status.agentApproval) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.agentApproval');
    statusObj.styleType = 'warning';
  } else if (value === status.issuerApproval) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.issuerApproval');
    statusObj.styleType = 'warning';
  } else if (value === status.rejected) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.rejected');
    statusObj.styleType = 'negative';
  } else if (value === status.draft) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.draft');
    statusObj.styleType = 'info';
  } else if (value === status.approved) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.approved');
    statusObj.styleType = 'positive';
  } else if (value === status.inProgress) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.inProgress');
    statusObj.styleType = 'info';
  } else if (value === status.cancelled) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.cancelled');
    statusObj.styleType = 'inactive';
  } else if (value === status.completed) {
    statusObj.statusMessage = i18n.global.t('utils.statuses.completed');
    statusObj.styleType = 'inactive';
  } else {
    statusObj.statusMessage = i18n.global.t('utils.unknown');
    statusObj.styleType = 'negative';
  }
  return statusObj;
}

export function getHoldingTypeTranslation(value) {
  switch (value) {
    case holdingTypes.bene:
      return i18n.global.t('shareholderId.beneficiary');
    case holdingTypes.ooac:
      return i18n.global.t('shareholderId.onOwnAccount');
    case holdingTypes.nominee:
      return i18n.global.t('shareholderId.nominee');
    case holdingTypes.unknown:
      return unknown;
    default:
      return value;
  }
}

export function getEntityTypeTranslation(value) {
  switch (value) {
    case entityTypes.legal:
    case entityTypes.legalPerson:
      return i18n.global.t('gen.legal');
    case entityTypes.natural:
    case entityTypes.naturalPerson:
      return i18n.global.t('gen.natural');
    default:
      return value;
  }
}

function getPartyRoleTranslation(value) {
  switch (value) {
    case partyRoleTypes.lgrd:
      return i18n.global.t('partyRoleTypes.legal');
    case partyRoleTypes.decm:
      return i18n.global.t('partyRoleTypes.decision');
    case partyRoleTypes.dist:
      return i18n.global.t('partyRoleTypes.dist');
    default:
      return value;
  }
}

export function getIdTypeTranslation(value) {
  switch (value) {
    case identificationTypes.alien:
      return i18n.global.t('idTypes.alien');
    case identificationTypes.bic:
      return i18n.global.t('idTypes.bic');
    case identificationTypes.clientId:
      return i18n.global.t('idTypes.clientId');
    case identificationTypes.custom:
      return i18n.global.t('idTypes.custom');
    case identificationTypes.driver:
      return i18n.global.t('idTypes.driver');
    case identificationTypes.idCard:
      return i18n.global.t('idTypes.idCard');
    case identificationTypes.lei:
      return i18n.global.t('idTypes.lei');
    case identificationTypes.nationalRegistrationId:
      return i18n.global.t('idTypes.nationalRegistrationId');
    case identificationTypes.nationalRegistration:
      return i18n.global.t('idTypes.nationalRegistration');
    case identificationTypes.passport:
      return i18n.global.t('idTypes.passport');
    case identificationTypes.propId:
      return i18n.global.t('idTypes.propId');
    case identificationTypes.socialSec:
      return i18n.global.t('idTypes.socialSec');
    case identificationTypes.taxId:
      return i18n.global.t('idTypes.taxId');
    case identificationTypes.unknown:
    case undefined:
    case null:
      return i18n.global.t('idTypes.id');
    default:
      return value;
  }
}

export function formatAddress(data) {
  if (!data) {
    return null;
  }
  return {
    addressLines: data.addressLines,
    streetName: data.streetName,
    buildingNumber: data.buildingNumber,
    postalBox: data.postalBox,
    postalCode: data.postalCode,
    townName: data.townName,
    countrySubDivision: data.countrySubDivision,
    country: data.country,
  };
}

function formatThirdParties(data) {
  if (!data) {
    return null;
  }
  return Object.keys(data).map((key) => ({
    name: data[key].name,
    identifier: data[key].identifier,
    identificationType: getIdTypeTranslation(data[key].identificationType),
    entityType: getEntityTypeTranslation(data[key].entityType),
    partyRole: getPartyRoleTranslation(data[key].partyRole),
    address: formatAddress(data[key].address),
  }));
}

function formatAccountsAndHoldings(data) {
  if (!data) {
    return null;
  }
  return Object.keys(data).map((key) => ({
    name: data[key].shareholderName,
    securitiesAccountNumber: data[key].securitiesAccountNumber || undisclosed,
    securities: data[key].securities,
    ownershipPercentage: data[key].ownershipPercentage / 100,
    initialDateOfShareholding: data[key].initialDateOfShareholding
      ? formatDateTime(data[key].initialDateOfShareholding) : undisclosed,
    ownershipType: (data[key].ownershipType === 'UNDISCLOSED' || !data[key].ownershipType)
      ? undisclosed : data[key].ownershipType,
    investorType: (data[key].investorType === 'UNDISCLOSED' || !data[key].investorType)
      ? undisclosed : data[key].investorType,
    address: formatAddress(data[key].address),
    thirdParties: formatThirdParties(data[key].thirdParties),
  }));
}

function formatContactPerson(data) {
  if (!data) {
    return null;
  }
  return {
    name: data.name,
    email: data.email,
    phoneNumber: data.phoneNumber,
    mobileNumber: data.mobileNumber,
    faxNumber: data.faxNumber,
  };
}

export function formatRespondingIntermediaries(data) {
  return Object.keys(data).map((key) => ({
    name: data[key].name,
    alternativeName: data[key].alternativeName,
    responseId: data[key].intermediaryResponseIdentifier,
    identificationType: getIdTypeTranslation(data[key].identificationType),
    intermediaryIdentifier: data[key].intermediaryIdentifier,
    disclosureDate: data[key].disclosureDate
      ? formatDateLong(data[key].disclosureDate) : unknown,
    contactPerson: formatContactPerson(data[key].contactPerson),
    address: formatAddress(data[key].address),
    accountsAndHoldings: formatAccountsAndHoldings(data[key].accountsAndHoldings),
  }));
}
