<template>
  <layout-sidebar-left
    :title-long="$t('account.myAccount.label')"
    :title-short="$t('account.label')"
    show-title-bar
    large-title-bar
  >
    <template #titleBar>
      <h1 class="h1 mb-0">
        <span class="d-md-block text-white d-none">{{ $t('account.label') }}</span>
        <span class="d-md-none d-block ml-2 mb-0 mt-1 text-white">
          {{ $t('account.label') }}
        </span>
      </h1>
    </template>
    <template #sidebar>
      <div class="bg-white shadow-well">
        <base-menu :menu-items="items" />
      </div>
    </template>
    <div class="bg-white shadow-well py-4">
      <router-view />
    </div>
  </layout-sidebar-left>
</template>

<script>
import LayoutSidebarLeft from '@/layouts/LayoutSidebarLeft.vue';
import BaseMenu from '@/components/base/BaseMenu.vue';
import { defineComponent } from 'vue';
import { getAccountNavItems } from '@/utils/getNavItems';
import useUserStore from '@/stores/UserStore';

export default defineComponent({
  name: 'UserAccountView',
  components: {
    BaseMenu,
    LayoutSidebarLeft,
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  computed: {
    items() {
      return getAccountNavItems(this.$router.getRoutes(), this.userStore.getRoles);
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.d_block;
@include lib.d_md_block;
@include lib.d_md_none;
@include lib.d_none;
@include lib.text_white;

@include lib.h1;
@include lib.mb_0;
@include lib.ml_1;
@include lib.mt_1;

@include lib.shadow_well;
@include lib.py_4;
@include lib.py_2;

.bg-white {
  background-color: lib.$white;
}

</style>
