<template>
  <div>
    <section
      :aria-label="$t('shareholderId.disclosureProgress')"
      class="container"
    >
      <div class="progressArea shadow-well containerItem">
        <disclosure-progress />
      </div>
      <div class="graphPersons shadow-well containerItem">
        <holders-graph />
      </div>
      <div class="graphSecurities shadow-well containerItem">
        <securities-graph />
      </div>
      <div class="topTen shadow-well containerItem">
        <top-ten-table />
      </div>
    </section>
    <section
      id="holders-list"
      class="mt-3"
      :aria-label="$t('shareholderId.identifiedHolders')"
    >
      <holders-list />
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DisclosureProgress from '@/components/shareholderId/report/DisclosureProgress.vue';
import HoldersGraph from '@/components/shareholderId/report/HoldersGraph.vue';
import SecuritiesGraph from '@/components/shareholderId/report/SecuritiesGraph.vue';
import TopTenTable from '@/components/shareholderId/report/TopTenTable.vue';
import HoldersList from '@/components/shareholderId/report/HoldersList.vue';

export default defineComponent({
  name: 'DashboardGrid',
  components: {
    HoldersList,
    TopTenTable,
    SecuritiesGraph,
    HoldersGraph,
    DisclosureProgress,
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.mt_3;

@media (min-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
    'progress topTen'
    'graphPerson graphSecurities';
  }
}

@media (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
    'progress'
    'graphPerson'
    'graphSecurities'
    'topTen';
  }
}

.container {
  gap: 16px;
  margin-top: 24px;
}

.progressArea {
  grid-area: progress;
}

.graphPersons {
  grid-area: graphPerson;
}

.graphSecurities {
  grid-area: graphSecurities;
}

.topTen {
  grid-area: topTen;
}

.containerItem {
  background-color: lib.$white;
  padding: 24px;
  width: 100%
}

</style>
