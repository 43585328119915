<template>
  <div>
    <form @keyup.enter="submit()">
      <div class="col px-0">
        <div class="row pt-3 mx-2">
          <aab-inputblock-password
            :icon-position="'left'"
            class="form-element"
          >
            <label
              slot="label"
              for="password"
            >
              {{ $t(labelPassword) }}
            </label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
            >
            <base-icon
              slot="icon"
              :label="$t(labelPassword)"
              icon-name="sy_tools_lock"
              size="3"
              :color="iconColor"
            />
          </aab-inputblock-password>
        </div>
        <div class="row pt-3 mx-2">
          <aab-inputblock-password
            :icon-position="'left'"
            class="form-element"
          >
            <label
              slot="label"
              for="confirm-password"
            >
              {{ $t(labelConfirmPassword) }}
            </label>
            <input
              id="confirm-password"
              v-model="confirmPassword"
              name="confirm-password"
              type="password"
            >
            <base-icon
              slot="icon"
              :label="$t(labelConfirmPassword)"
              icon-name="sy_tools_lock"
              size="3"
              :color="iconColor"
            />
          </aab-inputblock-password>
        </div>
        <div
          v-if="showValidationErrors"
          class="row pt-3 mx-2"
        >
          <aab-notification type="warning">
            <span>{{ passwordValidationMessage }}</span>
            <ul>
              <li
                v-for="error in v$.$errors"
                :key="error.$validator"
              >
                {{ error.$message }}
              </li>
            </ul>
          </aab-notification>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { n300 } from '@aab/sc-styles-colors';
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import {
  helpers,
} from '@vuelidate/validators';
import {
  atLeastOneLowerCaseChar,
  atLeastOneUpperCaseChar,
  atLeastOneDigit,
  atLeastOneSpecialChar,
  required, minLength, sameAs,
} from '@/utils/customValidation';
import { specialCharacters } from '@/utils/reference/globalConstants';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default defineComponent({
  name: 'PasswordForm',
  components: { BaseIcon },
  props: {
    variant: {
      default: 'activate',
      type: String,
      validator(val) {
        return ['activate', 'update', 'forgotten'].includes(val);
      },
    },
  },
  emits: ['password-submit'],
  data() {
    return {
      v$: useVuelidate(),
      iconColor: n300,
      password: '',
      confirmPassword: '',
    };
  },
  computed: {
    passwordValidationMessage() {
      return (this.v$.$errors.length > 0) ? this.$t('auth.errors.passwordRequirement') : '';
    },
    showValidationErrors() {
      return (this.passwordValidationMessage && this.variant !== 'update');
    },
    labelPassword() {
      if (this.variant === 'update') {
        return 'user.newPassword';
      }
      return 'user.password';
    },
    labelConfirmPassword() {
      if (this.variant === 'update') {
        return 'user.confirmNewPassword';
      }
      return 'user.confirmPassword';
    },
  },
  methods: {
    submit() {
      this.$emit('password-submit', { data: this.password });
    },
    resetForm() {
      this.password = '';
      this.confirmPassword = '';
    },
  },
  validations() {
    return {
      password: {
        required: helpers.withParams({ field: this.$t(this.labelPassword) }, required),
        minLength: helpers.withParams({ field: this.$t(this.labelPassword) }, minLength(10)),
        atLeastOneLowerCaseChar: helpers.withParams(
          { field: this.$t(this.labelPassword) },
          atLeastOneLowerCaseChar,
        ),
        atLeastOneUpperCaseChar: helpers.withParams(
          { field: this.$t(this.labelPassword) },
          atLeastOneUpperCaseChar,
        ),
        atLeastOneDigit: helpers.withParams(
          { field: this.$t(this.labelPassword) },
          atLeastOneDigit,
        ),
        atLeastOneSpecialChar: helpers.withParams(
          { field: this.$t(this.labelPassword), characters: specialCharacters },
          atLeastOneSpecialChar,
        ),
        $autoDirty: true,
      },
      confirmPassword: {
        required: helpers.withParams({ field: this.$t(this.labelConfirmPassword) }, required),
        sameAs: helpers.withParams(
          { field: this.$t(this.labelPassword), sameAsField: this.$t(this.labelConfirmPassword) },
          sameAs(this.password),
        ),
        $autoDirty: true,
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-3;
@include lib.mt-4;
@include lib.py-4;
@include lib.row;

.form-element {
  width: 100%;
}

</style>
