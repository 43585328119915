<template>
  <the-header class="position-relative"></the-header>
  <router-view />
  <aab-toastmessage
    :toast-message-text="toastStore.getToastMessage"
  ></aab-toastmessage>
</template>

<script>
import TheHeader from '@/components/header/TheHeader.vue';
import useUserStore from '@/stores/UserStore';
import useToastStore from '@/stores/ToastStore';
import '@aab/sc-aab-toastmessage';

export default {
  name: 'App',
  components: { TheHeader },
  setup() {
    const userStore = useUserStore();
    const toastStore = useToastStore();
    return { userStore, toastStore };
  },
  created() {
    this.userStore.autoLogin();
  },
};
</script>

<style lang="scss">
@use '@/styles/styles' as lib;

@include lib.position_relative;
</style>
