<template>
  <div class="shadow-tile rounded-2 bg-white">
    <slot></slot>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTile',
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.shadow_tile;
@include lib.rounded_2;

.footer {
  background-color: lib.$gm55;
}

</style>
