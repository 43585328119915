<template>
  <aab-button
    :style-type="styleType"
    :full-width="fullWidth"
    :large="large"
    :icon-position="iconPosition"
  >
    <button
      :type="type"
      :disabled="disabled"
    >
      <base-icon
        v-if="icon"
        class="pr-1"
        :icon-name="icon"
        size="3"
        :color="iconColor"
        :label="text"
      />
      {{ text }}
    </button>
  </aab-button>
</template>

<script>
import { g300, n400, n670 } from '@aab/sc-styles-colors';
import BaseIcon from '@/components/base/BaseIcon.vue';

/**
 * BaseButton is a base component for all buttons, following aab-button styling
 * Props:
 * - type: follows html button types. Defaults to button. Helps with default html functionality
 * - style-type: determines the style and options are the aab-button options
 * - text: copy of the button
 * - large: offers extra large option (i.e. mobile)
 * - full-width: will take up full width of available space
 * - disabled: determines disabled state
 * - iconPosition: determines whether icon is left or right
 * - icon: the name of the icon. Use Emerald Icon library names.
 */
export default {
  name: 'BaseButton',
  components: { BaseIcon },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    styleType: {
      type: String,
      default: 'base',
      validator: (value) => ([
        'primary',
        'secondary',
        'base',
        'action',
      ].indexOf(value) !== -1),
    },
    text: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ([
        'left',
        'right',
      ].indexOf(value) !== -1),
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    iconColor() {
      if (this.disabled) {
        return n400;
      }
      if (this.styleType === 'secondary') {
        return g300;
      }
      if (this.styleType === 'action') {
        return g300;
      }
      return n670;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.pr_1;
</style>
