<template>
  <aab-sidepanel
    :side-panel-title="$t('utils.filters')"
    :is-open="openPanel || null"
    :disable-background="true"
    @side-panel-closed="$emit('close-panel')"
    @side-panel-open="$emit('open-panel')"
  >
    <slot name="filter-panel-body"></slot>
    <div slot="footer">
      <div class="panel-footer-buttons">
        <base-button
          id="cancel-button"
          style-type="secondary"
          :text="$t('utils.cancel')"
          class="mr-2 button"
          @click="$emit('close-panel')"
          @keyup.enter="$emit('close-panel')"
        />
        <base-button
          id="apply-button"
          class="button"
          style-type="primary"
          :text="$t('utils.apply')"
          @click="$emit('apply-filters')"
        />
      </div>
    </div>
  </aab-sidepanel>
</template>

<script>
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'BaseFilterSidePanel',
  components: { BaseButton },
  props: {
    openPanel: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['close-panel', 'apply-filters', 'select-filter', 'open-panel'],
});

</script>

<style lang="scss" scoped>
@use '@/styles/styles.scss' as lib;

@include lib.mt_0;
@include lib.mb_1;

.panel-footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 1em;

  .button {
    flex-grow: 1;
  }
}

</style>
