export default function createIssuerData(data) {
  const issuers = Object.keys(data.list)
    .map((key) => ({
      uuid: data.list[key].issuerUuid,
      logoUrl: data.list[key].logoUrl,
      name: data.list[key].name,
      officialName: data.list[key].officialName,
      slug: data.list[key].slug,
    }));
  return {
    issuers,
    totalItems: data.totalElements,
  };
}
