import { formatNumber } from '@/utils/formatLib';

function format(value) {
  const number = Number.parseInt(value, 10);
  if (Number.isNaN(number)) {
    return '';
  }
  return formatNumber(number, 'decimal');
}

/**
 * Directive thousandSeparator. This can be used on text input fields
 * to mask the true value for the user by including a thousand separator.
 * It currently assumes that the separator is a comma. In the future this
 * needs to be flexible depending on the i18n language.
 */
const thousandSeparator = {
  beforeMount(el, _binding, vnode) {
    el.addEventListener('input', () => {
      const originalValue = el.value;
      let input = el.value.replace(/,/g, ''); // remove existing commas
      if (input === '') {
        // eslint-disable-next-line no-param-reassign
        el.value = input;
      } else {
        // remove any non-numeric characters
        input = input.replace(/[^0-9]/g, '');
        // eslint-disable-next-line no-param-reassign
        el.value = format(input);
      }
      // don't trigger if value has not changed
      // this step is necessary to override v-model
      if (originalValue !== el.value) {
        vnode.el.dispatchEvent(new CustomEvent('input'));
      }
    });
  },
};

export default thousandSeparator;
