<template>
  <layout-full-width
    :title-long="titleLongKey"
    :title-short="titleShortKey"
    large-title-bar
    :back-button-title="backButtonTitle"
    :back-button-path="backButtonPath"
  >
    <div>
      <div v-if="!isMatchingDetailsPageActive">
        <aab-tabs id="sidRequestTabs">
          <aab-tab
            v-for="tab in tabs"
            :id="tab.id"
            :key="tab.id"
            :divider="true"
            :active="selectedTab === tab.id"
            :disabled="tab.disabled"
            tabindex="0"
            @click="onTabSelect(tab)"
            @keyup.enter="onTabSelect(tab)"
          >
            {{ tab.label }}
          </aab-tab>
        </aab-tabs>
        <section
          aria-label="Status"
          class="my-4"
        >
          <!-- error -->
          <div
            v-if="error"
            id="error"
          >
            <aab-notification
              type="warning"
              type-aria-label="Warning notification"
            >
              <p>{{ error }}</p>
            </aab-notification>
          </div>
          <!--loading-->
          <div
            v-if="loading"
            id="spinner"
          >
            <aab-spinner
              size="8"
              hide-overlay
            />
          </div>
        </section>
      </div>
      <div v-if="sidRequestStore.getSidRequest">
        <router-view />
      </div>
    </div>
  </layout-full-width>
</template>

<script>
import { defineComponent } from 'vue';
import navItems from '@/utils/reference/navItems';
import useSidRequestStore from '@/stores/SidRequestStore';
import LayoutFullWidth from '@/layouts/LayoutFullWidth.vue';

/**
 * SidRequestView contains the logic necessary to download the SID Details
 * and put them into the sidRequestStore. It also contains the tab logic
 * and the router view for the SID Request details, report, nominees
 * and manual matching components
 */
export default defineComponent({
  name: 'SidRequestView',
  components: { LayoutFullWidth },
  props: {
    sidRequestUuid: {
      type: String,
      required: true,
    },
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      selectedTab: this.$route.name,
      error: null,
      loading: false,
      tabs: null,
    };
  },
  /**
   * When on a matching details overview, tabs and status aren't necessary in DOM
   */
  computed: {
    isMatchingDetailsPageActive() {
      return this.$route.name === navItems.sidHome.sidRequest.matchingDetails.routeName;
    },
    backButtonPath() {
      // when in a matching details overview,
      // the back button must point to the nominees page of the active sid request
      if (this.isMatchingDetailsPageActive) {
        return `/sid/request/${this.$route.params.sidRequestUuid}/nominees`;
      }
      // when elsewhere in a sid request, the back button must point to the sid request list
      return '/sid/list';
    },
    backButtonTitle() {
      // when in a matching details overview, the back button says back
      if (this.isMatchingDetailsPageActive) {
        return this.$t('utils.back');
      }
      // when elsewhere in a sid request, the back button points to sid request list
      return this.$t('shareholderId.listing');
    },
    titleLongKey() {
      if (this.isMatchingDetailsPageActive) {
        // if there is no nominee name, show nothing
        return this.sidRequestStore.getNomineeAccountName || '';
      }
      if (this.sidRequestStore.isSidRequestLoaded) {
        return `${this.$t('shareholderId.label')}: ${this.sidRequestStore.getSidIssuerName}`;
      }
      return this.$t('shareholderId.label');
    },
    titleShortKey() {
      if (this.isMatchingDetailsPageActive) {
        // if there is no nominee name, show nothing
        return this.sidRequestStore.getNomineeAccountName || '';
      }
      if (this.sidRequestStore.isSidRequestLoaded) {
        return this.sidRequestStore.getSidIssuerName;
      }
      return this.$t('shareholderId.label');
    },
  },
  async created() {
    await this.getSidRequest();
    this.tabs = [{
      label: this.$t('gen.details'),
      id: navItems.sidHome.sidRequest.details.routeName,
      disabled: false,
    },
    {
      label: this.$t('gen.report'),
      id: navItems.sidHome.sidRequest.report.routeName,
      disabled: !this.sidRequestStore.hasSidRequestStarted,
    },
    {
      label: this.$tc('shareholderId.nominee', 2),
      id: navItems.sidHome.sidRequest.nominees.routeName,
      disabled: !this.sidRequestStore.hasSidRequestStarted,
    },
    {
      label: this.$t('shareholderId.manualMatching'),
      id: navItems.sidHome.sidRequest.manualMatching.routeName,
      disabled: !this.sidRequestStore.hasSidRequestStarted,
    }];
  },
  unmounted() {
    this.sidRequestStore.$reset();
  },
  methods: {
    onTabSelect(tab) {
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.$router.push({
        name: tab.id,
        params: { sidRequestUuid: this.sidRequestUuid },
      });
    },
    async getSidRequest() {
      this.error = null;
      this.loading = true;
      try {
        await this.sidRequestStore.getSidRequestDetails(this.sidRequestUuid);
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getSidRequestDetails')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.my_4;

</style>
