<template>
  <div :class="{ 'background' : background }">
    <h4 v-if="label">
      {{ $t(label) }}
    </h4>
    <ul
      class="p-0 m-0"
      :class="{ 'bordered-items' : hasBorder }"
    >
      <base-menu-item
        v-for="item in menuItems"
        :key="item.routeName"
        :link="item"
        :sub-links="item.subLinks"
      />
    </ul>
  </div>
</template>

<script>
import BaseMenuItem from '@/components/base/BaseMenuItem.vue';

export default {
  name: 'BaseMenu',
  components: { BaseMenuItem },
  props: {
    background: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: true,
      required: false,
    },
    menuItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.border_box;
@include lib.p_1;
@include lib.pl_3;
@include lib.py_1;
@include lib.p_0;
@include lib.m_0;
@include lib.float_right;
@include lib.d_inline_block;
@include lib.d_flex;
@include lib.flex_grow_1;
@include lib.h4;

.background {
  background-color: lib.$g50;
}

h4 {
  color: lib.$gm400;
}
</style>
