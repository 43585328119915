<template>
  <li class="header-menu-item flex-grow-1 flex-md-grow-0 text-center">
    <router-link
      :id="item.routeName + '-navLabel'"
      :to="item.route"
      class="header-menu-link d-flex flex-column flex-md-row justify-content-center"
    >
      <span class="header-link-text">{{ $t(item.label) }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'NavItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.flex_md_grow_0;
@include lib.flex_md_row;
@include lib.justify_content_center;
@include lib.text_center;

.header-menu-item {
  height: 100%;
  z-index: 1;

  a {
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: lib.$n400;
    text-decoration: none;
  }

  a:hover,
  a.router-link-active {
    background-color: lib.$g300;
    color: lib.$white;
  }

  .header-menu-link {
    height: 48px;
    padding: 12px 6px;

    .header-link-text {
      font-weight: 500;
    }
  }
}

@media (min-width: 768px) {
  .header-menu-container {
    width: auto;

    .header-menu-item {
      a {
        line-height: 24px;
      }

      .header-menu-link {
        padding: 12px 12px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    max-height: 64px !important;
  }

  .container-fluid ul li .header-menu-link {
    height: 64px !important;
  }
}

@media (min-width: 992px) {
  .header-menu-container {
    padding-left: 90px !important;
    .header-menu-item {
      .header-menu-link {
        padding: 12px 16px;
      }
    }
  }
}
</style>
