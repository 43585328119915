<template>
  <div>
    <hr
      :style="widthStyle"
      :class="[type, color]"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseDivider',
  props: {
    width: {
      type: Number,
      default: 75,
      validator(val) {
        return val > 0 && val <= 100;
      },
    },
    color: {
      type: String,
      default: 'green',
      validator(val) {
        return ['dark', 'light', 'gray', 'lightGray', 'green'].includes(val);
      },
    },
    type: {
      type: String,
      validator(val) {
        return ['dashed', 'dotted', 'double'].includes(val);
      },
      default: null,
    },
  },
  computed: {
    widthStyle() {
      return `width: ${this.width}%`;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

.dark {
  color: lib.$n670;
}

.green {
  composes: lib.$g400;
}

.gray {
  composes: lib.$n300;
}

.lightGray {
  composes: lib.$n100;
}

.light {
  composes: lib.$n50;
}

.dashed {
  border-top: 1px dashed;
  border-bottom: none;
}

.dotted {
  border-top: 1px dotted;
  border-bottom: none;
}

.double {
  border-top: 3px double;
  border-bottom: none;
}

</style>
