<template>
  <base-tile class="set-password-tile">
    <div>
      <div class="px-2 pt-2">
        <h2>{{ $t('user.welcome') }}</h2>
        <base-typology>{{ $t('auth.activateAccount.title') }}</base-typology>
      </div>
      <div
        v-if="error"
        class="mt-2 mx-2"
      >
        <aab-notification
          type="warning"
          type-aria-label="Warning notification"
        >
          <p>{{ error }}</p>
        </aab-notification>
      </div>
      <password-form
        ref="childForm"
        @password-submit="checkValidation($event)"
      ></password-form>
    </div>
    <template #footer>
      <div class="footer">
        <base-button
          id="submit"
          :style-type="'primary'"
          :text="$t('auth.activateAccount.label')"
          :disabled="loading"
          @click="getPassword()"
        ></base-button>
      </div>
    </template>
  </base-tile>
</template>

<script>
import { defineComponent, ref } from 'vue';
import BaseTile from '@/components/base/BaseTile.vue';
import PasswordForm from '@/components/user/PasswordForm.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { activateAccount } from '@/services/userService';
import BaseButton from '@/components/base/BaseButton.vue';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
  name: 'ActivateAccount',
  components: {
    BaseTypology,
    BaseButton,
    PasswordForm,
    BaseTile,
  },
  setup() {
    const childForm = ref(null);
    return { childForm };
  },
  data() {
    return {
      v$: useVuelidate(),
      error: null,
      loading: false,
    };
  },
  methods: {
    getPassword() {
      this.childForm.submit();
    },
    async checkValidation(event) {
      if (!await this.v$.$validate()) {
        return;
      }
      await this.activateAccount(event);
    },
    async activateAccount(event) {
      this.loading = true;
      try {
        await activateAccount(event.data, this.$route.query.token);
        this.$router.push('/login');
      } catch (e) {
        this.error = `${this.$t('auth.errors.activateAccount')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-2;
@include lib.mt-2;
@include lib.row;

.footer {
  padding: 24px 16px 24px 16px;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

.set-password-tile {
  width: 450px;
}
</style>
