<template>
  <component :is="container">
    <div
      id="action-bar"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <base-typology variant="intro">
          {{ title }}
        </base-typology>
        <div
          v-if="actionBar && actionBar.secondaryAction"
          class="ml-2"
        >
          <base-button
            id="secondary-action"
            :text="actionBar.secondaryAction.text"
            :icon="actionBar.secondaryAction.icon"
            :disabled="actionBar.secondaryAction.disabled"
            style-type="secondary"
            @click="$emit('action-bar-event', { event: actionBar.secondaryAction.event })"
            @keyup.enter="$emit('action-bar-event', { event: actionBar.secondaryAction.event })"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <aab-input
          v-if="actionBar?.search?.type === 'input'"
          id="search-input"
          icon-position="right"
          class="mr-2"
        >
          <input
            v-model="searchOn"
            name="searchBy"
            type="text"
            :aria-label="$t('aria.searchField', { key: actionBar.search.searchOptionsKey })"
            :placeholder="$t('utils.searchOn', { value: '...' })"
            @keyup.enter="$emit('action-bar-event',
                                { event: 'search', value: searchOn })"
          >
          <base-icon
            id="searchIcon"
            slot="icon"
            :label="$t('utils.search')"
            icon-name="sy_tools_search"
            size="3"
            :color="iconColor"
            @click="$emit('action-bar-event',
                          { event: 'search', value: searchOn })"
          />
        </aab-input>
        <base-input-dropdown
          v-if="actionBar?.search?.type === 'inputDropdown'"
          ref="inputDropdown"
          id-tag="search"
          name="searchBy"
          :options="actionBar.search.searchData"
          @on-change="searchOn = $event"
          @on-select="$emit('action-bar-event',
                            { event: 'search', value: $event})"
          @click-on-search-icon="$emit('action-bar-event',
                                       { event: 'search', value: $event.value})"
        />
        <base-button
          v-if="actionBar && actionBar.filters"
          id="filter-button"
          :text="actionBar.filters.text || $t('utils.filters')"
          icon="sy_tools_filter"
          style-type="action"
          @click="$emit('action-bar-event', { event: 'open-filters' })"
          @keyup.enter="$emit('action-bar-event', { event: 'open-filters' })"
        />
        <base-button
          v-if="actionBar && actionBar.primaryAction"
          id="primary-action"
          :class="!actionBar.filters ? 'button-spacing-no-filter' : ''"
          :text="actionBar.primaryAction.text"
          :icon="actionBar.primaryAction.icon"
          :disabled="actionBar.primaryAction.disabled"
          style-type="primary"
          @click="$emit('action-bar-event', { event: actionBar.primaryAction.event })"
          @keyup.enter="$emit('action-bar-event', { event: actionBar.primaryAction.event })"
        />
      </div>
    </div>
    <!--loading-->
    <div
      v-if="loading"
      class="my-3"
    >
      <aab-spinner hide-overlay />
    </div>
    <!--error-->
    <div
      v-else-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
    <!--data-->
    <div v-else-if="rows.length > 0">
      <base-data-table
        :caption="title"
        :columns="columns"
        :rows="rows"
        :sort-direction="sortDirection"
        :sort-on="sortOn"
        variant="horizontalOnly"
        :selectable-rows="selectableRows"
        @row-selected="$emit('row-selected', $event)"
        @select-all-rows="$emit('select-all-rows')"
        @request-update="$emit('request-update', $event)"
      >
        <template
          v-for="row in rows"
          :key="row.id"
          #[`accordion-slot-${row.id}`]
        >
          <slot :name="`accordion-slot-${row.id}`"></slot>
        </template>
      </base-data-table>
    </div>
    <!--no results-->
    <div
      v-else
      id="noResults"
      class="my-2"
    >
      <aab-empty-state
        :title="$t('utils.noResults')"
        :text="emptyMessage"
        :icon="emptyIcon"
      ></aab-empty-state>
    </div>
    <div v-if="pageSize">
      <aab-pagination
        items-per-page="[25,50,75,100]"
        :selected-page-item="pageSize"
        :selected-page-number="pageNumber"
        :total-items="totalItems"
        :selected-language="$i18n.locale"
        @aab-pagination-updated="requestUpdatePagination($event)"
      />
    </div>
  </component>
</template>

<script>
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { g300 } from '@aab/sc-styles-colors';
import BaseTile from '@/components/base/BaseTile.vue';
import { em_folder as emptyIcon } from '@aab/sc-aab-icon-set/src/lib/emptyStates';
import BaseInputDropdown from '@/components/base/BaseInputDropdown.vue';

export default {
  name: 'BaseData',
  components: {
    BaseInputDropdown,
    BaseTile,
    BaseIcon,
    BaseButton,
    BaseTypology,
    BaseDataTable,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    selectableRows: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    sortOn: {
      type: String,
      default: null,
    },
    sortDirection: {
      type: String,
      validator(val) {
        return ['ASC', 'DESC'].includes(val);
      },
      default: null,
    },
    pageSize: {
      type: Number,
      default: null,
    },
    pageNumber: {
      type: Number,
      default: null,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    actionBar: {
      type: Object,
      default: () => null,
    },
    container: {
      type: String,
      default: 'base-tile',
    },
  },
  emits: ['row-selected', 'select-all-rows', 'request-update', 'action-bar-event'],
  data() {
    return {
      searchOn: '',
      iconColor: g300,
      emptyIcon,
    };
  },
  methods: {
    requestUpdatePagination(event) {
      this.$emit(
        'request-update',
        {
          pageSize: Number(event.detail.value.itemsPerPage),
          pageNumber: Number(event.detail.value.pageNumber),
        },
      );
    },
    clearInputValue() {
      this.searchOn = '';
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.d_flex;
@include lib.justify_content_between;
@include lib.align_items_center;
@include lib.p_2;
@include lib.ml_2;
@include lib.my_3;
@include lib.m_3;

#action-bar {
  border-bottom: 1px solid lib.$n65;
  padding: 1rem;
}

.button-spacing-no-filter {
  margin-left: 1rem;
}

#search-input input{
  width: 270px;
}

</style>
