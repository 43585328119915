<template>
  <div class="col mt-0 header-profile flex-fill">
    <div class="row float-right m-0">
      <div class="row m-0">
        <span
          v-if="renderLoginButton"
          class="d-block ml-3 float-right"
        >
          <base-button
            v-if="isLoggedIn"
            id="logout"
            :style-type="'secondary'"
            :text="$t('auth.logout')"
            icon="sy_tools_unlock"
            full-width
            @click="handleLogout()"
          />
          <base-button
            v-else
            id="login"
            :style-type="'primary'"
            :text="$t('auth.login')"
            icon="sy_tools_lock"
            full-width
            @click="handleLogin()"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import useUserStore from '@/stores/UserStore';

export default {
  name: 'HeaderProfile',
  components: { BaseButton },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  computed: {
    isLoggedIn() {
      return this.userStore.isAuthenticated;
    },
    renderLoginButton() {
      return this.$route.path !== '/login';
    },
  },
  methods: {
    handleLogout() {
      this.userStore.logout();
      this.$router.push('/home');
    },
    handleLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.col;
@include lib.d_block;
@include lib.flex_fill;
@include lib.float_right;
@include lib.m_0;
@include lib.ml_3;
@include lib.mt_0;
@include lib.row;

.header-profile {
  min-width: 0;

  .header-profile-left {
    min-width: 0;
  }
}
</style>
