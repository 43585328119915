<template>
  <aab-icon
    :svg="iconSvg"
    :color="color"
    :label="label"
    :size="size"
  />
</template>

<script>
import * as iconSet from '@aab/sc-aab-icon-set';

export default {
  name: 'BaseIcon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '4',
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconSvg() {
      return iconSet[this.iconName];
    },
  },
};
</script>
