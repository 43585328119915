<template>
  <form @submit.prevent>
    <base-tile id="form-container">
      <form-identification
        ref="identification"
        :form-data="identificationData"
      />
      <form-shares
        ref="shares"
        :form-data="sharesData"
      />
      <form-planning
        ref="planning"
        :form-data="planningData"
      />
    </base-tile>
    <div
      v-if="error"
      id="error-notification"
      class="pt-5"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
    <div
      id="form-footer"
      class="py-5"
    >
      <base-button
        id="submit-button"
        type="submit"
        style-type="primary"
        :text="loadingSubmit ? $t('utils.submitting') : $t('utils.submit')"
        :disabled="loadingSubmit || loadingDraft"
        @click="submitSidRequestForApproval"
        @keyup.enter="submitSidRequestForApproval"
      />
      <base-button
        id="draft-button"
        type="submit"
        style-type="action"
        :text="loadingDraft ? $t('utils.savingAsDraft') : $t('utils.saveAsDraft')"
        :disabled="loadingSubmit || loadingDraft"
        @click="submitDraftSidRequest"
        @keyup.enter="submitDraftSidRequest"
      />
      <base-button
        id="cancel-button"
        style-type="action"
        :text="$t('utils.cancel')"
        :disabled="loadingSubmit || loadingDraft"
        @click="$router.push(leaveRoute)"
        @keyup.enter="$router.push(leaveRoute)"
      />
    </div>
  </form>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import BaseTile from '@/components/base/BaseTile.vue';
import FormIdentification from '@/components/shareholderId/sidRequest/createForm/FormIdentification.vue';
import FormShares from '@/components/shareholderId/sidRequest/createForm/FormShares.vue';
import { useVuelidate } from '@vuelidate/core';
import BaseButton from '@/components/base/BaseButton.vue';
import FormPlanning from '@/components/shareholderId/sidRequest/createForm/FormPlanning.vue';
import { createSidRequest, setSidRequestStatus, updateSidRequest } from '@/services/sidService';
import { formatDateBackEnd } from '@/utils/formatLib';
import navItems from '@/utils/reference/navItems';
import BaseTypology from '@/components/base/BaseTypology.vue';
import useToastStore from '@/stores/ToastStore';
import useSidRequestStore from '@/stores/SidRequestStore';
import { generalStatus } from '@/utils/reference/globalConstants';

/**
 * RequestCreate is the container component for the form sections for SID creation.
 * It coordinates the submission of the SID Request by validating everything on submit.
 * It does partial validation in the event of a draft submission.
 * Submission happens by first resetting validation, loading and error config.
 * Then the new validation occurs and the SID request is created. For draft the SID request
 * is created first, to simplify the validation process of only two fields.
 * If submission is a success then the user is navigated to the details page of the SID request
 * they just created.
 */
export default defineComponent({
  name: 'RequestCreate',
  components: {
    BaseTypology,
    FormPlanning,
    BaseButton,
    FormShares,
    FormIdentification,
    BaseTile,
  },
  emits: ['confirm-leave'],
  setup() {
    const toastStore = useToastStore();
    const { params: { sidRequestUuid } } = useRoute();
    const identification = ref(null);
    const shares = ref(null);
    const planning = ref(null);
    const sidRequestStore = useSidRequestStore();
    return {
      identification,
      shares,
      planning,
      toastStore,
      sidRequestStore,
      sidRequestUuid,
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      error: null,
      loadingSubmit: false,
      loadingDraft: false,
      leaveRoute: navItems.sidHome.route,
      sidRequest: null,
      identificationData: null,
      sharesData: null,
      planningData: null,
    };
  },
  mounted() {
    if (this.sidRequestUuid) {
      this.fetchSidRequestDetails();
    }
  },
  methods: {
    async fetchSidRequestDetails() {
      try {
        await this.sidRequestStore.getSidRequestDetails(this.sidRequestUuid);
        const sidRequest = this.sidRequestStore.getSidRequest;
        this.identificationData = {
          issuerUuid: sidRequest.issuerUuid,
          issuerName: sidRequest.issuerName,
          isin: sidRequest.isin,
          csdUuid: sidRequest.csdUuid,
        };
        this.sharesData = {
          totalOutstandingShares: sidRequest.totalOutstandingShares,
          threshold: sidRequest.threshold,
          shareThreshold: sidRequest.shareThreshold,
          thresholdUnit: sidRequest.thresholdUnit,
        };
        this.planningData = {
          recordDate: sidRequest.recordDate,
          startDateScheduled: sidRequest.startDateScheduled,
          startDate: sidRequest.startDate,
          deadlineStandard: sidRequest.deadlineStandard,
          disclosureDeadlineDate: sidRequest.disclosureDeadlineDate,
          reportDeadlineDate: sidRequest.reportDeadlineDate,
        };
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getSidRequestDetails')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    async submitSidRequestForApproval() {
      this.loadingSubmit = true;
      this.error = null;
      const sidRequest = this.constructSidRequest();
      const isUpdate = !!this.sidRequestUuid;
      if (isUpdate) {
        sidRequest.sidRequestUuid = this.sidRequestUuid;
      }
      if (!await this.identification.$data.v$.$validate()
        || !await this.shares.$data.v$.$validate()
        || !await this.planning.$data.v$.$validate()) {
        this.error = this.$t('shareholderId.submitValidationError');
        this.loadingSubmit = false;
        return;
      }
      try {
        let resp;
        if (isUpdate) {
          resp = await updateSidRequest(sidRequest);
          await setSidRequestStatus(sidRequest.sidRequestUuid, generalStatus.issuerApproval);
        } else {
          resp = await createSidRequest(sidRequest, false);
        }
        this.$emit('confirm-leave');
        await this.sidRequestStore.getSidRequestDetails(resp.data.sidRequestUuid);
        this.$router.push({
          name: navItems.sidHome.sidRequest.details.routeName,
          params: { sidRequestUuid: resp.data.sidRequestUuid },
        });
        this.toastStore.setToastMessage(this.$t('shareholderId.success.requestSubmitted'));
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.submitSidRequest')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loadingSubmit = false;
      }
    },
    async submitDraftSidRequest() {
      this.loadingDraft = true;
      this.error = null;
      const sidRequest = this.constructSidRequest();
      const isUpdate = !!this.sidRequestUuid;
      if (!await this.identification.v$.form.issuerUuid.$validate()
        || !await this.identification.v$.form.isin.$validate()) {
        this.identification.v$.form.issuerUuid.$touch();
        this.identification.v$.form.isin.$touch();
        this.error = this.$t('shareholderId.draftValidationError');
        this.loadingDraft = false;
        return;
      }
      if (isUpdate) {
        sidRequest.sidRequestUuid = this.sidRequestUuid;
      }
      try {
        const resp = isUpdate
          ? await updateSidRequest(sidRequest) : await createSidRequest(sidRequest, true);
        this.$emit('confirm-leave');
        await this.sidRequestStore.getSidRequestDetails(resp.data.sidRequestUuid);
        this.$router.push({
          name: navItems.sidHome.sidRequest.details.routeName,
          params: { sidRequestUuid: resp.data.sidRequestUuid },
        });
        this.toastStore.setToastMessage(this.$t('shareholderId.success.requestSaved'));
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.saveDraftSidRequest')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loadingDraft = false;
      }
    },
    constructSidRequest() {
      const identificationData = this.identification.$data.form;
      const sharesData = this.shares.$data.form;
      const planningData = this.planning.$data.form;
      return {
        issuerUuid: identificationData.issuerUuid,
        isin: identificationData.isin,
        csdUuid: identificationData.csdUuid,
        totalOutstandingShares: sharesData.totalOutstandingShares,
        threshold: sharesData.threshold,
        thresholdUnit: sharesData.thresholdUnit,
        recordDate: formatDateBackEnd(planningData.recordDate),
        startDateScheduled: planningData.startDateScheduled,
        startDate: formatDateBackEnd(planningData.startDate),
        deadlineStandard: planningData.deadlineStandard,
        disclosureDeadlineDate: formatDateBackEnd(planningData.disclosureDeadline),
        reportDeadlineDate: formatDateBackEnd(planningData.reportDeadline),
      };
    },
  },
});
</script>

<style lang="scss">
@use '@/styles/styles' as lib;

@include lib.mb_1;
@include lib.py_5;
@include lib.pt_5;

#form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1rem 1.5rem 1rem;
}

#form-footer {
  display: flex;
  gap: 1rem;
}

.input-field {
  width: 320px;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-button-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
