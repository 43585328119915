<template>
  <aab-modal
    id="accountsHoldingsModal"
    modal-size="large"
    :is-open="openModal"
    @modal-close="shutdown()"
  >
    <div
      id="modal-title"
      slot="modal-title"
    >
      <base-typology
        tag="h3"
        variant="margin-0"
      >
        {{ `${$t('shareholderId.accountsHoldings')}: ${intermediaryName}` }}
      </base-typology>
    </div>
    <div
      id="accounts-holdings-container"
      slot="modal-content"
    >
      <div
        v-for="(account, index) in accountsHoldings"
        :key="index"
        class="shadow-tile bg-white mb-1"
      >
        <aab-accordion :id="`accounts-holdings-${index}`">
          <section slot="header">
            <div class="p-2">
              <base-typology
                :id="`header-name-${index}`"
                tag="h4"
                variant="margin-0"
              >
                {{ account.name }}
              </base-typology>
              <base-typology
                :id="`header-account-${index}`"
                variant="small"
                color="gray"
              >
                {{ `${$t('shareholderId.securitiesAccountNumber')}:
                ${account.securitiesAccountNumber}` }}
              </base-typology>
            </div>
          </section>
          <section class="p-2">
            <div class="row">
              <div
                :id="`ah-name-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.holderName') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ account.name }}
                </base-typology>
              </div>
              <div
                :id="`ah-secAccountNo-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.securitiesAccountNumber') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ account.securitiesAccountNumber }}
                </base-typology>
              </div>
              <div
                :id="`ah-ownership-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.securitiesOwnership') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ `${formatNumber(account.securities, 'decimal')}
                    (${formatNumber(account.ownershipPercentage, 'percent')})` }}
                </base-typology>
              </div>
              <div
                :id="`ah-date-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.initialDateOfShareholding') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ account.initialDateOfShareholding }}
                </base-typology>
              </div>
              <div
                :id="`ah-ownershipType-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.ownershipType') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ account.ownershipType }}
                </base-typology>
              </div>
              <div
                :id="`ah-investorType-${index}`"
                class="col-md-6"
              >
                <base-typology tag="label">
                  {{ $t('shareholderId.investorType') }}
                </base-typology><br>
                <base-typology tag="p">
                  {{ account.investorType }}
                </base-typology>
              </div>
              <div
                v-if="account.address"
                :id="`ah-address-${index}`"
                class="col-md-6 pb-2"
              >
                <base-address :address="account.address" />
              </div>
            </div>
            <section
              v-if="account.thirdParties"
            >
              <base-typology
                tag="h5"
                variant="margin-0"
              >
                {{ $t('shareholderId.thirdParties') }}
              </base-typology>
              <div
                v-for="(party, tpIndex) in account.thirdParties"
                :key="tpIndex"
              >
                <div class="row">
                  <div
                    :id="`tp-name-${index}-${tpIndex}`"
                    class="col-md-6 pb-2"
                  >
                    <base-typology tag="label">
                      {{ $t('user.name') }}
                    </base-typology><br>
                    <base-typology>
                      {{ party.name }}
                    </base-typology>
                  </div>
                  <div
                    :id="`tp-role-${index}-${tpIndex}`"
                    class="col-md-6 pb-2"
                  >
                    <base-typology tag="label">
                      {{ $t('user.role') }}
                    </base-typology><br>
                    <base-typology>
                      {{ party.partyRole }}
                    </base-typology>
                  </div>
                  <div
                    :id="`tp-id-${index}-${tpIndex}`"
                    class="col-md-6 pb-2"
                  >
                    <base-typology tag="label">
                      {{ $t('gen.identification') }}
                    </base-typology><br>
                    <base-typology>
                      {{ `${party.identificationType}: ${party.identifier}` }}
                    </base-typology>
                  </div>
                  <div
                    :id="`tp-entityType-${index}-${tpIndex}`"
                    class="col-md-6 pb-2"
                  >
                    <base-typology tag="label">
                      {{ $t('shareholderId.entityType') }}
                    </base-typology><br>
                    <base-typology>
                      {{ party.entityType }}
                    </base-typology>
                  </div>
                  <div
                    v-if="party.address"
                    :id="`tp-address-${index}-${tpIndex}`"
                    class="col-md-6 pb-2"
                  >
                    <base-address :address="party.address" />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </aab-accordion>
      </div>
    </div>
  </aab-modal>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { formatNumber } from '@/utils/formatLib';
import BaseAddress from '@/components/base/BaseAddress.vue';

export default defineComponent({
  name: 'AccountsHoldings',
  components: { BaseAddress, BaseTypology },
  props: {
    openModal: {
      type: Boolean,
      default: null,
    },
    intermediaryName: {
      type: String,
      required: true,
    },
    accountsHoldings: {
      type: Array,
      required: true,
    },
  },
  emits: ['close-modal'],
  methods: {
    formatNumber,
    shutdown() {
      this.$emit('close-modal');
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.shadow_tile;
@include lib.row;
@include lib.col-md-8;
@include lib.col-md-6;
@include lib.col-md-4;
@include lib.mb_1;
@include lib.p_2;
@include lib.pb_2;
@include lib.p_1;
</style>
