<template>
  <div>
    <base-typology tag="label">
      {{ $t('shareholderId.holdersGraph') }}
    </base-typology>
    <section
      id="holdersGraph"
      aria-label="Graph depicting type of holders per country, descending on amount"
    >
      <!--loading-->
      <div v-if="loading">
        <aab-spinner
          size="5"
          hide-overlay
        />
      </div>
      <div v-if="error">
        <aab-notification
          type="warning"
          type-aria-label="Warning notification"
        >
          <p>{{ error }}</p>
        </aab-notification>
      </div>
      <div
        v-if="!loading && !error"
        style="height: 300px"
      >
        <base-bar-chart
          chart-id="holders-by-country"
          :data="barData"
          :categories="barCategories"
          horizontal
        ></base-bar-chart>
      </div>
    </section>
  </div>
</template>

<script>
import BaseTypology from '@/components/base/BaseTypology.vue';
import { defineComponent } from 'vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import { getDisclosuresPerCountry } from '@/services/sidService';
import getCountryName from '@/utils/country';
import { g100, g400 } from '@aab/sc-styles-colors';
import BaseBarChart from '@/components/base/BaseBarChart.vue';
import { groupData, sortObjectsMultipleProps } from '@/utils/arrayTransformation';

export default defineComponent({
  name: 'HoldersGraph',
  components: { BaseBarChart, BaseTypology },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      barData: [],
      barCategories: [{
        name: this.$t('gen.legal'),
        key: 'legalHolders',
        colour: g400,
      },
      {
        name: this.$t('gen.natural'),
        key: 'naturalHolders',
        colour: g100,
      }],
    };
  },
  async created() {
    await this.getDataPerCountry();
  },
  methods: {
    async getDataPerCountry() {
      this.loading = true;
      try {
        const data = await getDisclosuresPerCountry(this.sidRequestStore.getSidUuid);
        if (data) {
          const unsortedBarData = Object.keys(data)
            .map((key) => ({
              label: getCountryName(data[key].countryCode),
              legalHolders: data[key].numberLegalShareholders,
              naturalHolders: data[key].numberNaturalShareholders,
            }));
          const ungroupedData = sortObjectsMultipleProps(
            unsortedBarData,
            ['legalHolders', 'naturalHolders'],
            'DESC',
          );
          this.barData = groupData(
            ungroupedData,
            7,
            ['legalHolders', 'naturalHolders'],
            [{ key: 'label', value: this.$t('gen.others') }],
          );
        }
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getDisclosuresPerCountry')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
