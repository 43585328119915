<!--
  This layout makes use of the titleBar and its options, a default content slot and a
  slot for a sticky bottom. It is a basic layout that allows for flexibility within
  content.
-->

<template>
  <div class="d-flex flex-column min-h-screen">
    <div
      id="main"
      role="main"
      class="flex-grow-1 pb-6 pb-lg-7"
    >
      <section
        v-if="showTitleBar"
        :class="titleBarGradient"
      >
        <title-bar
          :title-long="titleLong"
          :title-short="titleShort"
          :back-button-path="backButtonPath"
          :back-button-title="backButtonTitle"
          :large-title-bar="largeTitleBar"
        >
          <template #titleBar>
            <slot name="titleBar"></slot>
          </template>
        </title-bar>
      </section>
      <div
        class="container-fluid max-w-layout px-md-3"
        :class="titleBarPadding"
      >
        <div class="row">
          <div
            id="innerPage"
            class="col-12 px-0 spx-md-2"
          >
            <slot>
              <p>Here comes the main application</p>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showStickyBottom"
      class="sticky-bottom"
    >
      <slot name="stickyBottom"></slot>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/header/TitleBar.vue';

/**
 * This is a full-width layout, which also _loads_ runtime-organisms
 * (aab-header and aab-footer)
 * It can be used to create an exception layout by disabling the title bar
 *
 * @name LayoutFullWidth
 * @vue-prop {String} [titleLong]  - The long title to be used on desktop
 * @vue-prop {String} [titleShort] - The shorter title to be used for mobile
 * @vue-prop {Boolean} [showTitleBar=true] - show or hide the title bar
 * @vue-prop {String} [backButtonPath] - Path the back button links to
 * @vue-prop {String} [backButtonTitle] - Title of the back button
 * @vue-prop {Boolean} [showStickyBottom=false] -
 *    Show slot meant for sticky content at the bottom of the page
 * @vue-prop {Boolean} [largeTitleBar=false] - large or normal title bar
 */
export default {
  name: 'LayoutFullWidth',
  components: {
    TitleBar,
  },
  props: {
    titleLong: {
      type: String,
      required: true,
    },
    titleShort: {
      type: String,
      required: true,
    },
    backButtonPath: {
      type: String,
      default: null,
    },
    backButtonTitle: {
      type: String,
      default: null,
    },
    showStickyBottom: {
      type: Boolean,
      default: false,
    },
    showTitleBar: {
      type: Boolean,
      default: true,
    },
    largeTitleBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleBarGradient() {
      return this.showTitleBar ? 'gradient-g400-g200' : '';
    },
    titleBarPadding() {
      return this.showTitleBar ? 'pt-md-3' : '';
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.container_fluid;
@include lib.em_page_grid;
@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.row;
@include lib.col;
@include lib.pb-6;
@include lib.pb-lg-7;
@include lib.px-0;
@include lib.pt-1;
@include lib.pt-lg-0;
@include lib.pt-lg-2;
@include lib.position_relative;
@include lib.mx_0;
@include lib.overflow_show;
@include lib.position_absolute;
</style>
