// these functions are helper functions for components that use BaseData and BaseDataTable.
// BaseDataTable, and BaseData by extension,
// allow users to select individual rows or (de)select all rows
// Instead of having each component that has selectable data handle these actions individually
// these functions take care of that logic.

/**
 * Handles user interaction (check/uncheck) on an item used within BaseData or BaseDataTable.
 * This function provides the same array back with the updated checked/unchecked item.
 * Data going into BaseData or BaseDataTable has a specific structure,
 * which is necessary for this function to work.
 * If array is falsy, has a length of 0 or if the rowId is not provided, then function returns [].
 * @name provideUpdatedArrayAfterItemClick
 * @param totalArray       the existing array with all items
 * @param id               the item id (usually rowId (which is usually the unique uuid of the item)
 * @returns {Array}        the updated array with all updated checked/unchecked items
 */
function provideUpdatedArrayAfterItemClick(totalArray, id) {
  if (!totalArray || totalArray.length === 0 || !id) {
    return [];
  }
  return totalArray.map((item) => {
    const newItem = item;
    if (item.id === id) {
      // item is checked or unchecked, the inverse of its current status
      newItem.checked = !newItem.checked;
    }
    return newItem;
  });
}

/**
 * Handles user interaction (check/uncheck) on an item used within BaseData or BaseDataTable.
 * This function provides a new array with only the checked items from the total array.
 * Data going into BaseData or BaseDataTable has a specific structure,
 * which is necessary for this function to work.
 * If you're also updating the existing array aside from creating a new one,
 * this function needs to executed AFTER the function that updates the existing array
 * If array is falsy, has a length of 0 or if the rowId is not provided, then function returns [].
 * @name provideNewArrayWithSelectedItemsAfterItemClick
 * @param totalArray       the existing array with all items
 * @param checkedArray     the array with only the checked items
 * @param id               the item id (usually rowId (which is usually the unique uuid of the item)
 * @returns {Array}        the updated array with only checked items
 */
function provideNewArrayWithSelectedItemsAfterItemClick(totalArray, checkedArray, id) {
  if (!totalArray || totalArray.length === 0 || !id) {
    return [];
  }
  // if item is already in newArray, it needs to be removed
  // regardless of presence in totalArray (i.e. user moved page)
  const updatedCheckedArray = checkedArray.filter(
    (item) => item.id !== id,
  );
  // this adds the item to the checked items array
  // in the event the item was checked
  totalArray.forEach((item) => {
    if (item.id === id) {
      if (item.checked) {
        updatedCheckedArray.push(item);
      }
    }
  });
  return updatedCheckedArray;
}

/**
 * Handles user interaction on a (de)select all within BaseData or BaseDataTable.
 * This function provides an updated array with everything checked === true or checked == false.
 * Data going into BaseData or BaseDataTable has a specific structure,
 * which is necessary for this function to work.
 * If array is falsy, has a length of 0, then function returns [].
 * @name provideUpdatedArrayAfterSelectAll
 * @param totalArray  the existing array with all items
 * @returns {Array}   the updated array with everything unchecked or checked
 */
function provideUpdatedArrayAfterSelectAll(totalArray) {
  if (!totalArray || totalArray.length === 0) {
    return [];
  }
  // count checked items
  const amountSelected = totalArray
    .filter((item) => item.checked)
    .length;
  return totalArray.map((item) => {
    const newItem = item;
    // all items receive checked: true if amount selected is <> (= shorter) than the amount selected
    newItem.checked = amountSelected !== totalArray.length;
    return newItem;
  });
}

/**
 * Handles user interaction on a (de)select all within BaseData or BaseDataTable.
 * This function provides a new array which is empty or has all items which everything checked.
 * Data going into BaseData or BaseDataTable has a specific structure,
 * which is necessary for this function to work.
 * If you're also updating the existing array aside from creating a new one,
 * this function needs to executed AFTER the function that updates the existing array
 * If array is falsy, has a length of 0, then function returns [].
 * @name provideNewArrayAfterSelectAll
 * @param totalArray  the existing array with all items
 * @returns {Array}   the checkedArray either empty or identical to totalArray
 */
function provideNewArrayAfterSelectAll(totalArray) {
  if (!totalArray || totalArray.length === 0) {
    return [];
  }
  // count checked items
  const amountSelected = totalArray
    .filter((item) => item.checked)
    .length;
  // handles select all - all items have property checked === true
  if (totalArray.length === amountSelected) {
    return totalArray;
  }
  // handles deselect all
  return [];
}

function updateCheckedBasedOfSelectionInTotalArray(totalArray, selectionArray) {
  return totalArray.map((item) => {
    const newItem = item;
    selectionArray.forEach((selectedItem) => {
      if (newItem.id === selectedItem.id) {
        newItem.checked = true;
      }
    });
    return newItem;
  });
}

export {
  provideUpdatedArrayAfterItemClick,
  provideNewArrayWithSelectedItemsAfterItemClick,
  provideUpdatedArrayAfterSelectAll,
  provideNewArrayAfterSelectAll,
  updateCheckedBasedOfSelectionInTotalArray,
};
