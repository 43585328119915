import { defineStore } from 'pinia';
import { getSidRequestDetails } from '@/services/sidService';
import { generalStatus } from '@/utils/reference/globalConstants';

/**
 * This store contains sid request data and actions for getting that data.
 */
const useSidRequestStore = defineStore('sidRequestStore', {
  state: () => ({
    sidRequest: null,
    matchResponsesPageActive: true,
    nomineeAccountName: '',
  }),
  getters: {
    getSidUuid: (state) => state.sidRequest.uuid,
    getSidStatus: (state) => state.sidRequest.status,
    getSidIssuerName: (state) => state.sidRequest.issuerName,
    getSidTotalOutstandingShares: (state) => state.sidRequest.totalOutstandingShares,
    getSidRequest: (state) => state.sidRequest,
    getNomineeAccountName: (state) => state.nomineeAccountName,
    isMatchResponsesPageActive: (state) => state.matchResponsesPageActive,
    isSidRequestLoaded: (state) => !!state.sidRequest,
    hasSidRequestStarted: (state) => (!state.sidRequest
      ? false
      : (state.sidRequest.status.value === generalStatus.inProgress
      || state.sidRequest.status.value === generalStatus.completed
      || state.sidRequest.status.value === generalStatus.cancelled)
    ),
  },
  actions: {
    /**
     * Get SID Request
     *
     * On success the returned SID Request is stored in Pinia
     */
    async getSidRequestDetails(sidRequestUuid) {
      this.sidRequest = await getSidRequestDetails(sidRequestUuid);
    },
    async setSidRequestDetails(sidRequestData) {
      this.sidRequest = sidRequestData;
    },
    switchRequestMatchingPage() {
      this.matchResponsesPageActive = !this.matchResponsesPageActive;
    },
    /**
     * Set nominee account name
     *
     * When navigating to matching details of a nominee account
     * we need the name for the title bar. We use the store to
     * be able to access this information in SidHomeView.vue
     */
    setNomineeAccountName(name) {
      this.nomineeAccountName = name;
    },
    clearNomineeAccountName() {
      this.nomineeAccountName = '';
    },
  },
});

export default useSidRequestStore;
