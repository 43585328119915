import i18n from '@/i18n';

const regionNamesEn = new Intl.DisplayNames(['en'], { type: 'region', fallback: 'code' });

export default function getCountryName(countryCode) {
  if (!countryCode) {
    return i18n.global.t('utils.unknown');
  }
  if (i18n.global.locale === 'en-GB') {
    return regionNamesEn.of(countryCode);
  }
  return i18n.global.t('errors.unexpectedLocale');
}
